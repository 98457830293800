import { memo } from 'react';
import { createPortal } from 'react-dom';
import { clsx } from 'clsx';
import { Editor } from '@tiptap/react';
import { TableOfContentDataItem } from '@tiptap-pro/extension-table-of-contents';

import { useMediaGroupDetail } from '@api/mediaGroup';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import { DetailsModelTab } from '@/types/MediaGroup';
import DetailsModalSidebar from './DetailsModalSidebar';
import ModalStandardContent from './DetailsModalStandardContent';
import ModalFlowContent from './DetailsModalFlowContent';
import ModalNotesContent from './DetailsModalNotesContent';
import styles from './DetailsModalBody.module.scss';
import TableOfContents from './tiptap/nodes/TableOfContents';

const MemorizedToC = memo(TableOfContents);

export default function DetailsModalBody({
  activeTab,
  editor,
  handleImageUploadInputChange,
  imageUploadInputRef,
  mediaGroupId,
  tableOfContentItems,
}: {
  activeTab: DetailsModelTab;
  editor?: Editor | null;
  handleImageUploadInputChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => boolean | undefined;
  imageUploadInputRef?: React.RefObject<HTMLInputElement>;
  mediaGroupId: string;
  tableOfContentItems?: TableOfContentDataItem[];
}) {
  // TODO(@dbowring): Support user swapping between render styles.
  const asFlow = false;
  const { contentView, quickViewMediaGroupId } = useDetailsModalStore(
    (store) => ({
      contentView: store.contentView,
      quickViewMediaGroupId: store.quickViewMediaGroupId,
    })
  );
  const { data, isLoading } = useMediaGroupDetail(mediaGroupId);

  if (isLoading || !data) return null;
  const { mediaGroup } = data;
  const { kind } = mediaGroup;

  const tableOfContentSlot = document.getElementById(
    'detailsModalSidebarTableOfContents'
  );

  const hasLightBackground = ['notes'].includes(contentView ?? '');
  const enlargedSidebar =
    !!quickViewMediaGroupId && activeTab === 'browseRepository';

  return (
    <div
      className={clsx(
        styles.dialogBody,
        hasLightBackground && styles.lightBackground
      )}
    >
      <DetailsModalSidebar
        mediaGroupId={mediaGroupId}
        activeTab={activeTab}
        enlarged={enlargedSidebar}
      />
      {/* TODO: Fill in the content for additional tabs */}
      {!contentView && (
        <div className={clsx(styles.visuals, enlargedSidebar && styles.shrunk)}>
          <h1 className="title4">No content available</h1>
        </div>
      )}
      {contentView === 'content' && (
        <div
          className={clsx(
            styles.visuals,
            styles[kind],
            enlargedSidebar && styles.shrunk
          )}
        >
          {asFlow ? (
            <ModalFlowContent mediaGroupId={mediaGroupId} />
          ) : (
            <ModalStandardContent
              mediaGroupId={mediaGroupId}
              activeTab={activeTab}
            />
          )}
        </div>
      )}
      {tableOfContentSlot &&
        editor &&
        createPortal(
          <MemorizedToC editor={editor} items={tableOfContentItems ?? []} />,
          tableOfContentSlot
        )}
      {contentView === 'notes' && editor && (
        <div
          className={clsx(
            styles.visuals,
            styles.notes,
            enlargedSidebar && styles.shrunk
          )}
        >
          <ModalNotesContent
            editor={editor}
            imageUploadInputRef={imageUploadInputRef}
            handleImageUploadInputChange={handleImageUploadInputChange}
            mediaGroupId={mediaGroupId}
          />
        </div>
      )}
      {contentView === 'transcript' && (
        <div className={clsx(styles.visuals, enlargedSidebar && styles.shrunk)}>
          <h1 className="title4">Transcript</h1>
        </div>
      )}
    </div>
  );
}
