import { useNavigate } from 'react-router-dom';

import useWorkspaceId from '@hooks/useWorkspaceId';
import { useManagementModal } from '@ui/ManagementModal';
import { urlFor } from '@/urls';

const PRICING_LINK = {
  to: 'https://www.spaceduck.com/pricing',
  target: '_blank',
};

export const useUpgradePlanModal = (modalProps: {
  header?: string;
  title?: string;
  message?: string;
  closeModal?: () => void;
}) => {
  const workspaceId = useWorkspaceId();
  const { open: openManagementModal, close } = useManagementModal({
    cancelText: 'Compare plans',
    continueText: 'Upgrade now',
    header: modalProps.header || 'Management',
    title: modalProps.title || 'Management',
    message: modalProps.message,
    onCancel: PRICING_LINK,
    onContinue: () => {
      if (!workspaceId) {
        console.error(
          'Failed to navigate to billing settings due to missing workspaceId'
        );
        return;
      }
      navigate(urlFor('workspaceSettingsBillingAndPlan', { workspaceId }));
    },
    closeModal: modalProps.closeModal,
  });

  const navigate = useNavigate();
  return {
    open: () => {
      openManagementModal();
    },
    close,
  };
};
