import { useRef, useState } from 'react';
import clsx from 'clsx';
import { NumberFieldInstance } from '@spaceduck/api';

import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { CommonEditProps, CommonProps } from '../InfoCategories';
import Empty from './Empty';
import styles from './Number.module.scss';

export default function NumbeField(commonProps: CommonProps) {
  const { canEdit, instances, updateField } = commonProps;
  const [shouldShowEditView, setShouldShowEditView] = useState(false);
  const numberInstance = (instances as NumberFieldInstance[])[0];
  const [localValue, setLocalValue] = useState<string | null>(
    numberInstance?.value || null
  );

  const handleClick = () => {
    if (canEdit) {
      setShouldShowEditView(true);
    }
  };

  return (
    <>
      {shouldShowEditView && canEdit ? (
        <EditView
          localValue={localValue}
          setLocalValue={setLocalValue}
          setShouldShowEditView={setShouldShowEditView}
          numberInstance={numberInstance}
          updateField={updateField}
        />
      ) : (
        <div className={clsx(styles.wrapper, styles.hoverBg)}>
          {numberInstance ? (
            <div className={clsx(!shouldShowEditView && styles.overflowHidden)}>
              <div className={styles.truncated} onClick={handleClick}>
                {localValue}
              </div>
            </div>
          ) : (
            <Empty onClick={handleClick} />
          )}
        </div>
      )}
    </>
  );
}

const EditView = ({
  localValue,
  setLocalValue,
  setShouldShowEditView,
  numberInstance,
  updateField,
}: CommonEditProps & {
  localValue: string | null;
  setLocalValue: React.Dispatch<React.SetStateAction<string | null>>;
  numberInstance?: NumberFieldInstance;
}) => {
  const _localValue = useRef(
    numberInstance?.value ? Number(numberInstance.value).toString() : null
  );

  const setValue = (value: string | null) => {
    _localValue.current = value;
    setLocalValue(value);
  };

  const persist = async () => {
    await updateField?.(
      _localValue.current
        ? ([
            { ...numberInstance, value: _localValue.current },
          ] as NumberFieldInstance[])
        : []
    );
    setShouldShowEditView(false);
  };

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: persist,
  });

  const handleChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = (ev.target as HTMLInputElement).value.trim();

    if (!value) {
      setValue(null);
      return;
    }

    // Backend does not process more than 5 decimal places
    const fiveDecimalPattern = /^(\d*(\.\d{0,5})).*?/;
    if (fiveDecimalPattern.test(value)) {
      const res = value.match(fiveDecimalPattern);
      if (res?.length) {
        setValue(res[0] ?? null);
        return;
      }
    }

    setValue(value);
    return;
  };

  const handleKeyDown = async (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      persist();
      return true;
    }

    if (ev.key === 'Escape') {
      ev.stopPropagation();
      ev.preventDefault();
      setShouldShowEditView(false);
      return;
    }

    if (['e', 'E', '+'].includes(ev.key)) {
      ev.preventDefault();
      return true;
    }
  };

  return (
    <div ref={containerRef}>
      <input
        autoComplete="off"
        autoFocus
        className={styles.input}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Number"
        step={0.00001}
        type="number"
        value={localValue ?? ''}
      />
    </div>
  );
};
