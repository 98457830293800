import { useState, useEffect } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import clsx from 'clsx';

import Button from '@ui/Button';
import Spinner from '@ui/Spinner';
import styles from './ReorderableImageRow.module.scss';
import { Icon24 } from '@spaceduck/icons';
import { reorder } from '@/utils/array';
const { Close } = Icon24;

const useStrictDroppable = (loading: boolean) => {
  // Hack to get around strict mode for the droppable component
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    let animation = loading
      ? undefined
      : requestAnimationFrame(() => setEnabled(true));

    return () => {
      if (!animation) return;
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, [loading]);

  return [enabled];
};

function Image({
  src,
  onDelete,
  processing,
}: {
  src: string;
  onDelete: () => void;
  processing?: boolean;
}) {
  const [hovering, setHovering] = useState(false);
  return (
    <div
      className={styles.container}
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      {processing ? (
        <Button
          className={styles.removeButton}
          variant="flat"
          size="xs"
          isSquare={true}
        >
          <Spinner size={16} />
        </Button>
      ) : (
        hovering && (
          <Button
            className={styles.removeButton}
            variant="flat"
            size="xs"
            onClick={onDelete}
            isSquare={true}
          >
            <Close size={16} />
          </Button>
        )
      )}
      <img src={src} />
    </div>
  );
}

export default function ReorderableImageRow<
  T extends { url: string; processing?: boolean; key?: string },
>({
  assets,
  onChange,
  className,
}: {
  assets: Array<Exclude<T, undefined>>;
  onChange: (assets: T[]) => void;
  className?: string;
}) {
  const [enabled] = useStrictDroppable(assets.length === 0);
  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return;
    }
    const newOrder = reorder(
      assets,
      result.source.index,
      result.destination.index
    );
    onChange(newOrder);
  };

  useEffect(() => {
    const sliderElement = document.getElementById('imageSlider');
    if (sliderElement) {
      sliderElement.scrollTo({
        left: sliderElement.scrollWidth,
        behavior: 'smooth',
      });
    }
  }, [assets.length]);

  if (!assets.length) {
    return null;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {enabled && (
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <div
              ref={provided.innerRef}
              id="imageSlider"
              className={clsx(styles.imageSlider, className)}
              {...provided.droppableProps}
            >
              {assets.map((item, index) => {
                const key = item.key || item.url;
                return (
                  <Draggable key={key} draggableId={key} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.imageWrapper}
                        style={{
                          ...provided.draggableProps.style,
                          opacity: snapshot.isDragging ? 0.85 : 1,
                        }}
                      >
                        <Image
                          src={item.url}
                          onDelete={() => {
                            onChange(
                              assets.filter((asset) => asset.key !== key)
                            );
                          }}
                          processing={item.processing}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </DragDropContext>
  );
}
