import styles from './ProductPlaceholder.module.scss';

export default function ProductPlaceholder() {
  return (
    <div className={styles.container}>
      <div className={styles.visual}>
        <div className={styles.image}></div>
      </div>
      <div className={styles.details}>
        <div className={styles.icon}></div>
        <div className={styles.text}>
          <div className={styles.line1}></div>
          <div className={styles.line2}></div>
        </div>
      </div>
    </div>
  );
}
