import * as RadixScrollArea from '@radix-ui/react-scroll-area';
import { clsx } from 'clsx';

import styles from './ScrollArea.module.scss';

type ScrollAreaProps = {
  children: React.ReactNode;
  expandedScrollbar?: boolean;
  orientation?: 'horizontal' | 'vertical';
  style: React.CSSProperties;
} & RadixScrollArea.ScrollAreaProps;

export default function ScrollArea(props: ScrollAreaProps) {
  const {
    className,
    children,
    expandedScrollbar = false,
    orientation,
    style,
    ...rootProps
  } = props;

  return (
    <RadixScrollArea.Root
      className={styles.scrollAreaRoot}
      style={style}
      {...rootProps}
    >
      <RadixScrollArea.Viewport
        className={clsx(styles.scrollAreaViewport, className)}
      >
        {children}
      </RadixScrollArea.Viewport>
      {orientation !== 'horizontal' && (
        <RadixScrollArea.Scrollbar
          className={styles.scrollAreaScrollbar}
          orientation="vertical"
        >
          <RadixScrollArea.Thumb className={styles.scrollAreaThumb} />
        </RadixScrollArea.Scrollbar>
      )}
      {orientation !== 'vertical' && (
        <RadixScrollArea.Scrollbar
          className={clsx(
            styles.scrollAreaScrollbar,
            expandedScrollbar && styles.expanded
          )}
          orientation="horizontal"
        >
          <RadixScrollArea.Thumb className={styles.scrollAreaThumb} />
        </RadixScrollArea.Scrollbar>
      )}
      <RadixScrollArea.Corner className={styles.scrollAreaCorner} />
    </RadixScrollArea.Root>
  );
}
