import { useNavigate } from 'react-router-dom';
import { Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';

import { useGetSubscriptionInfo } from '@api/billing';
import { useModalManager } from '@context/ModalManagerContext';
import Spinner from '@components/Spinner';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button from '@ui/Button';
import Dialog from '@ui/Dialog';
import { urlFor } from '@/urls';
import styles from './UsageModal.module.scss';
import { UsageCategories } from '@spaceduck/api';
import prettyBytes from 'pretty-bytes';
import { css } from '@/lib/css';

const { PricingEnterprise } = Icon24;

const usageCategoryLabel: Record<UsageCategories, string> = {
  fileStorage: 'storage',
  mediaGroupCount: 'items',
  projectCount: 'spaces',
};

export const UsageModal = ({ closeModal }: { closeModal: () => void }) => {
  const navigate = useNavigate();
  const workspaceId = useWorkspaceId();
  const { data, error } = useGetSubscriptionInfo(workspaceId);

  if (!data) return <Spinner />;

  if (error) return null;

  return (
    <Dialog
      closeModal={closeModal}
      isOpen={true}
      maxWidth="48.75rem"
      footer={
        <div className={styles.footer}>
          <Button onClick={closeModal} size="md" variant="outlined">
            Dismiss
          </Button>
          {workspaceId && (
            <Button
              onClick={() => {
                navigate(
                  urlFor('workspaceSettingsBillingAndPlan', { workspaceId })
                );
                closeModal();
              }}
              size="md"
              variant="primary"
            >
              Choose a plan
            </Button>
          )}
        </div>
      }
      showHeader={false}
    >
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <span className={styles.headerIcon}>
            <PricingEnterprise size={22} />
          </span>
        </div>
        <h1>Your workspace is on the {data.plan} plan</h1>
        <p>
          Get the most out of Spaceduck: Upgrade to unlock unlimited access and
          exclusive features.
        </p>
        <div className={styles.chart}>
          {data.usage
            .filter(
              (
                usage
              ): usage is Pick<typeof usage, 'currentUsage' | 'limitName'> & {
                planLimit: number;
              } => !!usage.planLimit
            )
            .map((usage) => (
              <ChartItem
                key={usage.limitName}
                label={usageCategoryLabel[usage.limitName]}
                max={usage.planLimit}
                value={usage.currentUsage}
                format={(value) =>
                  usage.limitName === 'fileStorage' ? prettyBytes(value) : value
                }
              />
            ))}
        </div>
      </div>
    </Dialog>
  );
};

const ChartItem = ({
  className,
  label,
  max,
  value,
  format,
}: {
  className?: string;
  label: string;
  max: number;
  value: number;
  format?: (value: number) => string | number;
}) => {
  const width = (value / max) * 100;
  return (
    <div className={clsx(styles.chartItem, className)}>
      <div className={styles.bar}>
        <div
          style={css({
            '--width': `${width < 100 ? width : 100}%`,
          })}
        ></div>
      </div>
      <div className={styles.label}>
        <span className={styles.highlight}>{format?.(value) ?? value}</span> /{' '}
        {format?.(max) ?? max} {label}
      </div>
    </div>
  );
};

export const useUsageModal = () => {
  const { openModal, closeModal } = useModalManager();

  return {
    open: () =>
      openModal({
        component: <UsageModal closeModal={closeModal} />,
      }),
    close: closeModal,
  };
};
