import { TextSelection } from '@tiptap/pm/state';
import { Editor } from '@tiptap/core';
import { type TableOfContentDataItem } from '@tiptap-pro/extension-table-of-contents';
import clsx from 'clsx';

import styles from './TableOfContents.module.scss';

export const TableOfContentItem = ({
  item,
  onItemClick,
}: {
  item: TableOfContentDataItem;
  onItemClick: (ev: React.MouseEvent, id: string) => void;
}) => {
  return (
    <div
      className={clsx(styles.item, item.level && styles[`level${item.level}`])}
    >
      <a href={`#${item.id}`} onClick={(ev) => onItemClick(ev, item.id)}>
        {item.textContent}
      </a>
    </div>
  );
};

export default function TableOfContents({
  items = [],
  editor,
}: {
  items: TableOfContentDataItem[];
  editor: Editor;
}) {
  if (items.length === 0) return null;

  const onItemClick = (ev: React.MouseEvent, id: string) => {
    ev.preventDefault();
    if (editor) {
      const element = editor.view.dom.querySelector(`[data-toc-id="${id}"`);

      if (!element) return;
      const pos = editor.view.posAtDOM(element, 0);

      const tr = editor.view.state.tr;
      tr.setSelection(new TextSelection(tr.doc.resolve(pos)));
      editor.view.dispatch(tr);
      editor.view.focus();
      element.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.list}>
      {items.map((item) => (
        <TableOfContentItem
          onItemClick={(ev) => onItemClick(ev, item.id)}
          key={item.id}
          item={item}
        />
      ))}
    </div>
  );
}
