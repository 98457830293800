import { MediaGroupDTO } from '@spaceduck/api';
import { useCheckMediaGroupUpdated } from '@hooks/useCheckMediaGroupUpdated';
import Card from '@ui/Card';
import styles from './DeletedMediaGroupCard.module.scss';
import { useRestoreMediaGroup } from '@/api/mediaGroup';
import { catchApiErrorIntoToast } from '@/api/util';
import createToast from '@/utils/createToast';

type DeletedMediaGroupCardProps = {
  mediaGroup: MediaGroupDTO;
  updatedMediaGroupMap: ReturnType<typeof useCheckMediaGroupUpdated>;
};

export default function DeletedMediaGroupCard({
  mediaGroup,
  updatedMediaGroupMap,
}: DeletedMediaGroupCardProps) {
  const mg = updatedMediaGroupMap[mediaGroup.id] || mediaGroup;
  const { mutateAsync: restoreMediaGroup } = useRestoreMediaGroup();
  const restore = catchApiErrorIntoToast(async () => {
    await restoreMediaGroup(mediaGroup.id);
    createToast({
      bodyText: 'Item restored',
      iconVariant: 'success',
    });
  });
  return (
    <div className={styles.cardWrapper}>
      <div onClick={restore} className={styles.restoreOverlay}>
        <p>Restore this item</p>
      </div>
      <Card mediaGroup={mg} className={styles.card} />
    </div>
  );
}
