import { useNavigate } from 'react-router-dom';
import type { BillingPlan } from '@spaceduck/api';

import { useManagementModal } from '@ui/ManagementModal';
import { urlFor } from '@/urls';
import useWorkspaceId from '@/hooks/useWorkspaceId';

const buildModalProps = (subscriptionInfo?: {
  plan: BillingPlan;
  assignedSeats: number;
  seats: number;
}) => {
  if (!subscriptionInfo) {
    return {
      messageNode: <></>,
      continueText: '',
    };
  }
  let messageNode = (
    <>{`Contributor seats are designated for contributors in roles such as 'Editor', 'Admin', or 'Owner', granting them editing privileges or higher within your workspace. Viewers, however, can access content at no cost.`}</>
  );
  let continueText = 'Manage seats';

  if (subscriptionInfo.plan === 'starter') {
    continueText = 'Acquire additional seats';
    let message =
      'To include more contributors, acquire additional seats and then revisit this page to assign them.';
    if (subscriptionInfo.assignedSeats >= subscriptionInfo.seats) {
      message = 'All current seats are occupied. ' + message;
    }
    messageNode = <>{message}</>;
  }

  messageNode = (
    <>
      {messageNode}
      <br />
      <br />
      {`${subscriptionInfo.assignedSeats} / ${subscriptionInfo.seats} Contributor seats in use`}
    </>
  );

  return {
    messageNode,
    continueText,
  };
};

export function useSeatManagementModal(subscriptionInfo?: {
  plan: BillingPlan;
  assignedSeats: number;
  seats: number;
}) {
  const workspaceId = useWorkspaceId();
  const navigate = useNavigate();
  const { messageNode, continueText } = buildModalProps(subscriptionInfo);
  const { open: openManagementModal } = useManagementModal({
    header: 'Seat management',
    title: 'Contributor seats',
    message: messageNode,
    onContinue: () => {
      workspaceId &&
        navigate(
          urlFor('workspaceSettingsBillingAndPlan', {
            workspaceId,
          })
        );
    },
    cancelText: 'Never mind',
    continueText: continueText,
  });

  return {
    open: () => openManagementModal(),
  };
}
