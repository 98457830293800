import { forwardRef } from 'react';
import type { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from 'react';
import { clsx } from 'clsx';

import styles from './Checkbox.module.scss';
import { Icon24 } from '@spaceduck/icons';
const { Check } = Icon24;

type CheckboxProps = {
  children?: ReactNode;
  className?: string;
  hideCheckboxIcon?: boolean;
  icon?: ReactNode;
  onClick?: (value: string) => void;
  singleSelection?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  value?: string;
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'size' | 'type' | 'value'
>;

const iconSize = {
  xs: 20,
  sm: 20,
  md: 24,
  lg: 32,
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      children,
      className,
      hideCheckboxIcon = false,
      icon,
      onClick,
      singleSelection,
      size = 'sm',
      ...inputProps
    },
    ref
  ) => {
    return (
      <label
        className={clsx(
          styles.selectionButton,
          singleSelection && styles.singleSelection,
          styles[size],
          className
        )}
        onClick={() => inputProps?.value && onClick?.(inputProps?.value)}
      >
        <input type="checkbox" {...inputProps} ref={ref} />
        {!hideCheckboxIcon && (
          <span className={styles.icon}>
            <Check size={iconSize[size]} />
          </span>
        )}
        {icon && <span className={styles.customIcon}>{icon}</span>}
        {children}
      </label>
    );
  }
);

export default Checkbox;
