import { useMemo } from 'react';

import { VerifyCodeRejectedPayloadSchema } from '@spaceduck/api';
import { useLoginWithCode } from '@hooks/useLoginWithCode';
import CodeForm from './CodeForm';
import EmailForm from './EmailForm';
import { LoginPurpose } from './schema';

type LoginParams = {
  userHint: string | null;
  purpose: LoginPurpose | null;
};

const Login = ({ userHint, purpose }: LoginParams) => {
  const {
    nextUrl,
    requestIdMutation,
    requestResendHandler,
    setTargetEmail,
    submitCodeHandler,
    submitEmailHandler,
    verifyMutation,
    targetEmail,
  } = useLoginWithCode();

  const {
    data: requestId,
    isPending: requestIdIsLoading,
    error: requestIdError,
  } = requestIdMutation;

  const {
    data: verifyResult,
    isPending: verifyIsLoading,
    error: verifyError,
  } = verifyMutation;

  const verifyErrorReason = useMemo(() => {
    if (!verifyError) {
      return null;
    }
    const result = VerifyCodeRejectedPayloadSchema.safeParse(verifyError);
    if (!result.success) {
      return verifyError.message;
    }
    return result.data.info.reason;
  }, [verifyError]);

  const handleReset = () => {
    setTargetEmail(null);
  };

  if ((!requestId && !requestIdIsLoading) || !targetEmail) {
    return (
      <EmailForm
        onSubmit={submitEmailHandler}
        isLoading={requestIdIsLoading}
        error={requestIdError ? requestIdError.message : null}
        nextUrl={nextUrl || undefined}
        userHint={userHint}
        purpose={purpose}
      />
    );
  } else if (targetEmail && (!verifyResult || requestIdIsLoading)) {
    return (
      <CodeForm
        error={verifyErrorReason}
        handleBackClick={() => handleReset()}
        isLoadingVerify={verifyIsLoading}
        isLoadingRequestId={requestIdIsLoading}
        onResendRequest={requestResendHandler}
        onSubmit={submitCodeHandler}
        targetEmail={targetEmail}
      />
    );
  }
};

export default Login;
