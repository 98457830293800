import { unreachable } from '@spaceduck/utils';
import {
  createUploadRequest,
  getProcessingRequestDetailMany,
  uploadRequestIntoProcessingRequest,
} from '@/api/processingRequest';
import prettyBytes from 'pretty-bytes';
import { useCallback, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { z } from 'zod';
import { usePoll } from './usePoll';
import {
  ProcessingRequest,
  ProcessingRequestState,
  getWorkspace,
  uploadFileToUploadRequest,
} from '@spaceduck/api';
import { warningToast } from '@/utils/createToast';
import { urlFor } from '@/urls';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { PLAN_LABELS, PlanLabel } from '@/const';

const KILOBYTES = 1000;
const MEGABYTES = 1000 * KILOBYTES;
const MAX_FILE_SIZE = 500 * MEGABYTES;

const validPageFileSchema = (size?: number) =>
  z
    .any()
    .refine(
      (file: File) => file.size <= (size ?? MAX_FILE_SIZE),
      `max file size is ${prettyBytes(size ?? MAX_FILE_SIZE)}`
    );

const validTotalSizeSchema = (totalSize: number) =>
  z
    .any()
    .refine(
      (files: File[]) =>
        files.reduce((totalSize, file) => totalSize + file.size, 0) <=
        totalSize,
      `max total size is ${prettyBytes(totalSize)}`
    );
const invalidFileSizeToast = (
  navigate: NavigateFunction,
  fallbackErrorMessage: string,
  workspaceId?: string,
  maxFileSize?: number,
  plan?: PlanLabel
) => {
  warningToast({
    title:
      maxFileSize && plan
        ? `${plan} account file size limit: ${prettyBytes(maxFileSize)}`
        : undefined,
    message: maxFileSize
      ? `The maximum file size for a ${plan} account is ${prettyBytes(maxFileSize)}. Upgrade to upload larger files.`
      : fallbackErrorMessage,
    buttonText: 'See plans',
    onButtonClick: workspaceId
      ? () =>
          navigate(
            urlFor('workspaceSettingsBillingAndPlan', {
              workspaceId,
            })
          )
      : undefined,
  });
};

const invalidTotalSizeToast = (
  navigate: NavigateFunction,
  fallbackErrorMessage: string,
  workspaceId?: string,
  maxTotalSize?: number,
  plan?: PlanLabel
) => {
  warningToast({
    title:
      maxTotalSize && plan
        ? `${plan} account storage limit left: ${prettyBytes(maxTotalSize)}`
        : undefined,
    message: maxTotalSize
      ? `The storage left for your ${plan} account is ${prettyBytes(maxTotalSize)}. Upgrade to upload more files.`
      : fallbackErrorMessage,
    buttonText: 'See plans',
    onButtonClick: workspaceId
      ? () =>
          navigate(
            urlFor('workspaceSettingsBillingAndPlan', {
              workspaceId,
            })
          )
      : undefined,
  });
  return;
};

export type ProcessingResult = {
  file: File;
  key: string;
  request: ProcessingRequest;
};

export type UseProcessAssetsProps = {
  onCreate?: (item: WaitingItem) => void;
  onSuccess?: (item: ProcessingResult) => void;
  onFail?: (item: ProcessingResult) => void;
  onReject?: (item: ProcessingResult) => void;
  onComplete?: (item: ProcessingResult) => void;
  onError?: (items: UploadingItem) => void;
  pollInterval?: number;
};

const increment = (value: number) => value + 1;
const decrement = (value: number) => value - 1;

type UploadingItem = {
  kind: 'uploading';
  key: string;
  file: File;
};
type WaitingItem = {
  kind: 'waiting';
  file: File;
  request: ProcessingRequest;
  key: string;
};

const safeCall = <T>(fn: () => T) => {
  try {
    fn();
  } catch (error) {
    console.error('Error during event handler', error);
  }
};

const createProcessingRequest = async (
  file: File,
  workspaceId?: string,
  kind?: 'media' | 'avatar'
) => {
  const { uploadRequest } = await createUploadRequest({
    sizeBytes: file.size,
    mediaType: file.type,
    fileName: file.name,
    workspaceId: workspaceId,
  });

  await uploadFileToUploadRequest(uploadRequest, file);

  return await uploadRequestIntoProcessingRequest(uploadRequest.id, kind);
};

export const useProcessAssets = ({
  onCreate,
  onSuccess,
  onFail,
  onReject,
  onComplete,
  onError,
  pollInterval,
}: UseProcessAssetsProps = {}) => {
  const [loadingCount, setLoadingCount] = useState<number>(0);
  const fileMap = useRef(new Map<string, File>());
  const [pending, setPending] = useState<(UploadingItem | WaitingItem)[]>([]);
  const navigate = useNavigate();
  const registerFile = useCallback((file: File, providedKey?: string) => {
    const key = providedKey ?? v4();
    fileMap.current.set(key, file);
    return key;
  }, []);
  const findFile = useCallback((key: string) => fileMap.current.get(key), []);
  const forgetFile = useCallback(
    (key: string) => fileMap.current.delete(key),
    []
  );

  const forget = useCallback((key: string) => {
    setPending((existing) => existing.filter((item) => item.key !== key));
    forgetFile(key);
  }, []);

  const ingest = (items: { request: ProcessingRequest; key: string }[]) => {
    for (const { key, request } of items) {
      const file = findFile(key)!;

      if (file === undefined) {
        continue;
      }

      if (request.state === 'W' || request.state === 'P') {
        setPending((existing) =>
          existing.map((item) =>
            item.key === key ? { kind: 'waiting', file, request, key } : item
          )
        );
      } else if (request.state === 'S') {
        safeCall(() => onSuccess?.({ file, key, request }));
        forget(key);
      } else if (request.state === 'F') {
        safeCall(() => onFail?.({ file, key, request }));
        forget(key);
      } else if (request.state === 'R') {
        safeCall(() => onReject?.({ file, key, request }));
        forget(key);
      } else {
        throw unreachable('Unknown request state', request.state);
      }
      safeCall(() => onComplete?.({ file, key, request }));
    }
  };

  const withLoading = async <T>(fn: () => Promise<T>): Promise<T> => {
    setLoadingCount(increment);
    let result: T;
    try {
      result = await fn();
    } finally {
      setLoadingCount(decrement);
    }
    return result;
  };

  const insertFromExistingProcessingRequests = async (
    processingRequestIds: {
      file: File;
      key: string;
      processingRequestId: string;
      state: ProcessingRequestState;
    }[]
  ) => {
    setPending((existing) => [
      ...existing,
      ...processingRequestIds.map(
        ({ file, key, processingRequestId, state }) => ({
          kind: 'waiting' as const,
          key: registerFile(file, key),
          file,
          request: {
            id: processingRequestId,
            state,
            message: '',
            result: null,
          },
        })
      ),
    ]);
  };

  const insertSingle = async (
    file: File,
    workspaceId?: string,
    key?: string,
    kind?: 'media' | 'avatar'
  ) => {
    const uploadingItem: UploadingItem = {
      kind: 'uploading',
      key: registerFile(file, key),
      file,
    };

    const waitingItem = await withLoading(async () => {
      setPending((existing) => [...existing, uploadingItem]);
      let waitingItem: WaitingItem;

      try {
        const { processingRequest } = await createProcessingRequest(
          file,
          workspaceId,
          kind
        );
        waitingItem = {
          kind: 'waiting',
          request: processingRequest,
          key: uploadingItem.key,
          file: uploadingItem.file,
        };
        ingest([waitingItem]);
      } catch (e) {
        setPending((existing) =>
          existing.filter(({ key }) => key !== uploadingItem.key)
        );
        safeCall(() => onError?.(uploadingItem));
        throw e;
      }
      return waitingItem;
    });
    onCreate?.(waitingItem);
    return uploadingItem;
  };

  const insert = useCallback(
    async (
      params: {
        files: FileList | File[];
        keys?: string[];
        maxTotalSize?: number;
      } & (
        | {
            kind: 'avatar';
          }
        | {
            kind?: 'media';
            workspaceId: string;
          }
      )
    ) => {
      const { files, keys, kind } = params;
      const filesArray = [];
      for (const file of files) {
        filesArray.push(file);
      }
      let maxFileSize, maxTotalSize;
      let plan;
      if (params.kind !== 'avatar') {
        try {
          const { workspace } = await getWorkspace(params.workspaceId);
          plan = PLAN_LABELS[workspace.plan];
          maxFileSize =
            workspace.capabilities.find((c) => c.capability === 'upload')
              ?.limitLeft ?? undefined;
          maxTotalSize =
            workspace.capabilities.find((c) => c.capability === 'useStorage')
              ?.limitLeft ?? undefined;
        } catch (e) {
          console.error('Error calculating file size limits for workspace', e);
        }
      }
      for (const file of filesArray) {
        const result = validPageFileSchema(maxFileSize).safeParse(file);
        if (result.success) {
          continue;
        }
        invalidFileSizeToast(
          navigate,
          result.error.issues.map((issue) => issue.message).join('; '),
          params.kind !== 'avatar' ? params.workspaceId : undefined,
          maxFileSize,
          plan
        );
        return;
      }

      if (maxTotalSize) {
        const totalSizeValid =
          validTotalSizeSchema(maxTotalSize).safeParse(filesArray);
        if (!totalSizeValid.success) {
          invalidTotalSizeToast(
            navigate,
            totalSizeValid.error.issues
              .map((issue) => issue.message)
              .join('; '),
            params.kind !== 'avatar' ? params.workspaceId : undefined,
            maxTotalSize,
            plan
          );
          return;
        }
      }

      return await Promise.all(
        filesArray.map((f, idx) =>
          insertSingle(
            f,
            params.kind === 'avatar' ? undefined : params.workspaceId,
            keys?.[idx],
            kind
          )
        )
      );
    },
    [ingest, onCreate]
  );

  usePoll(
    pollInterval ?? 3000,
    pollInterval != undefined && pending.length > 0,
    async () => {
      const waiting = pending.filter(
        (item): item is WaitingItem => item.kind === 'waiting'
      );
      if (waiting.length === 0) {
        return 0;
      }
      const byId = new Map(waiting.map((item) => [item.request.id, item]));

      await withLoading(async () => {
        const { processingRequests } = await getProcessingRequestDetailMany(
          waiting.map(({ request }) => request.id)
        );
        ingest(
          processingRequests.map((request) => ({
            request,
            key: byId.get(request.id)!.key,
          }))
        );
      });

      return waiting.length;
    }
  );

  return {
    insert,
    insertFromExistingProcessingRequests,
    pending,
    isLoading: loadingCount > 0,
  };
};
