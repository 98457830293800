import Spinner from '@ui/Spinner';

import styles from './LoadingPlaceholder.module.scss';

const LoadingPlaceholder = ({
  message = 'Loading your stuff',
}: {
  message?: string;
}) => {
  return (
    <div className={styles.content}>
      <Spinner size={24} />
      {message}
    </div>
  );
};

export default LoadingPlaceholder;
