import { Icon24, IconProps } from '@spaceduck/icons';

import { Variant } from '@ui/AlertBanner';

const { AlertDanger, AlertInfo, AlertSuccess, AlertWarning } = Icon24;

type AlertProps = {
  variant: Variant;
} & IconProps;

export default function Alert(props: AlertProps) {
  const { variant, ...iconProps } = props;
  switch (variant) {
    case 'danger':
      return <AlertDanger {...iconProps} />;
    case 'info':
      return <AlertInfo {...iconProps} />;
    case 'success':
      return <AlertSuccess {...iconProps} />;
    case 'warning':
      return <AlertWarning {...iconProps} />;
    default:
      return null;
  }
}
