import Button, { ButtonLink } from '@ui/Button';
import Tooltip from '@ui/Tooltip';
import styles from '@components/detailsModal/DetailsModalHeader.module.scss';
import { useBackgroundLocation } from '@/hooks/useBackgroundLocation';
import { Icon24 } from '@spaceduck/icons';
const { Close, DrawerRight, Left, Right, Share } = Icon24;

export default function DetailsModalHeaderPlaceholder() {
  const backgroundLocation = useBackgroundLocation();

  return (
    <div className={styles.header}>
      <nav className={styles.navigation}>
        <Tooltip
          content="Close"
          icons={['Esc']}
          size="medium"
          variant="secondary"
        >
          <ButtonLink variant="icon" to={backgroundLocation || '/'}>
            <Close size={20} />
          </ButtonLink>
        </Tooltip>
        <span className={styles.divider}></span>
        <Button variant="icon" disabled>
          <Left />
        </Button>
        <Button variant="icon" disabled>
          <Right />
        </Button>
      </nav>
      <div className={styles.utils}>
        <Button variant="icon" disabled>
          <Share size={20} />
        </Button>
        <span className={styles.divider}></span>
        <Button variant="icon" disabled>
          <DrawerRight size={20} />
        </Button>
      </div>
    </div>
  );
}
