import { useCallback, useState } from 'react';
import clsx from 'clsx';

import { PdfDocument, PdfPage } from '@components/PdfDocument';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import { css } from '@lib/css';
import ScrollArea from '@ui/ScrollArea';
import styles from './PdfView.module.scss';
import { PdfPlaceholder } from './PdfView';

export default function PdfPreview({
  name,
  url,
}: {
  name: string;
  url: string;
}) {
  const [numPages, setNumPages] = useState(0);
  const handleLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
    },
    []
  );

  return (
    <div className={clsx(styles.pdfPreview, styles.noToolbar)}>
      <div className={clsx(styles.scaleContainer, styles.isPreview)}>
        <ScrollArea
          className={clsx(
            styles.scrollAreaMain,
            numPages === 0 && styles.noScroll
          )}
          style={css({
            '--width': '100%',
            '--maxHeight': '100%',
          })}
        >
          <div className={styles.pdfDocument}>
            <PdfDocument
              file={url}
              onLoadSuccess={handleLoadSuccess}
              loading={<LoadingPlaceholder />}
            >
              {[...Array(numPages).keys()].map((pageIndex) => {
                return (
                  <div
                    key={pageIndex}
                    className={styles.pdfPage}
                    data-index={pageIndex + 1}
                  >
                    <PdfPage pageIndex={pageIndex} scale={1} />
                  </div>
                );
              })}
              {numPages === 0 && <PdfPlaceholder name={name} url={url} />}
            </PdfDocument>
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}
