import type { MouseEvent } from 'react';
import { useController } from 'yet-another-react-lightbox';

import Tooltip from '@ui/Tooltip';
import Button from '@ui/Button';
import styles from './LightboxModalHeader.module.scss';
import { Icon24 } from '@spaceduck/icons';
const { Close, Left, Right, ZoomIn, ZoomOut } = Icon24;

type LightboxMenuButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
  type: 'close' | 'next' | 'prev' | 'zoomIn' | 'zoomOut';
};

export default function LightboxMenuButton({
  disabled = false,
  onClick,
  type,
}: LightboxMenuButtonProps) {
  const { close, focus: resetFocus, prev, next } = useController();
  const wrapClickAction = (ev: MouseEvent, cb?: () => void) => {
    ev.preventDefault();
    cb?.();
    resetFocus();
  };

  switch (type) {
    case 'close':
      return (
        <Tooltip
          content="Close"
          icons={['Esc']}
          size="medium"
          variant="secondary"
        >
          <Button
            className={styles.button}
            disabled={disabled}
            onClick={(ev) => wrapClickAction(ev, close)}
            variant="icon"
          >
            <Close />
          </Button>
        </Tooltip>
      );
    case 'prev':
      return (
        <Tooltip
          content="Previous Slide"
          icons={['←']}
          size="medium"
          variant="secondary"
        >
          <Button
            className={styles.button}
            disabled={disabled}
            onClick={(ev) => wrapClickAction(ev, prev)}
            variant="icon"
          >
            <Left />
          </Button>
        </Tooltip>
      );
    case 'next':
      return (
        <Tooltip
          content="Next Slide"
          icons={['→']}
          size="medium"
          variant="secondary"
        >
          <Button
            className={styles.button}
            disabled={disabled}
            onClick={(ev) => wrapClickAction(ev, next)}
            variant="icon"
          >
            <Right />
          </Button>
        </Tooltip>
      );
    case 'zoomIn':
      return (
        <Tooltip content="Zoom In" size="medium" variant="secondary">
          <Button
            className={styles.button}
            disabled={disabled}
            onClick={(ev) => wrapClickAction(ev, onClick)}
            variant="icon"
          >
            <ZoomIn />
          </Button>
        </Tooltip>
      );
    case 'zoomOut':
      return (
        <Tooltip content="Zoom Out" size="medium" variant="secondary">
          <Button
            disabled={disabled}
            className={styles.button}
            onClick={(ev) => wrapClickAction(ev, onClick)}
            variant="icon"
          >
            <ZoomOut />
          </Button>
        </Tooltip>
      );
    default:
      return null;
  }
}
