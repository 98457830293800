import { useId } from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import type { ProjectDetailDTO } from '@spaceduck/api';

import { useAddWebLinkToProject } from '@api/project';
import { catchApiErrorIntoToast } from '@api/util';
import { useModalManager } from '@context/ModalManagerContext';
import Button from '@ui/Button';
import Dialog from '@ui/Dialog';
import DropdownMenu, { MenuItem } from '@ui/DropdownMenu';
import { Favicon } from '@ui/Favicon';
import Spinner from '@ui/Spinner';
import { knownErrors } from '@/const';
import ProjectMenuAccordion from './ProjectMenuAccordion';
import styles from './ProjectMenuLinks.module.scss';
import createToast from '@/utils/createToast';
import {
  useDeleteProjectWebLink,
  usePatchProjectWebLink,
} from '@/api/projectWebLink';
import { useDeleteConfirmModal } from '@/components/ui/ConfirmModal';
import { Icon16, Icon24 } from '@spaceduck/icons';
const { Add, Links: LinksIcon, MenuMore } = Icon16;
const { Pencil, TrashCan } = Icon24;

type ProjectMenuLinksProps = {
  project: ProjectDetailDTO;
};

export default function ProjectMenuLinks({ project }: ProjectMenuLinksProps) {
  if (!project) {
    // TODO: Replace early returns with throw errors or place returns after hooks
    throw new Error(knownErrors.projectError, { cause: 'No space' });
  }

  const { capabilities } = project;
  const editCapability = capabilities?.find(
    ({ capability }) => capability === 'edit'
  );
  const canEdit = editCapability?.capable ?? false;

  const { open: openCreateLinkModal } = useManageLinkModal({
    projectId: project.id,
  });

  return (
    <ProjectMenuAccordion
      label="Links"
      alternateAction={
        <>
          {canEdit && (
            <Button
              isSquare
              onClick={(ev) => {
                ev.preventDefault();
                openCreateLinkModal();
              }}
              size="xs"
              type="button"
              variant="ghost"
            >
              <Add />
            </Button>
          )}
        </>
      }
    >
      <Links
        project={project}
        canEdit={canEdit}
        openCreateLinkModal={openCreateLinkModal}
      />
    </ProjectMenuAccordion>
  );
}

const Links = ({
  project,
  canEdit,
  openCreateLinkModal,
}: {
  project: ProjectDetailDTO;
  openCreateLinkModal: () => void;
  canEdit: boolean;
}) => {
  const { open: openEditLinkModal } = useManageLinkModal({
    projectId: project.id,
  });
  const { mutateAsync: deleteProjectWebLink } = useDeleteProjectWebLink();
  const confirmDeleteModal = useDeleteConfirmModal<{ id: string }>({
    title: 'Delete Link',
    subtitle:
      'This action cannot be undone. Your link will be permanently deleted.',
    confirmText: 'Yes, delete link',
    onConfirm: async ({ id: projectWebLinkId }: { id: string }) => {
      await deleteProjectWebLink({ projectWebLinkId });
      createToast({
        titleText: 'Link deleted',
        bodyText: 'Link was removed from space',
        iconVariant: 'success',
      });
    },
  });

  if (project.links.length === 0) {
    if (canEdit) {
      return (
        <p
          className={styles.placeholderText}
          onClick={() => openCreateLinkModal()}
        >
          Add links to this space
        </p>
      );
    }
    return <p className={styles.placeholderText}>No links available.</p>;
  }

  return (
    <ul className={styles.linksList}>
      {project.links.map((link) => {
        const { id, label, url } = link;

        return (
          <li key={id}>
            <a href={url} target="_blank">
              <span>
                <Favicon url={url} alt={label ?? ''} size={24} />
              </span>
              <span>{label ?? url}</span>
            </a>
            {canEdit && (
              <DropdownMenu
                align="end"
                className={styles.dropdownMenu}
                triggerContent={
                  <Button
                    isSquare
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    size="xs"
                    type="button"
                    variant="icon"
                    className={styles.editButton}
                  >
                    <MenuMore />
                  </Button>
                }
              >
                <MenuItem
                  asChild
                  iconBefore={<Pencil size={20} />}
                  onSelect={() => {
                    openEditLinkModal(link);
                  }}
                >
                  <span>Edit</span>
                </MenuItem>
                <MenuItem
                  iconBefore={<TrashCan size={20} />}
                  onSelect={() => {
                    confirmDeleteModal.open({ id: link.id });
                  }}
                >
                  Delete
                </MenuItem>
              </DropdownMenu>
            )}
          </li>
        );
      })}
    </ul>
  );
};

type ProjectLink = {
  url: string;
  label?: string;
  id?: string;
};

type ProjectLinkFormData = ProjectLink;

const useManageLinkModal = ({ projectId }: { projectId: string }) => {
  const { openModal, closeModal } = useModalManager();
  return {
    open: (link?: ProjectLink) => {
      openModal({
        component: <ManageLinkModal projectId={projectId} link={link} />,
      });
    },
    close: closeModal,
  };
};

const ManageLinkModal = ({
  projectId,
  link,
  closeModal,
  isOpen = true,
}: {
  projectId: string;
  link?: ProjectLink;
  closeModal?: () => void;
  isOpen?: boolean;
}) => {
  const id = useId();
  const { mutateAsync: addWebLinkToProject, isPending: addIsPending } =
    useAddWebLinkToProject();
  const { mutateAsync: patchProjectWebLink, isPending: patchIsPending } =
    usePatchProjectWebLink();
  const isPending = addIsPending || patchIsPending;
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<ProjectLinkFormData>();

  const handleLinkManagement = async (data: ProjectLinkFormData) => {
    if (link?.id) {
      const { url, label } = data;
      const response = await patchProjectWebLink({
        projectWebLinkId: link.id,
        patch: { url, label: label ?? '' },
      });
      createToast({
        titleText: 'Link Updated',
        bodyText: `"${response.webLink.label}" was updated.`,
        iconVariant: 'success',
      });
    } else {
      const { url, label } = data;
      const response = await addWebLinkToProject({
        projectId,
        url,
        label: label ?? '',
      });
      createToast({
        titleText: 'Added link',
        bodyText: `"${response.webLink.label}" was added to the space`,
        iconVariant: 'success',
      });
    }
  };

  const onSubmit = catchApiErrorIntoToast(async (data: ProjectLinkFormData) => {
    if (data.url) {
      await handleLinkManagement(data);
    }
    reset();
    closeModal?.();
  });

  if (!projectId) return null;
  const pendingText = link?.id ? 'Updating link' : 'Adding link';
  const buttonText = link?.id ? 'Update link' : 'Add link';
  const validateUrl = (link: string) => {
    let url;
    try {
      url = new URL(link);
    } catch (err) {
      return 'Please insert a valid url';
    }
    const allowedProtocols = new Set(['http:', 'https:']);
    if (!allowedProtocols.has(url.protocol)) {
      return 'URL should start with "https://" or "http://"';
    }
  };

  return (
    <Dialog
      className={styles.dialog}
      breadcrumb={[
        {
          icon: <LinksIcon />,
          text: link ? 'Edit link' : 'New link',
        },
      ]}
      closeModal={closeModal}
      isOpen={isOpen}
      maxWidth="38.75rem"
    >
      <form
        className={clsx(styles.form, isPending && styles.formDisabled)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="formBody">
          <div className="formGroup">
            <label htmlFor={`${id}Label`}>URL</label>
            <input
              {...register('url', {
                required: 'URL is required',
                validate: (url) => validateUrl(url),
              })}
              autoComplete="off"
              className={errors?.url && 'hasError'}
              defaultValue={link ? link.url : ''}
              id={`${id}Url`}
              placeholder="https://..."
              type="text"
            />
            {errors?.url?.message && (
              <p className="errorMessage">{errors.url.message}</p>
            )}
          </div>
          <div className="formGroup">
            <label htmlFor={`${id}Label`}>Title</label>
            <input
              {...register('label')}
              autoComplete="off"
              defaultValue={link ? link.label : ''}
              className={errors?.label && 'hasError'}
              id={`${id}Label`}
              placeholder="e.g. Jira workspace"
              type="text"
            />
            {errors?.label?.message && (
              <p className="errorMessage">{errors.label.message}</p>
            )}
          </div>
          <div className={styles.formFooter}>
            <div className={styles.formActions}>
              <Button
                disabled={isPending}
                onClick={closeModal}
                size="sm"
                type="button"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isPending}
                variant="primary"
                size="sm"
              >
                {isPending ? (
                  <>
                    <Spinner size={16} />
                    {pendingText}
                  </>
                ) : (
                  buttonText
                )}
              </Button>
            </div>
          </div>
        </div>
      </form>
      {isPending && <div className={styles.pendingOverlay}></div>}
    </Dialog>
  );
};
