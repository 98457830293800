import useWorkspaceId from '@hooks/useWorkspaceId';
import MenuLink from '../MenuLink';
import styles from './MenuMainNavSection.module.scss';
import { useKnockFeed } from '@knocklabs/react';
import { useEffect } from 'react';
import { urlFor } from '@/urls';
import { Icon16 } from '@spaceduck/icons';

const { InboxNotifications, Project, ProjectCategory, RepositoryBrowse } =
  Icon16;

// TODO: Disabled pending feature decision @ari
const ENABLE_INBOX_MENU_LINK = false;

const KnockCounter = () => {
  const feed = useKnockFeed();
  useEffect(() => {
    feed?.feedClient.fetch();
  }, [feed?.feedClient]);
  if (!feed) {
    return 0;
  }
  return feed.useFeedStore((state) => state.metadata.unread_count);
};

const InboxMenuLink = ({ workspaceId }: { workspaceId: string | null }) => {
  if (!ENABLE_INBOX_MENU_LINK) {
    return null;
  }
  if (!workspaceId) {
    return null;
  }

  return (
    <MenuLink
      icon={<InboxNotifications />}
      url={urlFor('workspaceInbox', { workspaceId })}
    >
      <span className={styles.inboxLink}>
        Inbox
        <span className={styles.inboxCounter}>
          <KnockCounter />
        </span>{' '}
      </span>
    </MenuLink>
  );
};

export default function MenuMainNavSection() {
  const workspaceId = useWorkspaceId();

  return (
    <nav className={styles.mainNav}>
      {workspaceId && (
        <>
          <MenuLink
            icon={<RepositoryBrowse />}
            url={urlFor('workspaceRepository', { workspaceId })}
          >
            Browse
          </MenuLink>
          <MenuLink
            icon={<ProjectCategory />}
            url={urlFor('workspaceCategories', { workspaceId })}
          >
            Categories
          </MenuLink>
          <InboxMenuLink workspaceId={workspaceId} />
          <MenuLink
            icon={<Project />}
            url={urlFor('workspaceSpaces', { workspaceId })}
          >
            Spaces
          </MenuLink>
        </>
      )}
    </nav>
  );
}
