import { useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';

import ErrorDuck from '@assets/img/ErrorDuck';
import Head from '@components/Head';
import Button from '@ui/Button';
import { getDuckFact } from '@utils/getDuckFact';
import styles from './ErrorPage.module.scss';

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <>
      <Head title="Page not found" />
      <div className={clsx('body5', styles.container)}>
        <ErrorDuck />
        <h1 className="h6">Not found (404)</h1>
        <p>“{getDuckFact()}.”</p>
        <Button onClick={() => navigate('/')}>
          Go to the Spaceduck Homepage
        </Button>
      </div>
    </>
  );
}
