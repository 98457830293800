import clsx from 'clsx';
import { Icon24 } from '@spaceduck/icons';

import { MediaGroupStatus as MediaGroupStatusIcon } from '@components/icons';
import Button from '@ui/Button';
import DropdownMenu, { TriggerButton } from '@ui/DropdownMenu';
import styles from './StatusMenu.module.scss';
import { MediaGroupStatus } from '@spaceduck/api';
import { useProject } from '@/api/project';

type StatusMenuItemProps = {
  className?: string;
  showSelectedAsCheck?: boolean;
  handleStatusUpdate?: (statusId: string | null) => void;
  currentStatus?: MediaGroupStatus | null;
  status: MediaGroupStatus;
};

type StatusMenuProps = {
  triggerContent?: React.ReactNode;
  width?: number;
  projectId: string;
} & Partial<StatusMenuItemProps>;

const { Check, StatusFilter } = Icon24;

export default function StatusMenu(statusMenuProps: StatusMenuProps) {
  const { triggerContent, projectId, width, ...statusMenuItemProps } =
    statusMenuProps;
  const { data: project } = useProject(projectId);

  if (!project?.project) {
    return;
  }

  return (
    <DropdownMenu
      className={styles.statusMenu}
      isPadded
      triggerContent={
        triggerContent ? (
          triggerContent
        ) : (
          <Button
            size="sm"
            variant="outlined"
            iconBefore={<StatusFilter size={16} />}
          >
            Status
          </Button>
        )
      }
      width={width}
    >
      {project.project.mediaGroupStatuses.map((value) => (
        <StatusMenuItem
          key={value.id}
          {...statusMenuItemProps}
          status={value}
        />
      ))}
    </DropdownMenu>
  );
}

export const StatusMenuItem = ({
  className,
  handleStatusUpdate,
  showSelectedAsCheck = false,
  currentStatus = null,
  status,
}: StatusMenuItemProps) => {
  return (
    <TriggerButton
      key={status.id}
      className={clsx(
        'noHoverIcon',
        styles.menuItem,
        showSelectedAsCheck && styles.showCheck,
        className
      )}
      iconBefore={<MediaGroupStatusIcon status={status} size={20} />}
      iconAfter={
        showSelectedAsCheck && currentStatus?.id === status.id ? (
          <span className={clsx('check', styles.check)}>
            <Check size={20} />
          </span>
        ) : undefined
      }
      onClick={() => {
        handleStatusUpdate?.(status.id);
      }}
    >
      {status.label}
    </TriggerButton>
  );
};
