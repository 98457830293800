import {
  ErrorBoundary as ReactErrorBoundary,
  type FallbackProps,
} from 'react-error-boundary';

import { knownErrors } from '@/const';
import { OuterSpace } from '@components/layouts/Stars';
import Error400Page from '@pages/400';

const generateDefaultMessage = (subject: string) => {
  return `The existence of this ${subject} is questionable. Please check if this ${subject} exists and if you have access to it.`;
};

function getErrorInfo(errorKind: string | null) {
  switch (errorKind) {
    case knownErrors.accessForbidden:
      return {
        title: errorKind,
        message: 'Please contact your administrator.',
      };
    case knownErrors.workspaceError:
      return {
        title: errorKind,
        message: generateDefaultMessage('workspace'),
      };
    case knownErrors.projectError:
      return {
        title: errorKind,
        message: generateDefaultMessage('space'),
      };
    case knownErrors.mediaGroupError:
      return {
        title: errorKind,
        message: generateDefaultMessage('media group'),
      };
    default:
      return {
        title: 'Bad Request (400)',
        message: 'Something went wrong...',
      };
  }
}

const intoErrorKind = (error: any): string | null => {
  const errorKind = error?.cause?.payload?.errorKind;

  if (errorKind === 'access-forbidden') {
    return knownErrors.accessForbidden;
  }

  if (typeof errorKind === 'string' || errorKind instanceof String) {
    return `${errorKind}`;
  }

  return null;
};

function FallbackComponent({ error, resetErrorBoundary }: FallbackProps) {
  const { title, message } = getErrorInfo(intoErrorKind(error));

  return (
    <OuterSpace>
      <Error400Page
        buttonAction={resetErrorBoundary}
        title={title}
        message={message}
      />
    </OuterSpace>
  );
}

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <ReactErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </ReactErrorBoundary>
  );
}
