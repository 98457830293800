import { useMemo, useReducer } from 'react';
import {
  MediaGroupCategoryDetail,
  patchMediaGroupCategoryField,
} from '@spaceduck/api';

import {
  useCreateMediaGroupCategoryField,
  useDeleteMediaGroupCategoryField,
  usePatchMediaGroupCategory,
  useReorderMediaGroupCategoryFields,
} from '@api/mediaGroupCategory';
import { catchApiErrorIntoToast } from '@api/util';
import {
  CreatePropertyFormData,
  EditAliasFormData,
  EditPropertyFormData,
} from '@components/category/Forms';
import createToast from '@utils/createToast';
import {
  EditPayload,
  TableColumnMeta,
  TableColumnReducerAction,
} from '@/types/Category';

export const useCategoryHeader = ({
  category,
  patchFunction,
}: {
  category: MediaGroupCategoryDetail;
  patchFunction: typeof patchMediaGroupCategoryField;
}) => {
  const baseCategoryProperties: TableColumnMeta[] = [
    {
      id: '_id',
      label: category.label,
      order: 0,
      kind: 'text',
      width: category.labelWidth ?? 310,
      settings: null,
    },
  ];
  const baseCategoryPropertiesNames = baseCategoryProperties.map(
    ({ label }) => label
  );

  const customCategoryProperties = category.fields.map((field, idx) => ({
    ...field,
    order: idx + 1,
  }));

  const sortProperties = (properties: TableColumnMeta[]) => {
    return properties.sort(
      ({ order: orderA }, { order: orderB }) => orderA - orderB
    );
  };

  const categoryPropertiesReducer = (
    state: TableColumnMeta[],
    action: TableColumnReducerAction
  ): TableColumnMeta[] => {
    switch (action.type) {
      case 'ADD':
        if (
          typeof action.payload === 'object' &&
          !baseCategoryPropertiesNames.includes(action.payload.label)
        ) {
          return [...state, action.payload];
        }
        return state;
      case 'DELETE': {
        if (typeof action.payload === 'string') {
          const existionProperty = state.find(
            (property) => property.id === action.payload
          );
          if (
            existionProperty &&
            !baseCategoryPropertiesNames.includes(existionProperty.label)
          ) {
            return state.filter((column) => column.id !== action.payload);
          }
        }
        return state;
      }
      case 'EDIT':
        if (Array.isArray(action.payload)) {
          const propertyIds = action.payload.map(({ id }) => id);

          return state.reduce<TableColumnMeta[]>((acc, curr) => {
            if (propertyIds.includes(curr.id)) {
              const updatedProperty = (action.payload as EditPayload[]).find(
                ({ id }) => id === curr.id
              );

              if (!updatedProperty) {
                return [...acc, curr];
              }
              if (curr.id === '_id') {
                // Only alias is editable for first column
                return [
                  ...acc,
                  { ...curr, label: updatedProperty.property.label },
                ];
              } else if (
                state.map(({ id }) => id).includes(updatedProperty.id)
              ) {
                return [...acc, updatedProperty.property];
              }
            }
            return [...acc, curr];
          }, []);
        }
        return state;
      case 'SORT':
        return sortProperties(state);
      default:
        return state;
    }
  };

  const [categoryProperties, dispatchCategoryProperties] = useReducer(
    categoryPropertiesReducer,
    [
      ...baseCategoryProperties,
      ...customCategoryProperties.sort((a, b) => a.order - b.order),
    ]
  );

  const { mutateAsync: reorderMediaGroupCategoryFields } =
    useReorderMediaGroupCategoryFields({ categoryId: category.id });
  const { mutateAsync: asyncDeleteField } = useDeleteMediaGroupCategoryField();
  const deleteField = catchApiErrorIntoToast(asyncDeleteField);
  const { mutateAsync: createMediaGroupCategoryField } =
    useCreateMediaGroupCategoryField();

  const propertyNamesLowerCase = useMemo(() => {
    return categoryProperties.map(({ label }) => label.toLowerCase());
  }, [categoryProperties]);

  const swapProperty = async ({
    direction,
    propertyId,
  }: {
    direction: 'left' | 'right';
    propertyId: string;
  }) => {
    if (!(direction && propertyId)) return null;
    const propertyIndex = categoryProperties.findIndex(
      ({ id }) => propertyId === id
    );
    if (propertyIndex < 0) return;

    const currentProperty = categoryProperties[propertyIndex];
    if (!currentProperty) return;

    if (direction === 'left') {
      if (propertyIndex === 0) return;
      const prevProperty = categoryProperties.sort((a, b) => a.order - b.order)[
        propertyIndex - 1
      ];

      if (!prevProperty) return;

      const newOrder = categoryProperties
        .filter(({ id }) => id !== '_id')
        .map(({ id }) => {
          if (id === currentProperty.id) {
            return prevProperty.id;
          } else if (id === prevProperty.id) {
            return currentProperty.id;
          }
          return id;
        });

      const res = await reorderMediaGroupCategoryFields({
        id: category.id,
        fields: newOrder,
      });

      if (res.kind !== 'success') {
        createToast({
          titleText: 'Update failed',
          bodyText: 'Order of properties could not be updated.',
          iconVariant: 'warning',
        });

        return;
      }

      dispatchCategoryProperties({
        type: 'EDIT',
        payload: [
          {
            id: currentProperty.id,
            property: { ...currentProperty, order: prevProperty.order },
          },
          {
            id: prevProperty.id,
            property: { ...prevProperty, order: currentProperty.order },
          },
        ],
      });
      dispatchCategoryProperties({ type: 'SORT' });
    }

    if (direction === 'right') {
      if (propertyIndex === propertyNamesLowerCase.length - 1) return;
      const nextProperty = categoryProperties[propertyIndex + 1];

      if (!nextProperty) return;

      const newOrder = categoryProperties
        .filter(({ id }) => id !== '_id')
        .map(({ id }) => {
          if (id === currentProperty.id) {
            return nextProperty.id;
          } else if (id === nextProperty.id) {
            return currentProperty.id;
          }
          return id;
        });

      const res = await reorderMediaGroupCategoryFields({
        id: category.id,
        fields: newOrder,
      });

      if (res.kind !== 'success') {
        createToast({
          titleText: 'Update failed',
          bodyText: 'Order of properties could not be updated.',
          iconVariant: 'warning',
        });

        return;
      }

      if (!nextProperty) return;
      dispatchCategoryProperties({
        type: 'EDIT',
        payload: [
          {
            id: currentProperty.id,
            property: { ...currentProperty, order: nextProperty.order },
          },
          {
            id: nextProperty.id,
            property: { ...nextProperty, order: currentProperty.order },
          },
        ],
      });
      dispatchCategoryProperties({ type: 'SORT' });
    }
  };

  const deleteProperty = async (id: string) => {
    const existingProperty = categoryProperties.find(
      (property) => property.id === id
    );

    if (!existingProperty) {
      createToast({
        titleText: 'Oops, something went wrong',
        bodyText: `Property was not found.`,
        iconVariant: 'warning',
      });

      return;
    }

    dispatchCategoryProperties({
      type: 'DELETE',
      payload: id,
    });

    await deleteField(id); // TODO: Undo dispatch if delete fails?

    createToast({
      titleText: 'Property deleted successfully',
      bodyText: `Property name "${existingProperty.label}" was deleted.`,
      iconVariant: 'success',
    });
  };

  const { mutateAsync: patchMediaGroupCategory } = usePatchMediaGroupCategory();

  const editPropertyAlias = async (data: EditAliasFormData) => {
    const id = '_id';
    const { labelAlias } = data;

    const existingProperty = categoryProperties.find(
      (property) => property.id === id
    )!;

    const res = await patchMediaGroupCategory({
      id: category.id,
      patch: { labelAlias: data.labelAlias },
    });

    // TODO: handle failure
    if (res.kind !== 'success') {
      createToast({
        titleText: 'Property update failed',
        bodyText: 'Could not rename heading',
        iconVariant: 'warning',
      });
      return;
    }

    dispatchCategoryProperties({
      type: 'EDIT',
      payload: [
        {
          id: existingProperty.id,
          property: { ...existingProperty, label: labelAlias },
        },
      ],
    });

    createToast({
      titleText: 'Property edited successfully',
      bodyText: `Property name was changed to "${labelAlias}".`,
      iconVariant: 'success',
    });
  };

  const editPropertyName = async ({
    data,
    id,
  }: {
    data: EditPropertyFormData;
    id: string;
  }) => {
    const existingProperty = categoryProperties.find(
      (property) => property.id === id
    );
    if (!existingProperty) return;

    const res = await patchFunction({
      mediaGroupCategoryFieldId: existingProperty.id,
      patch: {
        label: data.label,
      },
    });

    if (res.kind !== 'success') {
      createToast({
        titleText: 'Property update failed',
        bodyText: 'Could not update table property',
        iconVariant: 'warning',
      });
      return;
    }

    dispatchCategoryProperties({
      type: 'EDIT',
      payload: [
        {
          id: existingProperty.id,
          property: { ...existingProperty, label: data.label },
        },
      ],
    });

    createToast({
      titleText: 'Property edited successfully',
      bodyText: `Property name was changed to "${data.label}".`,
      iconVariant: 'success',
    });
  };

  const createNewProperty = async (data: CreatePropertyFormData) => {
    if (!(data.label && data.kind)) return null;

    if (
      categoryProperties
        .map((property) => property.label.toLowerCase())
        .includes(data.label.toLowerCase())
    ) {
      createToast({
        titleText: 'Property already exists',
        bodyText: `The property name "${data.label}" already exists and was not added; properties must be unique.`,
        iconVariant: 'warning',
      });
      return null;
    }

    const nextItemOrder = Math.max(
      ...categoryProperties.map((property) => property.order)
    );

    const field = await createMediaGroupCategoryField({
      ...data,
      categoryId: category.id,
    });

    dispatchCategoryProperties({
      type: 'ADD',
      payload: {
        ...data,
        id: field.field.id,
        order: nextItemOrder + 1,
        width: 0,
      },
    });

    createToast({
      titleText: 'Property created successfully',
      bodyText: `Added "${data.label}" as a property.`,
      iconVariant: 'success',
    });
  };

  return {
    categoryProperties,
    createNewProperty,
    deleteProperty,
    dispatchCategoryProperties,
    editPropertyAlias,
    editPropertyName,
    swapProperty,
  };
};
