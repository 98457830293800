import { useEffect, useRef } from 'react';

export const useOnClickOutside = <T extends HTMLElement>({
  callback,
}: {
  callback?: () => void | Promise<void>;
}) => {
  const containerRef = useRef<T | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      event.target &&
      !containerRef.current.contains(event.target as Node)
    ) {
      callback?.();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return {
    containerRef,
  };
};
