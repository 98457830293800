import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import {
  useJoinProject,
  usePatchProject,
  useProject,
  useRemoveUserFromProject,
} from '@api/project';
import Breadcrumb from '@ui/Breadcrumb';
import breadcrumbStyles from '@ui/Breadcrumb.module.scss';
import Button from '@ui/Button';
import Tooltip from '@ui/Tooltip';
import { copyUrlToClipboard } from '@utils/copyToClipboard';
import styles from './PageHeader.module.scss';
import { useUserInfo } from '@/hooks/useAuth';
import Spinner from '@/components/ui/Spinner';
import createToast from '@/utils/createToast';
import { Icon16, Icon24 } from '@spaceduck/icons';
const { Project } = Icon16;
const { Favorite, FollowSubscribe } = Icon24;

export default function PageHeader() {
  const projectId = useParams<{ projectId: string }>().projectId!;
  const { data } = useProject(projectId);
  const { project } = data || {};

  if (!project) return null;

  return (
    <header className={clsx(breadcrumbStyles.headerBreadcrumbs, styles.header)}>
      <div className={styles.headerBreadcrumbs}>
        <Breadcrumb
          breadcrumb={[
            {
              icon: <Project />,
              text: 'Spaces',
            },
            {
              text: project.label ?? 'Progress',
            },
          ]}
        />
      </div>
      <div className={styles.headerActions}>
        <FollowButton projectId={projectId} />
        <StarButton projectId={projectId} />
        <Button
          className={styles.share}
          onClick={() => {
            copyUrlToClipboard();
          }}
          size="sm"
          type="button"
          variant="outlined"
        >
          Share
        </Button>
      </div>
    </header>
  );
}

const FollowButton = ({ projectId }: { projectId: string }) => {
  const user = useUserInfo();
  const { data, status } = useProject(projectId);

  const { mutateAsync: joinProject, isPending: isJoinPending } =
    useJoinProject();
  const { mutateAsync: removeUserFromProject, isPending: isLeavePending } =
    useRemoveUserFromProject();
  const isFollowingPending = isJoinPending || isLeavePending;

  const setFollowing = async (newIsFollowing: boolean) => {
    if (status !== 'success' || !user) {
      return;
    }
    const { project } = data;

    if (newIsFollowing) {
      await joinProject({ projectId });
      createToast({
        titleText: 'Following space',
        bodyText: `Joined "${project.label}"`,
        iconVariant: 'success',
      });
    } else {
      await removeUserFromProject({ projectId: project.id, userId: user.id });
      createToast({
        titleText: 'Stopped following space',
        bodyText: `left "${project.label}"`,
        iconVariant: 'success',
      });
    }
  };

  if (status === 'pending') {
    return <Spinner />;
  }
  if (status === 'error') {
    return null;
  }

  const {
    project: { isFollowing },
  } = data;

  return (
    <Tooltip content={isFollowing ? 'Unfollow' : 'Follow'}>
      <Button
        isSquare
        onClick={() => setFollowing(!isFollowing)}
        size="sm"
        type="button"
        variant="ghost"
        disabled={isFollowingPending}
      >
        {isFollowingPending ? (
          <Spinner />
        ) : (
          <FollowSubscribe active={isFollowing} size={20} />
        )}
      </Button>
    </Tooltip>
  );
};

const StarButton = ({ projectId }: { projectId: string }) => {
  const { data, status } = useProject(projectId);

  const { mutateAsync: patchProject, isPending } = usePatchProject();

  const setIsStarred = async (isStarred: boolean) => {
    if (status !== 'success') {
      return;
    }
    const { project } = data;

    await patchProject({ id: project.id, patch: { isStarred } });

    if (isStarred) {
      createToast({
        titleText: 'Added to favorites',
        bodyText: `Starred "${project.label}"`,
        iconVariant: 'success',
      });
    } else {
      createToast({
        titleText: 'Removed from favorites',
        bodyText: `Un-starred "${project.label}"`,
        iconVariant: 'success',
      });
    }
  };

  if (status === 'pending') {
    return <Spinner />;
  }
  if (status === 'error') {
    return null;
  }

  const {
    project: { isStarred },
  } = data;

  return (
    <Tooltip content={isStarred ? 'Remove from favorites' : 'Add to favorites'}>
      <Button
        isSquare
        onClick={() => setIsStarred(!isStarred)}
        size="sm"
        type="button"
        variant="ghost"
        disabled={isPending}
      >
        {isPending ? <Spinner /> : <Favorite size={20} active={isStarred} />}
      </Button>
    </Tooltip>
  );
};
