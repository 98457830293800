import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';
import debounce from 'lodash/debounce';
import type { MediaGroupCategory } from '@spaceduck/api';
import { Icon24, Icon64 } from '@spaceduck/icons';

import {
  useCreateMediaGroupCategory,
  useDeleteMediaGroupCategory,
  usePatchMediaGroupCategory,
} from '@api/mediaGroupCategory';
import { useListMediaGroupCategories } from '@api/workspace';
import NoEntries from '@components/NoEntries';
import { useModalManager } from '@context/ModalManagerContext';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { EditMenu, Search } from '@pages/common';
import Button from '@ui/Button';
import { useConfirmModal } from '@ui/ConfirmModal';
import Dialog from '@ui/Dialog';
import { DropdownMenuItem } from '@ui/DropdownMenu';
import createToast from '@utils/createToast';
import { Container, Header, SettingsError, TopNav } from './common';
import styles from './CategoryManager.module.scss';

const { Add } = Icon24;
const { ProjectCategory } = Icon64;

type MediaGroupCategoryData = {
  label: string;
};

const CreateMediaGroupCategoryModel = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const workspaceId = useWorkspaceId();
  const { mutateAsync: createMediaGroupCategory } =
    useCreateMediaGroupCategory();

  const onSubmit = async (data: MediaGroupCategoryData) => {
    if (!workspaceId) {
      console.error(
        'Failed to create media group category due to missing workspaceId'
      );
      createToast({
        titleText: 'Error creating content category',
        bodyText: 'Please try again later.',
        iconVariant: 'warning',
      });
      return;
    }
    const { created, category } = await createMediaGroupCategory({
      workspaceId,
      label: data.label,
    });
    closeModal();

    if (created) {
      createToast({
        titleText: 'Category added successfully',
        bodyText: `Added "${category.label}" as a category.`,
        iconVariant: 'success',
      });
    } else {
      createToast({
        titleText: 'Category already exists',
        bodyText: `The category name "${category.label}" already exists and was not added; categories must be unique.`,
        iconVariant: 'warning',
      });
    }
  };

  return (
    <CreateOrEditForm
      closeModal={closeModal}
      heading="Create category"
      onSubmit={onSubmit}
      saveText="Save"
    />
  );
};

const EditMediaGroupCategoryModel = ({
  mediaGroupCategory,
  closeModal,
}: {
  mediaGroupCategory: MediaGroupCategory;
  closeModal: () => void;
}) => {
  const { mutateAsync: patchMediaGroupCategory } = usePatchMediaGroupCategory();
  const onSubmit = async (data: MediaGroupCategoryData) => {
    const response = await patchMediaGroupCategory({
      id: mediaGroupCategory.id,
      patch: data,
    });
    closeModal();
    createToast({
      titleText: 'Category edited successfully',
      bodyText: `Category name was changed to "${response.category.label}".`,
      iconVariant: 'success',
    });
  };

  return (
    <CreateOrEditForm
      mediaGroupCategory={mediaGroupCategory}
      onSubmit={onSubmit}
      closeModal={closeModal}
    />
  );
};

const CreateOrEditForm = ({
  closeModal,
  heading = 'Edit category',
  onSubmit,
  saveText = 'Save',
  mediaGroupCategory,
}: {
  closeModal: () => void;
  heading?: string;
  onSubmit: (data: MediaGroupCategoryData) => void;
  saveText?: string;
  mediaGroupCategory?: MediaGroupCategory;
}) => {
  const { register, handleSubmit, reset, setFocus } =
    useForm<MediaGroupCategoryData>();

  const onOpenAutoFocus = (ev: Event) => {
    ev.preventDefault();
    setFocus('label');
  };

  return (
    <Dialog
      className={styles.tagModal}
      closeModal={closeModal}
      headerPadding={0}
      isOpen={true}
      maxWidth="32.5rem"
      modalHeading={heading}
      onOpenAutoFocus={onOpenAutoFocus}
      padding="lg"
    >
      <form
        className={styles.tagForm}
        onSubmit={handleSubmit(async (data) => {
          await onSubmit(data);
          reset();
        })}
      >
        <div className="formGroup">
          <label htmlFor="categoryName">Category name</label>
          <input
            defaultValue={mediaGroupCategory?.label}
            id="categoryName"
            type="text"
            {...register('label')}
          />
        </div>
        <div className={styles.tagFormFooter}>
          <Button
            size="sm"
            type="button"
            variant="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button size="sm" type="submit" variant="primary">
            {saveText}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export function useCreateMediaGroupCategoryModel() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: () => {
      openModal({
        component: <CreateMediaGroupCategoryModel closeModal={closeModal} />,
      });
    },
    close: closeModal,
  };
}

export function useEditMediaGroupCategoryModal() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: (mediaGroupCategory: MediaGroupCategory) => {
      openModal({
        component: (
          <EditMediaGroupCategoryModel
            mediaGroupCategory={mediaGroupCategory}
            closeModal={closeModal}
          />
        ),
      });
    },
    close: closeModal,
  };
}

type MediaGroupCategoryTableProps = {
  mediaGroupCategories: MediaGroupCategory[];
  lastRef: ReturnType<typeof useInView>['ref'];
};

const MediaGroupCategoryTable = ({
  mediaGroupCategories,
  lastRef,
}: MediaGroupCategoryTableProps) => {
  const lastIndex = mediaGroupCategories.length - 1;

  const { mutateAsync: deleteMediaGroupCategory } =
    useDeleteMediaGroupCategory();

  const editModal = useEditMediaGroupCategoryModal();
  const confirmDeleteModal = useConfirmModal<{ id: string }>({
    title: 'Delete category',
    subtitle:
      'This action cannot be undone. This category will be permanently deleted.',
    confirmVariant: 'danger',
    confirmText: 'Yes, delete category',
    onConfirm: async (vars) => {
      if (!vars) return;
      const { id } = vars;
      const response = await deleteMediaGroupCategory(id);
      createToast({
        titleText: 'Category deleted successfully',
        bodyText: `Category name "${response.category.label}" was deleted.`,
        iconVariant: 'success',
      });
    },
  });

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.label}>Category name</th>
          <th className={styles.actions} title="Actions"></th>
        </tr>
      </thead>
      <tbody>
        {mediaGroupCategories.map((category, index) => (
          <tr key={category.id} ref={index === lastIndex ? lastRef : null}>
            <td>{category.label}</td>
            <td>
              <EditMenu>
                <DropdownMenuItem
                  onSelect={() => editModal.open(category)}
                  disabled={
                    !category.capabilities?.find(
                      ({ capability, capable }) =>
                        capability === 'edit' && capable
                    )
                  }
                >
                  Edit
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => confirmDeleteModal.open({ id: category.id })}
                  disabled={
                    !category.capabilities?.find(
                      ({ capability, capable }) =>
                        capability === 'delete' && capable
                    )
                  }
                >
                  Delete
                </DropdownMenuItem>
              </EditMenu>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const MediaGroupCategoryManager = ({
  setAllowCreate,
}: {
  setAllowCreate: (value: boolean) => void;
}) => {
  const { ref, inView } = useInView();
  const [searchQuery, setSearchQuery] = useState('');
  const workspaceId = useWorkspaceId();
  const {
    data: paginator,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    status,
  } = useListMediaGroupCategories(workspaceId, searchQuery);
  const pageCount = paginator?.pages.length ?? 0;
  const debouncedSetSearchQuery = useCallback(
    debounce(setSearchQuery, 400),
    []
  );
  const lastPage = paginator?.pages.at(-1);
  const allowCreate = !!lastPage?.capabilities.find(
    ({ capability, capable }) => capability === 'create' && capable
  );

  useEffect(() => {
    setAllowCreate(allowCreate);
  }, [allowCreate]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, pageCount, fetchNextPage, isFetchingNextPage]);

  if (status === 'error') {
    return (
      <Container>
        <PageHeader />
        <SettingsError />
      </Container>
    );
  }

  if (lastPage?.total === 0) {
    return <NoMediaGroupCategory />;
  }

  const mediaGroupCategories = paginator?.pages.flatMap(
    (page) => page.categories
  );

  return (
    <Container>
      <PageHeader />
      <Search
        defaultValue={searchQuery}
        onInput={(e) => debouncedSetSearchQuery(e.currentTarget.value)}
        placeholder="Search categories..."
        status={status}
      />
      {status === 'pending' ? (
        <div className={styles.statusMessage}>Searching...</div>
      ) : (
        <>
          {!!mediaGroupCategories?.length ? (
            <>
              <MediaGroupCategoryTable
                mediaGroupCategories={mediaGroupCategories}
                lastRef={ref}
              />
              {isFetchingNextPage && (
                <div className={styles.statusMessage}>Loading more...</div>
              )}
            </>
          ) : (
            <div className={styles.statusMessage}>No matches found.</div>
          )}
        </>
      )}
    </Container>
  );
};

export default function CategoryManagerPage() {
  const createModal = useCreateMediaGroupCategoryModel();
  const [allowCreate, setAllowCreate] = useState(false);

  return (
    <>
      <TopNav
        buttonIsDisabled={!allowCreate}
        buttonOnClick={() => createModal.open()}
        buttonText="New category"
        currentBreadcrumb="Categories"
        showAddIcon
        owner="workspace"
        title="Manage Categories"
      />
      <MediaGroupCategoryManager setAllowCreate={setAllowCreate} />
    </>
  );
}

const NoMediaGroupCategory = () => {
  const createModal = useCreateMediaGroupCategoryModel();

  return (
    <NoEntries icon={<ProjectCategory />} pageType="settings">
      <h1>Category</h1>
      <p>
        Categories allow you to organize your content into distinct groups such
        as design, research, bugs, reviews, and more, making it easier to manage
        and navigate your work.
      </p>
      <Button
        iconBefore={<Add />}
        onClick={() => createModal.open()}
        size="sm"
        variant="primary"
      >
        New category
      </Button>
    </NoEntries>
  );
};

const PageHeader = () => {
  return (
    <Header>
      <h1>Categories</h1>
      <p>Manage your workspace categories.</p>
    </Header>
  );
};
