import { MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';
import { Icon32 } from '@spaceduck/icons';

import Button from '@ui/Button';
import Spinner from '@ui/Spinner';
import PositionContainer from './PositionContainer';
import styles from './BookmarkPlaceholder.module.scss';

const { Open } = Icon24;
const { Bookmark: BookmarkIcon } = Icon32;

type BookmarkPlaceholderProp = {
  mediaGroup: MediaGroupDetailDTO;
};

export default function BookmarkPlaceholder({
  mediaGroup,
}: BookmarkPlaceholderProp) {
  if (mediaGroup.media.length > 0) {
    return null;
  }

  const { contentType, isGeneratingContent, linkUrl, linkUrlSource, media } =
    mediaGroup;
  const isYoutubeVideo =
    mediaGroup.embed?.kind === 'youtube' && !!mediaGroup.embed?.videoId;

  const hasEmbeddedView =
    mediaGroup.contentType === 'bookmark' &&
    (mediaGroup.media.length > 0 || isYoutubeVideo);

  if (
    (hasEmbeddedView && contentType !== 'bookmark') ||
    (!isGeneratingContent && media.length)
  )
    return null;

  return (
    <PositionContainer>
      <div className={styles.sizeContainer}>
        {isGeneratingContent ? <Spinner /> : <BookmarkIcon />}
        <h3>{linkUrlSource}</h3>
        {isGeneratingContent && (
          <p>
            Generating website info. This might take a moment, so please check
            back later.
          </p>
        )}
        {linkUrl && (
          <Button
            iconAfter={<Open />}
            onClick={() => window.open(linkUrl)}
            size="md"
            variant="outlined"
          >
            Open link
          </Button>
        )}
      </div>
    </PositionContainer>
  );
}
