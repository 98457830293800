import { type ExternalToast, toast } from 'sonner';

import Toast, { ToastIconVariants } from '@ui/Toast';
import { MouseEventHandler } from 'react';
import WarningToast from '@/components/ui/WarningToast';

export type CreateToastProps = {
  bodyText: string;
  buttonText?: string;
  iconVariant: ToastIconVariants;
  onClick?: () => void;
  settings?: ExternalToast;
  titleText?: string;
};

const createToast = ({
  bodyText,
  buttonText,
  iconVariant,
  onClick,
  settings,
  titleText,
}: CreateToastProps) => {
  return toast.custom(
    (t) => (
      <Toast
        body={bodyText}
        buttonText={buttonText}
        iconVariant={iconVariant}
        onClick={onClick}
        onClose={() => toast.dismiss(t)}
        title={titleText}
      />
    ),
    settings
  );
};

export const warningToast = ({
  message,
  title,
  onButtonClick,
  buttonText,
}: {
  message?: string;
  title?: string;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  buttonText?: string;
}) => {
  return toast.custom(() => (
    <WarningToast
      message={message}
      title={title}
      onButtonClick={onButtonClick}
      buttonText={buttonText}
    />
  ));
};
export default createToast;
