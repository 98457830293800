import type { ProjectStatus } from '@spaceduck/api';
import type { BillingPlan } from '@spaceduck/api';

export const DUCK_FUN_FACTS = [
  'Ducks spend most of their time on water',
  'Ducks are omnivores',
  'Ducks have three eyelids',
  'Ducks have waterproof feathers',
  'Once a duckling finds food it likes, it will never eat anything else',
  'Ducks have no nerves or blood vessels in their feet',
  'Ducks have been domesticated as pets and farm animals for more than 500 years',
  'Ducks have a complex language that allows them to communicate exact descriptions of animals and people',
  'Ducks have a wide flat beak adapted for dredging',
  'Ducks have a long, broad bill which contains serrated plates called lamellae',
  'Ducks have a large flat beak adapted for dredging',
  'Never once has a duck been caught red-handed',
  'Some ducks have been known to lay eggs in other birds nests',
  "Everytime a duck quacks, it is actually saying the word 'quack'",
  'Why ducks require life jackets is a mystery',
  'Communication with a duck is a two-way street',
  'Sometimes a duck will quack just to hear its own voice',
  'Quack quack quack: the sound of a duck',
  'Once a duck has made an enemy, duck or human-kind, it will never forget',
  'A quack of a duck, does not echo',
  'To be chosen by a duck is a great honor',
  'Ducks are not known for their sense of humor',
];

export const knownErrors = {
  accessForbidden: 'Access Forbidden',
  mediaGroupError: 'Media Group Error',
  projectError: 'Project Error',
  workspaceError: 'Workspace Error',
} as const;

export const projectStatusOptions: Record<ProjectStatus, string> = {
  canceled: 'Canceled',
  completed: 'Completed',
  inProgress: 'In progress',
  none: 'No status',
  paused: 'Paused',
  planned: 'Planned',
};

export const activeProjectKeys: ProjectStatus[] = [
  'none',
  'inProgress',
  'paused',
  'planned',
] as const;

export const archivedProjectKeys: ProjectStatus[] = [
  'completed',
  'canceled',
] as const;

export const BROWSER_EXT_ID = 'bmiaedpgbkkhjgcjmkbhmjibipalamep';
export const BROWSER_EXT_URL = `https://chromewebstore.google.com/detail/spaceduck/${BROWSER_EXT_ID}`;
export const BROWSER_EXT_DIV_ID = `ext-${BROWSER_EXT_ID}-content`;

export type PlanLabel = 'Free' | 'Pro' | 'Ultra' | 'Enterprise';

export const PLAN_LABELS: Record<BillingPlan, PlanLabel> = {
  starter: 'Free',
  pro: 'Pro',
  team: 'Ultra',
  enterprise: 'Enterprise',
} as const;
