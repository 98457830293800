const getRate = (key: string, fallback: number): number => {
  const rawValue = import.meta.env[`${key}`];

  if (!rawValue) {
    return fallback;
  }

  const value = Number.parseFloat(rawValue);

  if (Number.isNaN(value)) {
    return fallback;
  }

  return value;
};

export const getTracesSampleRate = () =>
  getRate('SENTRY_TRACES_SAMPLE_RATE', 0.01);

export const getReplaySessionSampleRate = () =>
  getRate('SENTRY_REPLAY_SESSION_SAMPLE_RATE', 0.01);

export const getReplaysOnErrorSampleRate = () =>
  getRate('SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE', 1.0);
