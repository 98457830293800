import { ChangeEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHotkeys } from 'react-hotkeys-hook';
import { BooleanFieldInstance, isBooleanFieldInstance } from '@spaceduck/api';

import Checkbox from '@ui/Checkbox';
import type { AvailableTypes, StandardCellValue } from '@/types/Category';
import styles from './Checkbox.module.scss';
import { useCategoryCellSelectionKeyboard } from '@/hooks/useCategoryCellSelection';

function getInitialValue(value: AvailableTypes) {
  const instance = value.find(() => true);
  if (!instance) {
    return { value: false };
  }

  if (!isBooleanFieldInstance(instance)) {
    return { value: false };
  }

  return instance;
}

export default function CheckboxValue({
  canEdit,
  handleUpdate,
  info,
  setSelectedCell,
  showPreview,
  value,
}: StandardCellValue) {
  const initialValue = getInitialValue(value);
  const [localValue, setLocalValue] =
    useState<BooleanFieldInstance>(initialValue);

  const persist = async (updatedValue: BooleanFieldInstance) => {
    // Optimistic update
    info.table.options.meta?.updateData?.(info.row.index, info.column.id, [
      updatedValue,
    ]);

    await handleUpdate?.({
      propertyId: info.column.id,
      rowIndex: info.row.index,
      value: [updatedValue],
    });
  };

  const handleOnChange = (ev: ChangeEvent) => {
    const checked = !!(ev.currentTarget as HTMLInputElement).checked;
    const updatedValue = { ...localValue, value: checked };
    setLocalValue(updatedValue);
    persist(updatedValue);
  };

  const { setEnabled } = useCategoryCellSelectionKeyboard({
    setSelectedCell,
    enableOnLoad: false,
  });

  useEffect(() => {
    setEnabled(showPreview);
  }, [showPreview]);

  useHotkeys(
    'Space, Enter',
    () => {
      const value = { ...localValue, value: !localValue.value };
      setLocalValue(value);
      persist(value);
    },
    {
      enabled: canEdit && showPreview,
    }
  );

  if (!canEdit) {
    return (
      <>
        {showPreview && <div className={styles.focusedBackground}></div>}
        <Checkbox
          className={clsx(styles.checkbox, showPreview && styles.preview)}
          checked={localValue ? localValue.value : false}
          disabled={true}
        />
      </>
    );
  }

  return (
    <>
      {showPreview && <div className={styles.focusedBackground}></div>}
      <Checkbox
        className={styles.checkbox}
        onChange={canEdit ? handleOnChange : undefined}
        defaultChecked={localValue ? localValue.value : false}
      />
    </>
  );
}
