import clsx from 'clsx';

import styles from './Shortcut.module.scss';

type ShortcutProps = {
  className?: string;
  isFixedWidth?: boolean;
  shadow?: boolean;
  children?: React.ReactNode;
};

export default function Shortcut({
  className,
  isFixedWidth,
  shadow,
  children,
}: ShortcutProps) {
  return children ? (
    <div
      className={clsx(
        styles.shortcut,
        isFixedWidth && styles.fixedWidth,
        shadow && styles.shadow,
        className
      )}
    >
      {children}
    </div>
  ) : null;
}

export function MetaKey(props: Omit<ShortcutProps, 'children'>) {
  return (
    <Shortcut isFixedWidth {...props}>
      {navigator.platform.indexOf('Mac') === 0 ? '⌘' : '⌥'}
    </Shortcut>
  );
}
