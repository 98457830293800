import { z } from "zod";
import { billingPlanSchema } from "../resources/schemas";

export const UserInfoSchema = z.object({
  id: z.string(),
  preferredName: z.string(),
  avatarUrl: z.string().nullable(),
  isNew: z.boolean(),
  flags: z
    .string()
    .array()
    .transform((flags) => new Set(flags)),
  email: z.string()
});
export type UserInfo = z.infer<typeof UserInfoSchema>;

export const BearerAccessSchema = z.object({
  kind: z.literal("bearer"),
  header: z.string(),
  token: z.string()
});
export type BearerAccess = z.infer<typeof BearerAccessSchema>;

export const AccessSchema = z.union([BearerAccessSchema, z.never()]);
export type Access = z.infer<typeof AccessSchema>;

export const WorkspaceSchema = z.object({
  id: z.string(),
  label: z.string(),
  plan: billingPlanSchema
});

export const AuthenticatedSessionSchema = z.object({
  kind: z.literal("authenticated"),
  keyHash: z.string(),
  user: UserInfoSchema,
  access: AccessSchema.array(),
  workspaces: WorkspaceSchema.array()
});
export type AuthenticatedSession = z.infer<typeof AuthenticatedSessionSchema>;

export const OAuth2AuthenticationMethodSchema = z.object({
  kind: z.literal("oauth2"),
  namespace: z.string(),
  href: z.string()
});
export type Oauth2AuthenticationMethod = z.infer<typeof OAuth2AuthenticationMethodSchema>;

export const PasswordlessAuthenticationMethodSchema = z.object({
  kind: z.literal("passwordless")
});
export type PasswordlessAuthenticationMethod = z.infer<
  typeof PasswordlessAuthenticationMethodSchema
>;

export const AuthenticationMethodSchema = z.union([
  OAuth2AuthenticationMethodSchema,
  PasswordlessAuthenticationMethodSchema
]);
export type AuthenticationMethod = z.infer<typeof AuthenticationMethodSchema>;

export const NotAuthenticatedSession = z.object({
  kind: z.literal("not-authenticated"),
  methods: AuthenticationMethodSchema.array()
});

export const SessionSchema = z.union([AuthenticatedSessionSchema, NotAuthenticatedSession]);
export type Session = z.infer<typeof SessionSchema>;
