import { Icon24, IconProps } from '@spaceduck/icons';

import { MediaGroupStatus } from '@spaceduck/api';

const { Empty } = Icon24;
type MediaGroupStatusIconProps = {
  status: MediaGroupStatus | null;
} & IconProps;

export default function MediaGroupStatusIcon(props: MediaGroupStatusIconProps) {
  const { status, ...iconProps } = props;

  if (!status?.id) {
    return <Empty color="#7A7E9F" {...iconProps} />;
  }

  return (
    <Empty {...props} fill={`#${status.color}`} color={`#${status.color}`} />
  );
}
