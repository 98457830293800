import { MediaGroupDTO, MediaGroupDetailDTO, ProjectDTO } from '@spaceduck/api';

import { isCapable } from '@api/util';
import { useWorkspaceProjects } from '@api/workspace';
import useWorkspaceId from '@hooks/useWorkspaceId';

export const useProjectPermissions = (project: ProjectDTO) => {
  const findCapability = (action: string) => {
    if (!project) {
      console.warn('No space provided');
      return false;
    }

    const { capabilities } = project;
    return (
      capabilities?.find(({ capability }) => capability === action) ?? false
    );
  };

  const canDelete = findCapability('delete');
  const canEdit = findCapability('edit');

  const workspaceId = useWorkspaceId();
  const { data: workspaceProjects } = useWorkspaceProjects(workspaceId);

  const findCreateCapability = () => {
    if (!workspaceProjects) {
      console.warn('Could not find workspace spaces');
      return false;
    }
    return isCapable('create', workspaceProjects.capabilities);
  };

  const canCreate = findCreateCapability();

  return {
    canCreate,
    canDelete,
    canEdit,
  };
};

export const useMediaGroupPermissions = (
  mediaGroup?: MediaGroupDTO | MediaGroupDetailDTO
) => {
  const findCapability = (action: string) => {
    if (!mediaGroup) {
      console.warn('No media group provided');
      return false;
    }

    return isCapable(action, mediaGroup.userCapabilities).capable;
  };

  const canComment = findCapability('comment');
  const canCreate = findCapability('create');
  const canEdit = findCapability('edit');

  return {
    canComment,
    canCreate,
    canEdit,
  };
};
