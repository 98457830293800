import { useState } from 'react';
import { clsx } from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import upperFirst from 'lodash/upperFirst';
import prettyBytes from 'pretty-bytes';
import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import { useManageMediaGroupLinkModal } from '@components/ManageMediaGroupLinkModal';
import { ContentType } from '@components/icons';
import TagInput from '@components/TagInput';
import { useModalManager } from '@context/ModalManagerContext';
import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useCreateTags } from '@hooks/useCreateTags';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { css } from '@lib/css';
import dayjs from '@lib/dayjs';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Button from '@ui/Button';
import { Favicon } from '@ui/Favicon';
import OptionalLink from '@ui/OptionalLink';
import Spinner from '@ui/Spinner';
import Tag from '@ui/Tag';
import Tooltip from '@ui/Tooltip';
import { AuthorAvatar } from '@ui/UserAvatar';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import { urlFor } from '@/urls';
import InfoCategories from './InfoCategories';
import SingleFieldEdit from '../SingleFieldEdit';
import styles from './Info.module.scss';

const {
  ColorPalette,
  BacklinkIncoming: BacklinkIncoming16,
  BacklinkOutgoing: BacklinkOutgoing16,
  Info,
  Hashtag,
} = Icon16;
const { Add } = Icon24;

const GeneratingSpinner = ({ label }: { label: string }) => (
  <div className={styles.generating}>
    <Spinner size={18} />
    <span>Generating {label}...</span>
  </div>
);

const LinkDetails = ({
  linkUrl,
  linkUrlSource,
  mediaGroupId,
  userCanEdit,
}: {
  linkUrl: string;
  linkUrlSource: string;
  mediaGroupId: string;
  userCanEdit: boolean;
}) => {
  const { open } = useManageMediaGroupLinkModal({ mediaGroupId });

  if (userCanEdit) {
    return (
      <div className={clsx(styles.text, styles.editable)}>
        <div
          className={styles.trigger}
          onClick={(ev) => {
            if (userCanEdit) {
              ev.preventDefault();
              ev.stopPropagation();
              open(linkUrl);
            }
          }}
        >
          <Favicon url={linkUrl} className={styles.favicon} size={24} />
          <div className={styles.company}>{linkUrlSource}</div>
        </div>
        <OptionalLink href={linkUrl} isExternal>
          {' '}
        </OptionalLink>
      </div>
    );
  }

  return (
    <div className={styles.text}>
      <OptionalLink href={linkUrl} isExternal>
        <Favicon url={linkUrl} className={styles.favicon} size={24} />
        <div className={styles.company}>
          {new URL(linkUrl).host.replace(/^www\./, '')}
        </div>
      </OptionalLink>
    </div>
  );
};

export default function DetailsModalSidebarInfo({
  mediaGroup,
  userCanEdit,
}: {
  mediaGroup: MediaGroupDetailDTO;
  userCanEdit: boolean;
}) {
  const workspaceId = useWorkspaceId();
  const {
    id,
    createdAt,
    author,
    description,
    label,
    linkUrl,
    linkUrlSource,
    tags,
    isGeneratingContent,
    colors: mgColors,
    links,
    backLinks,
  } = mediaGroup;
  const project = mediaGroup.project;
  const { open } = useManageMediaGroupLinkModal({ mediaGroupId: id });

  const { isInLightboxView, isInlineCommenting } = useDetailsModalStore(
    ({ isInLightboxView, isInlineCommenting }) => ({
      isInLightboxView,
      isInlineCommenting,
    })
  );

  const [showSummaryForm, setShowSummaryForm] = useState<boolean>(false);
  const [showAllTags, setShowAllTags] = useState(false);
  const [showAllLinks, setShowAllLinks] = useState(
    (backLinks?.length ?? 0) + (links?.length ?? 0) === 0
  );
  const backgroundLocation = useBackgroundLocation();
  const { modals } = useModalManager();

  const navigate = useNavigate();

  useHotkeys(
    'Escape',
    () => {
      if (showSummaryForm) {
        setShowSummaryForm(false);
      } else {
        navigate(backgroundLocation || '/');
      }
    },
    {
      enabled: !modals.length && !isInLightboxView && !isInlineCommenting,
    },
    [isInLightboxView, isInlineCommenting]
  );

  const {
    addTag,
    removeTag,
    highlightedTag,
    setShowTagCreationInput,
    showTagCreationInput,
  } = useCreateTags(mediaGroup);

  const originLabel = project ? project.label : 'Repository';

  return (
    <div className={styles.sidebarContent}>
      <div className={styles.meta}>
        <div
          className={clsx(
            'attribution',
            'truncated',
            'body6',
            styles.attribution
          )}
        >
          <AuthorAvatar author={author} size="xxs" />{' '}
          <span className={styles.highlighted}>{author.name}</span>
          <span>•</span>
          <span title={dayjs(createdAt).format('DD/MM/YYYY')}>
            {dayjs(createdAt).format('MMM D')}
          </span>
          <span>•</span>
          <span className={styles.highlighted}>{originLabel}</span>
        </div>
        <div className={styles.summary}>
          {isGeneratingContent && !label && !description ? (
            <GeneratingSpinner label="info" />
          ) : (
            <>
              <div>
                <SingleFieldEdit
                  borderHeight={1}
                  displayAs="h2"
                  displayStyle={clsx(!label && styles.dim, styles.label)}
                  displayWrapperStyle={styles.inputPlaceholders}
                  enterToSubmit
                  fallback="Title..."
                  fieldName="label"
                  fieldLabel="title"
                  mediaGroupId={mediaGroup.id}
                  required={true}
                  textareaStyle={styles.label}
                />
              </div>
              <div>
                <SingleFieldEdit
                  borderHeight={1}
                  displayAs="p"
                  displayStyle={clsx(
                    !description && styles.dim,
                    styles.description
                  )}
                  displayWrapperStyle={styles.inputPlaceholders}
                  fallback="Description..."
                  fieldName="description"
                  fieldLabel="description"
                  mediaGroupId={mediaGroup.id}
                  textareaStyle={styles.description}
                />
              </div>
            </>
          )}
        </div>
        <InfoCategories mediaGroup={mediaGroup} />
        <div className={styles.details}>
          {!!linkUrl && !!linkUrlSource && (
            <LinkDetails
              mediaGroupId={mediaGroup.id}
              linkUrl={linkUrl}
              linkUrlSource={linkUrlSource}
              userCanEdit={userCanEdit}
            />
          )}
          {!linkUrl && userCanEdit && (
            <Button
              className={styles.addAppLink}
              iconBefore={<Add />}
              onClick={() => open()}
              type="button"
              variant="link"
            >
              Add a link
            </Button>
          )}
        </div>
        <div className={styles.spec}>
          <div className={styles.kind}>
            <ContentType contentType={mediaGroup.contentType} />
            <div className="subtitle6">
              {upperFirst(mediaGroup.contentType)}
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(styles.links, showAllLinks && styles.expanded)}>
        <div className={styles.linkKind}>
          <h3 className={styles.heading}>
            <BacklinkIncoming16 />
            Backlinks
            <span>•</span>
            <span>{backLinks?.length}</span>
          </h3>
          {!!backLinks?.length && (
            <div className={styles.linkList}>
              {backLinks?.map((mediaGroup) => {
                return (
                  <a
                    key={mediaGroup.id}
                    href={urlFor('mediaGroup', {
                      mediaGroupId: mediaGroup.id,
                    })}
                    target="_blank"
                    className={styles.backLink}
                  >
                    <ContentType
                      contentType={mediaGroup.contentType}
                      size={20}
                    />
                    <span className={styles.label}>{mediaGroup.label}</span>
                  </a>
                );
              })}
            </div>
          )}
        </div>
        {mediaGroup.kind === 'document' && (
          <div className={styles.linkKind}>
            <h3 className={styles.heading}>
              <BacklinkOutgoing16 />
              Outgoing links
              <span>•</span>
              <span>{links?.length}</span>
            </h3>
            {!!links?.length && (
              <div className={styles.linkList}>
                {links?.map((mediaGroup) => {
                  return (
                    <a
                      key={mediaGroup.id}
                      className={styles.backLink}
                      href={urlFor('mediaGroup', {
                        mediaGroupId: mediaGroup.id,
                      })}
                      target="_blank"
                    >
                      <ContentType
                        contentType={mediaGroup.contentType}
                        size={20}
                      />
                      <span className={styles.label}>{mediaGroup.label}</span>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        )}
        <div
          className={styles.overflowOverlay}
          onClick={() => setShowAllLinks(true)}
        ></div>
      </div>
      <div className={styles.tagManagement}>
        <h3 className={clsx(styles.heading, !tags?.length && styles.noTags)}>
          <Hashtag />
          Tags
          {userCanEdit && (
            <Button
              iconBefore={<Add size={16} />}
              onClick={() => setShowTagCreationInput((state) => !state)}
              variant="ghost"
              size="xs"
            >
              Add tag
            </Button>
          )}
        </h3>
        {showTagCreationInput && userCanEdit && (
          <TagInput
            className={styles.tagInputGroup}
            onAddTag={addTag}
            onRemoveTag={(tag) => removeTag(tag)}
            showSelectedTags={false}
            showSuggestions
            tags={tags?.map((tag) => tag.label)}
          />
        )}
        {isGeneratingContent && !tags.length && (
          <GeneratingSpinner label="tags" />
        )}
        {tags?.length > 0 && (
          <div
            className={clsx(
              'tags',
              styles.tags,
              showAllTags && styles.expanded
            )}
          >
            {tags.map((tag, idx) => {
              const onRemoveClick = () => removeTag(tag.label);

              return (
                <Tag
                  key={idx}
                  className={clsx(
                    styles.tag,
                    tag.label.toLowerCase() === highlightedTag.toLowerCase() &&
                      'highlightedTag'
                  )}
                  onClick={() => {
                    if (workspaceId) {
                      navigate(urlFor('workspaceRepository', { workspaceId }), {
                        state: { tag },
                      });
                    }
                  }}
                  onRemoveClick={userCanEdit ? onRemoveClick : undefined}
                  isRounded
                  removeIconIsHidden
                  removeIconPosition="floating"
                  variant="tertiary"
                >
                  {tag.source === 'user' && <Hashtag />}
                  {tag.label}
                </Tag>
              );
            })}
            <div
              className={styles.overflowOverlay}
              onClick={() => setShowAllTags(true)}
            ></div>
          </div>
        )}
      </div>

      {!!mgColors.length && (
        <div className={styles.colors}>
          <h3 className={clsx('body4', styles.heading)}>
            <ColorPalette />
            Colors
          </h3>
          {isGeneratingContent && !mgColors.length && (
            <GeneratingSpinner label="colors" />
          )}
          <div
            className={styles.colorBar}
            style={css({ '--slices': mgColors.length })}
          >
            {mgColors.map(({ hex, label }, idx) => {
              const value = `#${hex.toUpperCase()}`;
              return (
                <Tooltip
                  content={
                    <span className="body6">{`${value} -> ${label}`}</span>
                  }
                  key={idx}
                >
                  <button
                    style={{ backgroundColor: value }}
                    onClick={() => {
                      copyTextToClipboard(value, {
                        titleText: `Color: ${label}`,
                        bodyText: `"${value}" copied to clipboard`,
                      });
                    }}
                  ></button>
                </Tooltip>
              );
            })}
          </div>
        </div>
      )}
      {!!mediaGroup.sizeBytes && (
        <div className={styles.generalInfo}>
          <h3 className={styles.heading}>
            <Info />
            Information
          </h3>
          <div className={styles.dataPoint}>
            <h5>Storage used</h5>
            <p>{prettyBytes(mediaGroup.sizeBytes)}</p>
          </div>
        </div>
      )}
    </div>
  );
}
