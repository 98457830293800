import { z } from "zod";
import { commentDTO } from "./schemas";
import { successfulResponseSchema } from "../schema";
import { ApiClient } from "../client";
import { apiUrl } from "../util";

const listCommentsSchema = successfulResponseSchema.extend({
  comments: commentDTO.array()
});

export type ListCommentsResponse = z.infer<typeof listCommentsSchema>;

export const listComments = async (mediaGroupId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/comment/`,
    method: "GET",
    responseSchema: listCommentsSchema
  });

type CommentPointer = {
  x: number;
  y: number;
  mediaId: string;
};

export type CreateCommentBody = {
  content: string;
  pointAt?: CommentPointer;
  parentId?: string;
  document?: object;
};

export const comment = async ({
  mediaGroupId,
  content,
  pointAt,
  parentId,
  document
}: CreateCommentBody & { mediaGroupId: string }) => {
  return ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/comment/`,
    method: "POST",
    body: { content, parentId, pointAt, document },
    responseSchema: successfulResponseSchema.extend({
      id: z.string(),
      invitable: z.string().array(),
      projectId: z.string().nullable()
    })
  });
};

export const editComment = async (commentId: string, content: string, document?: object) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/comment/${commentId}/`,
    method: "PATCH",
    body: { content, document },
    responseSchema: successfulResponseSchema.extend({
      id: z.string(),
      invitable: z.string().array(),
      projectId: z.string().nullable()
    })
  });

export const deleteComment = async (commentId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/comment/${commentId}/`,
    method: "DELETE",
    responseSchema: successfulResponseSchema
  });

export const react = async (commentId: string, emoji: string, setOn: boolean) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/comment/${commentId}/react/`,
    method: "POST",
    body: { emoji, setOn },
    responseSchema: successfulResponseSchema
  });

export const resolve = async (commentId: string, resolved: boolean) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/comment/${commentId}/resolve/`,
    method: "POST",
    body: { resolved },
    responseSchema: successfulResponseSchema
  });

export const commentKeys = {
  list: (mediaGroupId: string) => ["comment", mediaGroupId]
};
