import { ReactRenderer } from '@tiptap/react';
import tippy, { type Instance as TippyInstance } from 'tippy.js';

import CommandsList, { CommandListRef } from './CommandsList';

const renderSlashMenu = (tippySlashMenuRoot: string) => () => {
  let component: ReactRenderer<CommandListRef> | undefined = undefined;
  let popup: TippyInstance[] | undefined = undefined;

  return {
    onStart: (props: any) => {
      component = new ReactRenderer(CommandsList, {
        props,
        editor: props.editor,
      });

      popup = tippy(`#${tippySlashMenuRoot}`, {
        appendTo: 'parent',
        getReferenceClientRect: props.clientRect,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: 'manual',
        placement: 'bottom-start',
        theme: 'sdPopper',
      });
    },
    onUpdate(props: any) {
      component?.updateProps(props);

      popup?.[0]?.setProps({
        getReferenceClientRect: props.clientRect,
      });
    },
    onKeyDown(props: any) {
      if (props.event.key === 'Escape') {
        popup?.[0]?.hide();
      }

      return component?.ref?.onKeyDown(props);
    },
    onExit() {
      popup?.[0]?.destroy();
      component?.destroy();
    },
  };
};

export default renderSlashMenu;
