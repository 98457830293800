import clsx from 'clsx';
import { MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';

import { useDeleteMediaGroup } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import { ContentType } from '@components/icons';

import Button from '@ui/Button';
import DropdownMenu, { MenuItem } from '@ui/DropdownMenu';
import Tooltip from '@ui/Tooltip';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import createToast from '@utils/createToast';
import { urlFor } from '@/urls';
import DraggableCard from './DraggableCard';
import styles from './CardWrapper.module.scss';
import useDeleteMediaGroupConfirmModal from '@/hooks/useDeleteMediaGroupConformModal';

const { Close, Menu, Link, Open, Swap, TrashCan } = Icon24;

type CardWrapperProps = {
  children: React.ReactNode;
  className?: string;
  mediaGroup: MediaGroupDetailDTO;
  onClose?: () => void;
  onDelete?: () => void;
  onSwapClick?: () => void;
};

export default function CardWrapper({
  children,
  className,
  mediaGroup,
  onClose,
  onDelete,
  onSwapClick,
}: CardWrapperProps) {
  const userCanEdit = isCapable('edit', mediaGroup?.userCapabilities).capable;

  const { mutateAsync: deleteMutation } = useDeleteMediaGroup();
  const mediaGroupId = mediaGroup.id;

  const openDeleteMediaGroupConfirmModal = useDeleteMediaGroupConfirmModal({
    onConfirm: async (id: string) => {
      await deleteMutation(id);
    },
    onSuccess: () => {
      createToast({
        bodyText: 'Item deleted',
        iconVariant: 'success',
      });
      onDelete?.();
    },
  });
  return (
    <div className={styles.container}>
      <div className={clsx(styles.card, className)}>
        <div className={styles.header}>
          <DraggableCard mediaGroup={mediaGroup}>
            <div className={styles.title}>
              <ContentType contentType={mediaGroup.contentType} />
              {mediaGroup.label}
            </div>
          </DraggableCard>
          <div className={styles.menu}>
            {mediaGroup.kind === 'document' && !!onSwapClick && (
              <Tooltip content="Swap views" align="end">
                <Button onClick={onSwapClick} variant="icon">
                  <Swap size={20} />
                </Button>
              </Tooltip>
            )}
            {userCanEdit && (
              <Tooltip content="Show menu" align="end">
                <span>
                  <DropdownMenu
                    align="end"
                    className={styles.dropdownMenu}
                    isPadded
                    width={260}
                    triggerContent={
                      <Button variant="icon">
                        <Menu size={20} />
                      </Button>
                    }
                  >
                    <MenuItem
                      iconBefore={<Open size={20} />}
                      onSelect={() => {
                        window.open(
                          urlFor('mediaGroup', {
                            mediaGroupId,
                          })
                        );
                      }}
                    >
                      Open in new tab
                    </MenuItem>
                    <MenuItem
                      iconBefore={<Link size={20} />}
                      onSelect={() => {
                        copyTextToClipboard(
                          new URL(
                            urlFor('mediaGroup', {
                              mediaGroupId,
                            }),
                            window.location.origin
                          ).toString(),
                          {
                            titleText: undefined,
                            bodyText: 'Content Block link copied',
                          }
                        );
                      }}
                    >
                      Copy link
                    </MenuItem>
                    <MenuItem
                      iconBefore={<TrashCan size={20} />}
                      onSelect={() =>
                        openDeleteMediaGroupConfirmModal(mediaGroupId)
                      }
                    >
                      Delete
                    </MenuItem>
                  </DropdownMenu>
                </span>
              </Tooltip>
            )}
            <Tooltip content="Show Content Block list" align="end">
              <Button onClick={onClose} variant="icon">
                <Close size={20} />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
