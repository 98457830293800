import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Icon24 } from '@spaceduck/icons';

import Button from '@ui/Button';
import Switch from '@ui/Switch';
import styles from './EditLinkForm.module.scss';

type EditLinkFormProps = {
  hideForm: () => void;
  initialOpenInNewTab?: boolean;
  initialTextValue?: string;
  initialValue?: string;
  onCreateLink: (url: string, openInNewTab: boolean, text?: string) => void;
  onDelete: () => void;
  showTextField?: boolean;
};

const { Check, TrashCan } = Icon24;

export default function EditLinkForm({
  hideForm,
  initialOpenInNewTab = true,
  initialTextValue = '',
  initialValue = '',
  onCreateLink,
  onDelete,
  showTextField,
}: EditLinkFormProps) {
  const [url, setUrl] = useState(initialValue);
  const [text, setText] = useState(initialTextValue);
  const [openInNewTab, setOpenInNewTab] = useState(initialOpenInNewTab);
  const [error, setError] = useState<null | string>(null);

  const validate = () => {
    if (!url) {
      setError('URL is required');
      return;
    }

    try {
      new URL(url);
      onCreateLink(url, openInNewTab, text);
    } catch {
      setError('URL is invalid');
    }
  };

  const reset = () => {
    setUrl('');
    setOpenInNewTab(true);
  };

  const deleteLink = () => {
    reset();
    onDelete();
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Escape') {
      ev.preventDefault();
      ev.stopPropagation();
      hideForm();
    } else if (ev.key === 'Enter') {
      validate();
    }
  };

  const ref = useHotkeys<HTMLDivElement>('Escape', (ev) => {
    ev.stopPropagation();
    hideForm();
  });

  return (
    <div className={styles.editLinkForm} ref={ref} tabIndex={1}>
      {showTextField && (
        <div className={styles.optionalText}>
          <input
            autoComplete="off"
            autoFocus
            className={styles.input}
            onChange={(ev) => setText(ev.currentTarget.value)}
            onKeyDown={handleKeyDown}
            placeholder="Text"
            value={text}
            type="text"
          />
        </div>
      )}
      <div className={styles.field}>
        <input
          autoComplete="off"
          autoFocus={!showTextField}
          className={styles.input}
          onKeyDown={handleKeyDown}
          onChange={(ev) => setUrl(ev.currentTarget.value)}
          placeholder="https://..."
          type="text"
          value={url}
        />
        <Button
          className={styles.btnSubmit}
          onClick={validate}
          variant="icon"
          size="md"
          type="button"
        >
          <Check />
        </Button>
        <Button
          className={styles.btnCancel}
          onClick={deleteLink}
          variant="light"
          size="md"
          type="button"
        >
          <TrashCan />
        </Button>
      </div>
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.targetOption}>
        <label>
          Open in a new tab
          <Switch
            defaultChecked={openInNewTab}
            onCheckedChange={(checked) => setOpenInNewTab(checked)}
          />
        </label>
      </div>
    </div>
  );
}
