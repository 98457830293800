import { Link } from 'react-router-dom';
import { clsx } from 'clsx';
import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import type { ProjectDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import {
  useJoinProject,
  usePatchProject,
  useRemoveUserFromProject,
} from '@api/project';
import { useEditProjectModal } from '@components/admin/CreateProjectsModal';
import { ProjectMode as ProjectModeIcon } from '@components/icons';
import StatusBadge from '@components/StatusBadge';
import { useUserInfo } from '@hooks/useAuth';
import Button from '@ui/Button';
import DropdownMenu from '@ui/DropdownMenu';
import Tooltip from '@ui/Tooltip';
import UserAvatar from '@ui/UserAvatar';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import styles from './ProjectListItem.module.scss';
import { urlFor } from '@/urls';
import createToast from '@/utils/createToast';
import { trackEvent } from '@/lib/analytics/google';

const { MenuMore } = Icon16;
const { Favorite, FollowSubscribe, Link: LinkIcon, Settings } = Icon24;

type ProjectListItemProps = {
  project: ProjectDTO;
  redirectOnDelete?: boolean;
  setCurrentProject?: (project: ProjectDTO) => void;
};

const MAX_DISPLAYED_USERS = 8;

export default function ProjectListItem({
  project,
  redirectOnDelete,
}: ProjectListItemProps) {
  const {
    id,
    label,
    description,
    status,
    membersPreview,
    totalPosts,
    capabilities,
  } = project;
  const hiddenUsersCount = membersPreview.length - MAX_DISPLAYED_USERS;
  const editCapability = capabilities?.find(
    ({ capability }) => capability === 'edit'
  );
  const canEdit = editCapability?.capable ?? false;

  const { open: openEditProjectModal } = useEditProjectModal(
    project,
    redirectOnDelete
  );
  const { mutateAsync: joinProject } = useJoinProject();
  const { mutateAsync: removeUserFromProject } = useRemoveUserFromProject();
  const { mutateAsync: patchProject } = usePatchProject();
  const user = useUserInfo();

  const setFollowing = async (newIsFollowing: boolean) => {
    if (!user) {
      return;
    }

    if (newIsFollowing) {
      await joinProject({ projectId: project.id });
      createToast({
        titleText: 'Following space',
        bodyText: `Joined "${project.label}"`,
        iconVariant: 'success',
      });
    } else {
      await removeUserFromProject({ projectId: project.id, userId: user.id });
      createToast({
        titleText: 'Stopped following space',
        bodyText: `left "${project.label}"`,
        iconVariant: 'success',
      });
    }
  };

  const setIsStarred = async (isStarred: boolean) => {
    await patchProject({ id: project.id, patch: { isStarred } });
    createToast({
      titleText: 'Favorites Updated',
      bodyText: isStarred
        ? `Favorited "${project.label}"`
        : `Unfavorited "${project.label}"`,
      iconVariant: 'success',
    });
  };

  const url = urlFor('space', { projectId: id });

  const trackSelect = () => {
    trackEvent('select_content', {
      content_type: 'project',
      content_id: project.id,
    });
  };

  return (
    <div className={styles.projectListItem}>
      <div className={styles.top}>
        <div className={styles.header}>
          <h4 className="title4">
            <Link to={url} onClick={trackSelect}>
              <ProjectModeIcon mode={project.mode} set={24} size={20} />
              {label}
            </Link>
          </h4>
          <DropdownMenu
            align="end"
            className={styles.projectDropdownMenu}
            triggerContent={
              <Button variant="icon" size="xs" className={styles.menu}>
                <MenuMore />
              </Button>
            }
          >
            <div className={clsx('menu', styles.projectMenu)}>
              <DropdownMenuItem
                asChild
                onSelect={() => setFollowing?.(!project.isFollowing)}
              >
                <Button
                  variant="menu"
                  iconBefore={
                    <FollowSubscribe active={project.isFollowing} size={20} />
                  }
                >
                  {project.isFollowing ? 'Following' : 'Follow'}
                </Button>
              </DropdownMenuItem>
              <DropdownMenuItem
                asChild
                onSelect={() => setIsStarred?.(!project.isStarred)}
              >
                <Button
                  variant="menu"
                  iconBefore={<Favorite active={project.isStarred} size={20} />}
                >
                  {project.isStarred ? 'Favorited' : 'Favorite'}
                </Button>
              </DropdownMenuItem>
              <DropdownMenuItem
                asChild
                onSelect={() =>
                  copyTextToClipboard(url.toString(), {
                    titleText: undefined,
                    bodyText: 'Space link copied to your clipboard!',
                  })
                }
              >
                <Button variant="menu" iconBefore={<LinkIcon size={20} />}>
                  Copy Link
                </Button>
              </DropdownMenuItem>
              {canEdit && (
                <DropdownMenuItem
                  asChild
                  onSelect={() => {
                    openEditProjectModal?.();
                  }}
                >
                  <Button variant="menu" iconBefore={<Settings size={20} />}>
                    Settings
                  </Button>
                </DropdownMenuItem>
              )}
            </div>
          </DropdownMenu>
        </div>
        {description && <div className="body5">{description}</div>}
      </div>
      <div className={styles.bottom}>
        <div className={styles.userAndStatus}>
          <div className={styles.followers}>
            {membersPreview?.length > 0 && (
              <ul>
                {membersPreview
                  .slice(
                    0,
                    Math.min(membersPreview.length, MAX_DISPLAYED_USERS)
                  )
                  .map((member, idx) => (
                    <li key={idx}>
                      <Tooltip content={member.name}>
                        <span>
                          <UserAvatar
                            imageUrl={member.avatarUrl}
                            name={member.name}
                            size="xs"
                          />
                        </span>
                      </Tooltip>
                    </li>
                  ))}
                {hiddenUsersCount > 0 && (
                  <li
                    className={styles.hiddenUsersCount}
                  >{`+${hiddenUsersCount}`}</li>
                )}
              </ul>
            )}
          </div>
          {status && (
            <StatusBadge
              background={2}
              color={status === 'none' ? 3 : 2}
              status={status}
            />
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.posts}>{totalPosts} items</div>
      </div>
    </div>
  );
}
