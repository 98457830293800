import { useEffect, useMemo, useState } from 'react';

import { useWorkspaceMembers } from '@api/workspace';
import { useDebouncedValue } from '@hooks/useDebouncedValue';
import useWorkspaceId from '@hooks/useWorkspaceId';
import UserAvatar from '@ui/UserAvatar';
import DropdownCheckboxList, {
  DropdownCheckboxListParams,
} from '@ui/DropdownCheckboxList';

export default function PeopleDropdownList(
  params: Omit<
    DropdownCheckboxListParams,
    'options' | 'setWantMore' | 'setQuery'
  >
) {
  const workspaceId = useWorkspaceId();
  const [wantMore, setWantMore] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebouncedValue(search);

  const {
    data: members,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useWorkspaceMembers(workspaceId, debouncedSearch);

  useEffect(() => {
    if (wantMore && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [wantMore, isFetchingNextPage, hasNextPage, fetchNextPage]);

  const people = useMemo(() => {
    return (
      members?.pages
        .flatMap((page) => page.members)
        .map((member) => ({
          icon: (
            <UserAvatar
              name={member.name.substring(0, 1)}
              imageUrl={member.avatarUrl}
              size="xs"
              isMuted={true}
            />
          ),
          label: member.name,
          value: member.id,
          role: member.role,
        })) ?? []
    );
  }, [members]);

  return (
    <DropdownCheckboxList
      {...params}
      options={people}
      setWantMore={setWantMore}
      search={search}
      setSearch={setSearch}
    />
  );
}
