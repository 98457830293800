import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { SubmitHandler } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  createLoginRequest,
  verifyLoginCode,
} from '@api/passwordlessAuthentication';
import { useRefreshSession } from '@hooks/useAuth';
import type { CodeFormData } from '@components/login/CodeForm';
import type { EmailFormData } from '@components/login/EmailForm';
import { trackEvent } from '@/lib/analytics/google';

export const useLoginWithCode = () => {
  const [targetEmail, setTargetEmail] = useState<string | null>(null);
  const refresh = useRefreshSession();
  const location = useLocation();
  const nextUrl = new URLSearchParams(location.search).get('next');

  const requestIdMutation = useMutation({
    mutationFn: async (variables: { email: string }) => {
      setTargetEmail(variables.email);
      const newRequestId = createLoginRequest({
        email: variables.email,
        next: nextUrl,
      });
      return newRequestId;
    },
    mutationKey: ['passwordless-create-request'],
  });

  const verifyMutation = useMutation({
    mutationFn: async (vars: { requestId: string; code: string }) => {
      const verificationResult = await verifyLoginCode(vars);
      if (verificationResult) {
        trackEvent('login', { method: 'magicCode' });
        await refresh();
      }
      return verificationResult;
    },
    mutationKey: ['passwordless-verify-request'],
  });

  const submitEmailHandler: SubmitHandler<EmailFormData> = async (data) => {
    setTargetEmail(data.email);
    await requestIdMutation.mutate({ email: data.email });
  };

  const submitCodeHandler: SubmitHandler<CodeFormData> = async (data) => {
    const requestId = requestIdMutation.data;
    if (!requestId) {
      throw new Error('Missing login request ID during code validation');
    }
    await verifyMutation.mutate({ requestId, code: data.code });
  };

  const requestResendHandler = useCallback(async () => {
    if (targetEmail === null) {
      throw new Error('missing email during re-send request');
    }

    await requestIdMutation.mutate({ email: targetEmail });
  }, [requestIdMutation.mutate, targetEmail]);

  return {
    nextUrl,
    requestIdMutation,
    requestResendHandler,
    setTargetEmail,
    submitCodeHandler,
    submitEmailHandler,
    targetEmail,
    verifyMutation,
  };
};
