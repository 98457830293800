import { type ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const ENABLE_REPORTING = import.meta.env.VITE_ENABLE_USAGE_REPORTS;
const MS_CLARITY_ID = import.meta.env.VITE_MS_CLARITY_ID;
const GA_MEASUREMENT_ID = import.meta.env.VITE_GA_MEASUREMENT_ID;

export const AnalyticsProvider = ({
  children,
}: {
  readonly children: ReactNode;
}) => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {ENABLE_REPORTING === 'yes' && (
          <>
            {GA_MEASUREMENT_ID && (
              <script
                id="google-tag-manager-src"
                src={`https://www.googletagmanager.com/gtag/js?id=${encodeURIComponent(
                  GA_MEASUREMENT_ID
                )}`}
              />
            )}
            {GA_MEASUREMENT_ID && (
              <script id="google-analytics">
                {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            var ga_default_consent = {
              'ad_storage': 'denied',
              'ad_user_data': 'denied',
              'ad_personalization': 'denied',
              'analytics_storage': 'denied',
              'personalization_storage': 'denied',
              'functionality_storage': 'denied',
              'security_storage': 'denied',
            };

            if(!window.localStorage || localStorage.getItem('consentMode') === null) {
              gtag('consent', 'default', ga_default_consent);
            } else {
              try {
                gtag('consent', 'default', {...ga_default_consent, ...JSON.parse(localStorage.getItem('consentMode'))});
              } catch (error) {
                console.error('Error setting GA consent', error);
                gtag('consent', 'default', ga_default_consent);
              }
            }
            gtag('js', new Date());
            gtag('config', ${JSON.stringify(GA_MEASUREMENT_ID)});
          `}
              </script>
            )}
            {MS_CLARITY_ID && (
              <script id="ms-clarity" type="text/javascript">
                {`
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", ${JSON.stringify(
              MS_CLARITY_ID
            )});
          `}
              </script>
            )}
          </>
        )}
      </Helmet>
      {children}
    </HelmetProvider>
  );
};
