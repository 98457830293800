import { Icon16 } from '@spaceduck/icons';

import { useMediaGroupDetail } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import { useModalManager } from '@context/ModalManagerContext';
import Dialog from '@ui/Dialog';
import ScrollArea from '@ui/ScrollArea';
import { css } from '@/lib/css';
import Info from './Info';
import styles from './SidebarModal.module.scss';

const { Info: InfoIcon } = Icon16;

export default function SidebarModal({
  closeModal,
  isOpen = true,
  mediaGroupId,
}: {
  closeModal?: () => void;
  isOpen?: boolean;
  mediaGroupId: string;
}) {
  const { data } = useMediaGroupDetail(mediaGroupId);
  const userCanEdit = data?.mediaGroup
    ? isCapable('edit', data.mediaGroup.userCapabilities).capable
    : false;

  if (!data?.mediaGroup) return null;

  return (
    <Dialog
      className={styles.modal}
      closeModal={closeModal}
      isOpen={isOpen}
      overlayClassname={styles.overlay}
      maxWidth="26.25rem"
      modalHeading={
        <div className={styles.modalHeader}>
          <InfoIcon />
          Info
        </div>
      }
      padding="lg"
    >
      <ScrollArea
        style={css({
          '--width': '100%',
          '--maxHeight': '720px',
          position: 'relative',
        })}
      >
        <div className={styles.wrapper}>
          <Info mediaGroup={data.mediaGroup} userCanEdit={userCanEdit} />
        </div>
        <div className={styles.gradientOverlay}></div>
      </ScrollArea>
    </Dialog>
  );
}

export const useSidebarModal = () => {
  const { openModal, closeModal } = useModalManager();

  return {
    open: ({ mediaGroupId }: { mediaGroupId: string }) => {
      openModal({
        component: <SidebarModal mediaGroupId={mediaGroupId} />,
      });
    },
    close: closeModal,
  };
};
