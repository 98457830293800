import { useContext, useEffect, useState } from 'react';
import { Icon16, Icon32 } from '@spaceduck/icons';

import { useCreateDocumentMediaGroup } from '@api/mediaGroup';
import Badge from '@ui/Badge';
import Button from '@ui/Button';
import { BROWSER_EXT_DIV_ID, BROWSER_EXT_URL } from '@/const';
import Browse01 from '@assets/img/onboarding/browse-01.png';
import Browse02 from '@assets/img/onboarding/browse-02.png';
import Browse03 from '@assets/img/onboarding/browse-03.png';
import { FileInputContext } from '@components/FileUploadWrapper';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Lightbox from './empty/Lightbox';
import Card, { CardGrid } from './empty/Card';
import styles from './EmptyCollection.module.scss';

const { Add, Download, Upload } = Icon16;
const { Play } = Icon32;

const VIDEO_URL =
  'https://www.youtube.com/embed/ajiOCDHa0EU?si=UN7ZldrQcxhm9OSe';

export default function EmptyCollection() {
  const workspaceId = useWorkspaceId();
  const createDocument = useCreateDocumentMediaGroup();
  const [showBrowserExt, setShowBrowserExt] = useState(
    !document.getElementById(BROWSER_EXT_DIV_ID)
  );
  const [showLightbox, setShowLightBox] = useState(false);
  const { inputRef } = useContext(FileInputContext);

  useEffect(() => {
    setShowBrowserExt(!document.getElementById(BROWSER_EXT_DIV_ID));
  }, []);

  return (
    <>
      <div className={styles.emptyCollectionContainer}>
        <div className={styles.text}>
          <h1>Welcome to your self-organizing digital home</h1>
          {VIDEO_URL ? (
            <p>
              Spaceduck has many use cases. Take a few minutes to watch the
              video below and learn how to make the most of it.
            </p>
          ) : (
            <p>
              Spaceduck has many use cases. Take a few minutes to explore one of
              the following options below.
            </p>
          )}
        </div>
        {!!VIDEO_URL && (
          <div className={styles.video}>
            <button
              className={styles.iconButton}
              onClick={() => setShowLightBox(true)}
            >
              <Play />
            </button>
            <button
              className={styles.textButton}
              onClick={() => setShowLightBox(true)}
            >
              Watch the introduction video
            </button>
          </div>
        )}
        <CardGrid>
          {showBrowserExt && (
            <Card
              button={
                <Button
                  iconBefore={<Download size={12} />}
                  onClick={() => window.open(BROWSER_EXT_URL)}
                  size="sm"
                  variant="outlined"
                >
                  Download
                </Button>
              }
            >
              <img alt="" src={Browse01} />
              <div className={styles.headingRow}>
                <h3>Browser Extension</h3>
                <Badge preset="trial" size="sm">
                  Must Have!
                </Badge>
              </div>
              <p>
                Install our Browser Extension and effortlessly capture anything
                from the web on the go.
              </p>
            </Card>
          )}
          <Card
            button={
              <Button
                iconBefore={<Upload size={12} />}
                onClick={() => inputRef?.current?.click()}
                size="sm"
                variant="outlined"
              >
                Upload Content
              </Button>
            }
          >
            <img alt="" src={Browse02} />
            <h3>Upload your first media or file</h3>
            <p>
              Just drag and drop or upload your files, and watch as they are
              automatically organized for you.
            </p>
          </Card>
          <Card
            button={
              !!workspaceId ? (
                <Button
                  iconBefore={<Add size={12} />}
                  onClick={() => createDocument({ workspaceId })}
                  size="sm"
                  variant="outlined"
                >
                  New Document
                </Button>
              ) : undefined
            }
          >
            <img alt="" src={Browse03} />
            <h3>Create your first Document</h3>
          </Card>
        </CardGrid>
      </div>
      {!!VIDEO_URL && (
        <Lightbox
          close={() => setShowLightBox(false)}
          embedUrl={VIDEO_URL}
          open={showLightbox}
        />
      )}
    </>
  );
}
