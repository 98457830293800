import { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import clsx from 'clsx';
import { CellContext } from '@tanstack/react-table';
import { MediaGroupWithCategoryFields } from '@spaceduck/api';

import {
  CellSelectionAction,
  useCategoryCellSelectionKeyboard,
} from '@hooks/useCategoryCellSelection';
import Checkbox from '@ui/Checkbox';
import { Row } from '@/types/Category';
import MediaGroup from './MediaGroup';
import styles from './FirstColumnTableCell.module.scss';

export default function FirstColumnTableCell({
  canEdit,
  clearSelectedCell,
  info,
  selectedRows,
  setSelectedCell,
  showPreview,
  toggleSelectedRow,
}: {
  canEdit: boolean;
  clearSelectedCell: () => void;
  info: CellContext<Row, MediaGroupWithCategoryFields | null | undefined>;
  selectedRows: string[];
  setSelectedCell: React.Dispatch<CellSelectionAction>;
  showPreview: boolean;
  toggleSelectedRow: (id: string) => void;
}) {
  const { setEnabled } = useCategoryCellSelectionKeyboard({
    setSelectedCell,
    enableOnLoad: false,
  });

  useEffect(() => {
    setEnabled(showPreview);
  }, [showPreview]);

  useHotkeys(
    'Escape',
    () => {
      () => clearSelectedCell();
    },
    {
      enabled: showPreview,
    }
  );

  useHotkeys(
    'Space, Enter',
    () => {
      if (!!info.getValue()?.id) {
        toggleSelectedRow(info.getValue()!.id);
      }
    },
    {
      enabled: showPreview,
    }
  );

  return (
    <>
      {showPreview && <div className={styles.focusedBackground}></div>}
      <div className={clsx(styles.sharedTh, showPreview && styles.focused)}>
        {canEdit && (
          <Checkbox
            className={styles.checkbox}
            defaultChecked={
              !!selectedRows.find((id) => id === info.getValue()?.id)
            }
            onChange={(ev) => {
              toggleSelectedRow(ev.target.value);
            }}
            value={info.getValue()?.id}
          />
        )}
        <MediaGroup focused={showPreview} mediaGroup={info.getValue()} />
      </div>
    </>
  );
}
