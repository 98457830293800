import clsx from 'clsx';
import type { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import ScrollArea from '@ui/ScrollArea';

import EditLinkForm from '../link/EditLinkForm';
import styles from './CommandsList.module.scss';
import { css } from '@/lib/css';

// Ref: Typing support https://github.com/ueberdosis/tiptap/discussions/2274
export type View = 'menuView' | 'linkView';

export type CommandSuggestion = {
  element: React.ReactNode;
  title: string;
  aliases?: string[];
  icon?: React.ReactNode;
  submenu?: CommandSuggestion[];
  view?: View;
};

export type CommandListRef = {
  onKeyDown: NonNullable<
    ReturnType<
      NonNullable<SuggestionOptions<CommandSuggestion>['render']>
    >['onKeyDown']
  >;
};

export type CommandsListProps = SuggestionProps<CommandSuggestion>;

const CommandList = forwardRef<CommandListRef, CommandsListProps>(
  (props, ref) => {
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(-1);
    const [selectedSubmenuIndex, setSelectedSubmenuIndex] = useState(-1);
    const [focus, setFocus] = useState<'menu' | 'submenu'>('menu');
    const [selectedItem, setSelectedItem] = useState<
      CommandSuggestion | undefined
    >(undefined);
    const [mouseSelectionIsDisabled, setMouseSelectionIsDisabled] =
      useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [view, setView] = useState<View>('menuView');

    const menu: CommandSuggestion[] = useMemo(() => {
      if (!props.query) return props.items;
      const results = props.items.reduce(
        (acc: CommandSuggestion[], curr: CommandSuggestion) => {
          if (
            curr.submenu?.find(
              (subItem) =>
                subItem.title
                  .toLowerCase()
                  .startsWith(props.query.toLowerCase()) ||
                subItem.aliases?.find((alias) =>
                  alias.toLowerCase().startsWith(props.query.toLowerCase())
                )
            )
          ) {
            return [
              ...acc,
              {
                ...curr,
                submenu: curr.submenu?.filter(
                  (subItem) =>
                    subItem.title
                      .toLowerCase()
                      .startsWith(props.query.toLowerCase()) ||
                    subItem.aliases?.find((alias) =>
                      alias.toLowerCase().startsWith(props.query.toLowerCase())
                    )
                ),
              },
            ];
          }
          return acc;
        },
        []
      );
      return results;
    }, [props.query, props.items]);

    useEffect(() => {
      setSelectedMenuIndex(0);
    }, [menu]);

    useEffect(() => {
      setSelectedSubmenuIndex(-1);
      if (menu && menu.length > selectedMenuIndex) {
        setSelectedItem(menu[selectedMenuIndex]);
      } else {
        setSelectedItem(undefined);
      }
    }, [selectedMenuIndex, menu]);

    const submenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (submenuRef.current) {
        const activeElement = submenuRef.current.querySelector('.active');
        activeElement?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
    }, [selectedSubmenuIndex]);

    const handleMouseMove = () => {
      setMouseSelectionIsDisabled(false);
    };

    useEffect(() => {
      window.addEventListener('mousemove', handleMouseMove);

      return () => window.removeEventListener('mousemove', handleMouseMove);
    }, []);

    const selectItem = (menuIdx: number) => {
      if (menuIdx >= menu.length) {
        return;
      }

      const item = menu[menuIdx];
      if (item) {
        props.command(item);
      }
    };

    const selectSubItem = (submenuIdx: number) => {
      if (selectedMenuIndex >= menu.length) {
        return;
      }

      const menuItem = menu[selectedMenuIndex];
      if (!menuItem?.submenu || submenuIdx > menuItem.submenu.length) {
        return;
      }

      const submenuItem = menuItem.submenu[submenuIdx];
      if (submenuItem) {
        props.command(submenuItem);
        if (submenuItem.view) {
          setView(submenuItem.view);
        }
      }
    };

    const upHandler = () => {
      setMouseSelectionIsDisabled(true);
      if (focus === 'menu') {
        setSelectedMenuIndex(
          (selectedMenuIndex + menu.length - 1) % menu.length
        );
      } else if (focus === 'submenu' && selectedItem?.submenu?.length) {
        setSelectedSubmenuIndex(
          (selectedSubmenuIndex + selectedItem.submenu.length - 1) %
            selectedItem.submenu.length
        );
      }
    };

    const downHandler = () => {
      setMouseSelectionIsDisabled(true);
      if (focus === 'menu') {
        setSelectedMenuIndex((selectedMenuIndex + 1) % menu.length);
      } else if (focus === 'submenu' && selectedItem?.submenu?.length) {
        setSelectedSubmenuIndex(
          (selectedSubmenuIndex + 1) % selectedItem.submenu.length
        );
      }
    };

    const leftHandler = () => {
      if (focus === 'submenu') {
        setFocus('menu');
        setSelectedSubmenuIndex(-1);
      }
    };

    const rightHandler = () => {
      if (focus === 'menu') {
        const submenu = menu[selectedMenuIndex]?.submenu;
        if (submenu && submenu.length > 0) {
          setFocus('submenu');
        }
        if (selectedSubmenuIndex < 0) {
          setSelectedSubmenuIndex(0);
        }
      }
    };

    const enterHandler = () => {
      if (focus === 'menu') {
        selectItem(selectedMenuIndex);
      } else if (focus === 'submenu' && selectedSubmenuIndex > -1) {
        selectSubItem(selectedSubmenuIndex);
      }
    };

    const handleMouseOver = (menu: string, idx: number) => {
      if (!mouseSelectionIsDisabled) {
        if (menu === 'menu') {
          setFocus('menu');
          setSelectedMenuIndex(idx);
        } else if (menu === 'submenu') {
          setFocus('submenu');
          setSelectedSubmenuIndex(idx);
        }
      }
    };

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (!!containerRef.current?.checkVisibility?.()) {
          if (event.key === 'ArrowUp') {
            upHandler();
            return true;
          }

          if (event.key === 'ArrowDown') {
            downHandler();
            return true;
          }

          if (event.key === 'ArrowLeft') {
            leftHandler();
            return true;
          }

          if (event.key === 'ArrowRight') {
            rightHandler();
            return true;
          }
        }

        if (event.key === 'Enter' && selectedSubmenuIndex > -1) {
          enterHandler();
          return true;
        }

        return false;
      },
    }));

    if (!menu) return null;

    return menu.length > 0 ? (
      <div ref={containerRef}>
        <div style={{ display: view === 'menuView' ? 'block' : 'none' }}>
          <div className={styles.menuContainer}>
            <div className={styles.menu}>
              {menu.map((item, menuIdx) => (
                <div
                  key={menuIdx}
                  className={clsx(
                    styles.menuItem,
                    menuIdx === selectedMenuIndex && styles.active,
                    focus === 'submenu' && styles.activeChild
                  )}
                  onClick={() => selectItem(menuIdx)}
                  onMouseOver={() => handleMouseOver('menu', menuIdx)}
                >
                  {item.element || item.title}
                </div>
              ))}
            </div>
            <div className={styles.separator}></div>
            <div className={styles.submenu} ref={submenuRef}>
              {selectedItem?.title ? (
                <div className={styles.title}>{selectedItem.title}</div>
              ) : null}
              <ScrollArea
                className={styles.scrollArea}
                style={css({
                  '--width': '100%',
                  '--maxHeight': '100%',
                })}
              >
                {selectedItem?.submenu?.map((submenu, submenuIdx) => (
                  <div
                    key={submenuIdx}
                    className={clsx(
                      styles.subMenuItem,
                      submenu.icon && styles.hasIcon,
                      submenuIdx === selectedSubmenuIndex &&
                        `${styles.active} active`
                    )}
                    onClick={() => selectSubItem(submenuIdx)}
                    onMouseOver={() => handleMouseOver('submenu', submenuIdx)}
                  >
                    {submenu.icon && (
                      <span className={styles.icon}>{submenu.icon}</span>
                    )}
                    <div className={styles.label}>{submenu.title}</div>
                  </div>
                ))}
              </ScrollArea>
            </div>
          </div>
        </div>
        <div style={{ display: view === 'linkView' ? 'block' : 'none' }}>
          <EditLinkForm
            hideForm={() => {
              props.editor.chain().focus().deleteRange(props.range).run();
              setView('menuView');
              containerRef.current?.focus();
            }}
            onCreateLink={(url, openInNewTab, text) => {
              props.editor
                .chain()
                .focus()
                .deleteRange(props.range)
                .insertContent(
                  `<a href="${url}" target="${openInNewTab ? '_blank' : '_self'}">${text || url}</a>`
                )
                // Prevent hover bug
                .selectParentNode()
                .run();
              setView('menuView');
            }}
            onDelete={() => {
              props.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .run();
              setView('menuView');
            }}
            showTextField
          />
        </div>
      </div>
    ) : null;
  }
);

CommandList.displayName = 'CommandList';

export default CommandList;
