import { Icon16, IconProps } from '@spaceduck/icons';

import type { ProjectFeature as ProjectFeatureType } from '@/types';

const { Library, Feed } = Icon16;

type ProjectFeatureProps = {
  feature: ProjectFeatureType;
} & IconProps;

export default function ProjectFeature(
  projectFeatureIconProps: ProjectFeatureProps
) {
  const { feature, ...iconProps } = projectFeatureIconProps;

  switch (feature) {
    case 'library':
      return <Library {...iconProps} />;
    case 'progress':
      return <Feed {...iconProps} />;
    default:
      return null;
  }
}
