import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { isLinkFieldInstance, LinkFieldInstance } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import { exists, Subset } from '@spaceduck/utils';

import { useCategoryCellSelectionKeyboard } from '@hooks/useCategoryCellSelection';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { css } from '@lib/css';
import Button from '@ui/Button';
import ScrollArea from '@ui/ScrollArea';
import type {
  AvailableTypes,
  SelectableTypes,
  StandardCellValue,
  StandardEditCellValue,
} from '@/types/Category';
import { prefixUrl } from '@/utils/url';
import styles from './Link.module.scss';

const { Add, Delete } = Icon16;

type DataType = Subset<SelectableTypes, 'email' | 'url'>;

function getInitialValue(value: AvailableTypes) {
  if (!value || !Array.isArray(value)) return [];

  return value.filter(isLinkFieldInstance);
}

export default function LinkValue({
  canEdit,
  clearSelectedCell,
  handleDelete,
  handleUpdate,
  info,
  setSelectedCell,
  showPreview,
  type,
  value,
}: StandardCellValue & {
  type: DataType;
}) {
  const initialValue = getInitialValue(value);
  const content = value.filter(isLinkFieldInstance);

  const [shouldShowEditView, setShouldShowEditView] = useState(false);
  const [localValue, setLocalValue] =
    useState<LinkFieldInstance[]>(initialValue);

  const { setEnabled } = useCategoryCellSelectionKeyboard({
    setSelectedCell,
    enableOnLoad: false,
    onEnter: () => setShouldShowEditView(true),
    onEscape: () => {
      shouldShowEditView ? setShouldShowEditView(false) : clearSelectedCell();
    },
  });

  useEffect(() => {
    setEnabled(showPreview);
  }, [showPreview]);

  return (
    <>
      {canEdit && shouldShowEditView && (
        <EditLinkValue
          clearSelectedCell={clearSelectedCell}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
          info={info}
          initialValue={initialValue}
          localValue={localValue}
          setLocalValue={setLocalValue}
          setShouldShowEditView={setShouldShowEditView}
          type={type}
        />
      )}
      {showPreview ? (
        <div
          className={clsx(styles.links, styles.preview)}
          onClick={canEdit ? () => setShouldShowEditView(true) : undefined}
        >
          <div className={styles.preview}>
            {content.map(({ url, text }, idx) => (
              <div className={styles.linkPreviewItem} key={idx}>
                <a
                  className={clsx(
                    type === 'email' ? styles.email : styles.link
                  )}
                  href={type === 'email' ? `mailto:${url}` : url}
                  onClick={(ev) => ev.stopPropagation()}
                  target="_blank"
                >
                  {text || url}
                </a>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className={clsx(styles.links, shouldShowEditView && styles.hidden)}
        >
          <div className={styles.sizeWrapper}>
            <div className={styles.overflowWrapper}>
              {content.map(({ url, text }, idx) => (
                <a
                  className={clsx(
                    type === 'email' ? styles.email : styles.link
                  )}
                  href={type === 'email' ? `mailto:${url}` : url}
                  key={idx}
                  onClick={(ev) => ev.stopPropagation()}
                  target="_blank"
                >
                  {text || url}
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const EditLinkValue = ({
  handleDelete,
  handleUpdate,
  info,
  initialValue,
  localValue,
  setLocalValue,
  setShouldShowEditView,
  type,
}: StandardEditCellValue<LinkFieldInstance[]> & {
  type: DataType;
}) => {
  const localValueRef = useRef(localValue);
  const setLocalValueRef = (value: LinkFieldInstance[]) => {
    localValueRef.current = value;
    setLocalValue(value);
  };

  const persist = () => {
    // Stop update if value is same as initial value
    if (isEqual(initialValue, localValueRef.current)) {
      return;
    }

    const value = localValueRef.current
      ? localValueRef.current
          .filter((entry) => !!entry.url.trim())
          .map((entry) => {
            if (type === 'email') {
              return { ...entry, url: entry.url, text: entry.text ?? '' };
            }

            const { url: value } = entry;
            const url = prefixUrl(value);
            if (!url) return null;
            return { ...entry, url: url.toString(), text: entry.text ?? '' };
          })
          .filter(exists)
      : [];

    // Optimistic update
    info.table.options.meta?.updateData?.(
      info.row.index,
      info.column.id,
      localValueRef.current.filter((entry) => !!entry.url)
    );

    if (!value) {
      handleDelete?.({
        propertyId: info.column.id,
        rowIndex: info.row.index,
      });
      return;
    }

    handleUpdate?.({
      propertyId: info.column.id,
      rowIndex: info.row.index,
      value,
    });
  };

  const handleTextChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    setLocalValueRef(
      [...localValueRef.current].map((link, index) =>
        idx === index ? { ...link, text: ev.target.value || undefined } : link
      )
    );
  };

  const handleUrlChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    setLocalValueRef(
      [...localValueRef.current].map((link, index) =>
        idx === index ? { ...link, url: ev.target.value } : link
      )
    );
  };

  const handleAddEntry = () => {
    setLocalValueRef([...localValueRef.current, { url: '' }]);
  };

  const removeEntry = (idx: number) => {
    setLocalValueRef([
      ...localValueRef.current.filter((_, index) => index !== idx),
    ]);
  };

  const handleTextKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter') {
      if (ev.currentTarget.nextSibling) {
        (ev.currentTarget.nextSibling as HTMLElement)
          .querySelector('input')
          ?.focus();
      }
    }
  };

  const handleEmailKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      ev.stopPropagation();
      persist();
      (ev.currentTarget as HTMLElement).blur();
    }
  };

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: () => {
      persist();
      setShouldShowEditView(false);
    },
  });

  return (
    <div className={styles.linksEdit} ref={containerRef}>
      <ScrollArea
        className={clsx(
          styles.linksScrollArea,
          localValueRef.current.length > 0 && styles.hasEntries
        )}
        style={css({
          '--width': '100%',
          '--maxHeight': '100%',
        })}
      >
        {localValue.map(({ text = '', url }, idx) => (
          <div className={styles.inputBlock} key={idx}>
            {type === 'url' && (
              <input
                className={styles.link}
                onChange={(ev) => handleTextChange(ev, idx)}
                onKeyDown={handleTextKeyDown}
                placeholder={type === 'url' ? 'Text' : 'Name'}
                type="text"
                value={text}
              />
            )}
            <div className={styles.inputWrapper}>
              <input
                className={styles.link}
                onChange={(ev) => handleUrlChange(ev, idx)}
                onKeyDown={handleEmailKeyDown}
                placeholder={type === 'url' ? 'URL' : 'Email address'}
                type={type === 'url' ? 'url' : 'email'}
                value={url}
              />
              <Button onClick={() => removeEntry(idx)} variant="icon">
                <Delete />
              </Button>
            </div>
          </div>
        ))}
      </ScrollArea>
      <Button
        className={styles.addButton}
        iconBefore={<Add />}
        onClick={handleAddEntry}
        variant="ghost"
      >
        {type === 'url' ? 'Add a domain' : 'Add an email'}
      </Button>
    </div>
  );
};
