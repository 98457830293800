import {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import type {
  Session,
  SessionStore,
  SessionUpdateHandler,
  UserInfo,
} from '@spaceduck/api';
import type { ReactNode } from 'react';
import { sessionStore } from '@/singleton';

const SessionStoreContext = createContext<SessionStore | null>(null);
SessionStoreContext.displayName = 'Session Store Context';

const SessionStoreProvider = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <SessionStoreContext.Provider value={sessionStore}>
    {children}
  </SessionStoreContext.Provider>
);

/**
 * Get a function that can be used to make requests against the API using the
 * current session. If you have no session, a session will be fetched for you.
 * If your session has expired, an attempt to refresh will be made and the
 * request will be re-submitted. If the user is not logged in, `null` will be
 * returned. In all other situations, the result request will be returned
 * (regardless of the status, etc)..
 *
 * This means, for each call to this function, between 1-3 requests may result.
 */
export const useAuthenticatedFetch = () => {
  const sessionStore = useContext(SessionStoreContext);
  if (sessionStore === null) {
    throw new Error(
      '`useAuthenticatedFetch` must be used under `AuthenticatedFetchProvider`'
    );
  }
  return sessionStore.authenticatedFetch;
};

/**
 * Get a function that can be used to start the request for a session, if there
 * if not already a known session. If you are using `useSession`, this is
 * automatically handled for you.
 */
export const useEnsureSession = () => {
  const sessionStore = useContext(SessionStoreContext);
  if (sessionStore === null) {
    throw new Error(
      '`useEnsureSession` must be used under `AuthenticatedFetchProvider`'
    );
  }
  return sessionStore.ensureSession;
};

/**
 * Get function that can be called to request a new session. For example, you
 * can call this to get an updated session if the user has logged in (or out)
 * using another tab/window.
 */
export const useRefreshSession = () => {
  const sessionStore = useContext(SessionStoreContext);
  if (sessionStore === null) {
    throw new Error(
      '`useRefreshSession` must be used under `AuthenticatedFetchProvider`'
    );
  }
  return sessionStore.refresh;
};

/**
 * Get function that can be called to destroy the current session (I.e., log
 * the user out).
 */
export const useDestroySession = () => {
  const sessionStore = useContext(SessionStoreContext);
  if (sessionStore === null) {
    throw new Error(
      '`useDestroySession` must be used under `AuthenticatedFetchProvider`'
    );
  }
  return sessionStore.destroySession;
};

const SessionContext = createContext<Session | null | undefined>(null);
SessionContext.displayName = 'Session Context';

const SessionContextProvider = ({
  children,
}: {
  readonly children: ReactNode;
}) => {
  const sessionStore = useContext(SessionStoreContext);
  if (sessionStore === null) {
    throw new Error('Invalid use of SessionContextProvider');
  }
  const [session, setSession] = useState<Session | null | undefined>(undefined);

  const sessionUpdateHandler = useCallback<SessionUpdateHandler>(
    ({ session }): void => {
      setSession(session);
    },
    []
  );

  useEffect(() => {
    if (sessionStore === null) {
      console.warn('useSession used without sessionStore available');
      return;
    }

    const {
      getCurrentSession,
      addEventListener,
      removeEventListener,
      ensureSession,
    } = sessionStore;
    const currentSession = getCurrentSession();
    setSession(currentSession);
    /*
     * This ensures we actually try to fetch the session, instead of waiting
     * for something to try and use `authenticatedFetch`
     */
    // eslint-disable-next-line no-void
    void ensureSession();

    addEventListener('sessionUpdate', sessionUpdateHandler);
    return () => {
      removeEventListener('sessionUpdate', sessionUpdateHandler);
    };
  }, [sessionUpdateHandler, sessionStore]);

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
};

export const AuthProvider = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <SessionStoreProvider>
    <SessionContextProvider>{children}</SessionContextProvider>
  </SessionStoreProvider>
);

export const useSession = () => {
  const session = useContext<Session | null | undefined>(SessionContext);
  return session;
};

export const useUserInfo = () => {
  const session = useSession();
  const userInfo = useMemo<UserInfo | null>(() => {
    if (session?.kind === 'authenticated') {
      return session.user;
    }
    return null;
  }, [session]);

  return userInfo;
};
