import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { useInView } from 'react-intersection-observer';
import type { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import { WorkspaceMember } from '@spaceduck/api';

import { useWorkspaceMembers } from '@api/workspace';
import useWorkspaceId from '@hooks/useWorkspaceId';
import ScrollArea from '@ui/ScrollArea';
import Spinner from '@ui/Spinner';
import UserAvatar from '@ui/UserAvatar';
import styles from './MentionMenuList.module.scss';
import { css } from '@/lib/css';

export type CommandListRef = {
  onKeyDown: NonNullable<
    ReturnType<NonNullable<SuggestionOptions['render']>>['onKeyDown']
  >;
};

const MentionMenuList = forwardRef<CommandListRef, SuggestionProps>(
  (props, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const mentionMenuListRef = useRef<HTMLDivElement>(null);
    const { ref: loadMoreRef, inView } = useInView();
    const [activeMember, setActiveMember] = useState<WorkspaceMember | null>(
      null
    );
    const [debounceIsLoading, setDebounceIsLoading] = useState(false);

    const [searchValue, setSearchvalue] = useState('');
    const debouncedSearch = useCallback(
      debounce(async (value: string) => {
        setDebounceIsLoading(false);
        setSearchvalue(value);
      }, 150),
      []
    );

    useEffect(() => {
      setDebounceIsLoading(true);
      debouncedSearch(props.query);
    }, [props.query]);

    const workspaceId = useWorkspaceId();
    const {
      data: workspaceMemberData,
      isLoading,
      isError,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
    } = useWorkspaceMembers(workspaceId, searchValue);

    const members = workspaceMemberData?.pages.flatMap((page) => page.members);

    useEffect(() => {
      if (inView && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, [inView, isFetchingNextPage, hasNextPage]);

    useEffect(() => {
      const activeElement =
        mentionMenuListRef.current?.querySelector('.active');
      activeElement?.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, [activeMember]);

    useEffect(() => {
      const firstResult = !!(members?.length && members.length > 0)
        ? members[0]
        : undefined;

      if (!firstResult) {
        setActiveMember(null);
      } else if (!activeMember && firstResult) {
        setActiveMember(firstResult);
      } else if (activeMember) {
        const found = members?.find((member) => member.id === activeMember.id);
        if (!found) {
          setActiveMember(firstResult);
        }
      }
    }, [members]);

    const insertContentBlock = (memberId: string, memberLabel: string) => {
      // TODO: Check if line is empty and replace
      props.editor
        .chain()
        .focus()
        .insertContentAt(props.range, [
          {
            type: 'mention',
            attrs: {
              id: memberId,
              label: memberLabel,
            },
          },
          {
            type: 'text',
            text: ' ',
          },
        ])
        .run();
    };

    const upHandler = () => {
      if (members && members.length > 0 && activeMember) {
        const activeIndex = members.findIndex(
          (members) => members.id === activeMember.id
        );

        if (activeIndex > 0 && members?.[activeIndex - 1]) {
          setActiveMember(members[activeIndex - 1] ?? null);
        }
      }
    };

    const downHandler = () => {
      if (members && members.length > 0) {
        if (activeMember) {
          const activeIndex = members.findIndex(
            (members) => members.id === activeMember.id
          );

          if (
            members.length - activeIndex < 3 &&
            hasNextPage &&
            !isFetchingNextPage
          ) {
            fetchNextPage();
          }

          if (activeIndex < 0) return;

          if (activeIndex + 1 < members.length) {
            const nextactiveMember = members[activeIndex + 1];
            if (nextactiveMember) {
              setActiveMember(nextactiveMember);
            }
          }
        }
      }
    };

    const enterHandler = () => {
      if (activeMember) {
        insertContentBlock(activeMember.id, activeMember.name);
      }
    };

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (!!containerRef.current?.checkVisibility?.()) {
          if (event.key === 'ArrowUp') {
            upHandler();
            return true;
          }

          if (event.key === 'ArrowDown') {
            downHandler();
            return true;
          }
        }

        if (event.key === 'Enter') {
          enterHandler();
          return true;
        }

        return false;
      },
    }));

    const handleListItemClick = (member: WorkspaceMember | null) => {
      if (member?.id) {
        insertContentBlock(member.id, member.name);
      }
    };

    if (isError) return null;

    if (isLoading || debounceIsLoading)
      return (
        <div className={clsx(styles.container, styles.isLoading)}>
          <Spinner />
        </div>
      );

    return (
      <div className={styles.container} ref={containerRef}>
        <div className={styles.mentionsList} ref={mentionMenuListRef}>
          <ScrollArea
            className={styles.scrollArea}
            style={css({
              '--width': '100%',
              '--maxHeight': '100%',
            })}
          >
            {members?.map((member) => {
              return (
                <div
                  className={clsx(
                    styles.membersListItem,
                    activeMember?.id === member.id && `${styles.active} active`
                  )}
                  key={member.id}
                  onClick={() => handleListItemClick(member)}
                  onMouseOver={() => setActiveMember(member)}
                >
                  <div className={styles.avatar}>
                    <UserAvatar
                      name={member.name}
                      imageUrl={member.avatarUrl}
                      size="xs"
                      className={styles.avatar}
                    />
                  </div>
                  <div className={styles.name}>{member.name}</div>
                </div>
              );
            })}
            {hasNextPage && (
              <div ref={loadMoreRef} style={{ width: '100%', height: '10px' }}>
                {/* Triggers page fetch when in view */}
              </div>
            )}
          </ScrollArea>
        </div>
      </div>
    );
  }
);

MentionMenuList.displayName = 'MentionMenuList';

export default MentionMenuList;
