import { useHotkeys } from 'react-hotkeys-hook';
import { Icon24 } from '@spaceduck/icons';
import type { MediaGroupDetailDTO } from '@spaceduck/api';
import clsx from 'clsx';

import { isCapable } from '@api/util';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useNavigateWithState } from '@hooks/useNavigateWithState';
import { useNotesStore } from '@stores/useNotesStore';
import Button, { ButtonLink } from '@ui/Button';
import Spinner from '@ui/Spinner';
import Tooltip from '@ui/Tooltip';
import { copyUrlToClipboard } from '@utils/copyToClipboard';
import { getCloseTo } from '@utils/mediaGroup';
import { getOS } from '@utils/os';
import { urlFor } from '@/urls';
import { DetailsModelTab } from '@/types';
import DetailsModalActionsDropdown from './DetailsModalActionsDropdown';
import styles from './DetailsModalHeader.module.scss';

const { Close, Comment, DrawerRight } = Icon24;

type DetailsModalHeaderProps = {
  mediaGroup: MediaGroupDetailDTO;
  isNote: boolean;
};

export default function DetailsModalHeader({
  mediaGroup,
  isNote,
}: DetailsModalHeaderProps) {
  const {
    contentView,
    drawerIsOpen,
    isCommenting,
    openDrawer,
    setIsCommenting,
    setQuickViewMediaGroupId,
    toggleDrawer,
    userCapabilities,
  } = useDetailsModalStore((state) => ({
    contentView: state.contentView,
    drawerIsOpen: state.drawerIsOpen,
    isCommenting: state.isCommenting,
    openDrawer: state.openDrawer,
    setIsCommenting: state.setIsCommenting,
    setQuickViewMediaGroupId: state.setQuickViewMediaGroupId,
    toggleDrawer: state.toggleDrawer,
    userCapabilities: state.userCapabilities,
  }));
  const backgroundLocation = useBackgroundLocation();
  const { isNotesSaving, isQuickViewNotesSaving } = useNotesStore();

  const navigateWithState = useNavigateWithState();

  const setActiveTab = (activeTab: DetailsModelTab) => {
    const url = urlFor(
      activeTab === 'comment' ? 'mediaGroupComments' : 'mediaGroup',
      { mediaGroupId: mediaGroup.id }
    );
    navigateWithState(url);
  };

  useHotkeys(
    'meta+alt+s',
    () => {
      copyUrlToClipboard();
    },
    {
      enabled: getOS().isMac,
      preventDefault: true,
    }
  );

  useHotkeys(
    'ctrl+alt+s',
    () => {
      copyUrlToClipboard();
    },
    {
      enabled: !getOS().isMac,
      preventDefault: true,
    }
  );

  useHotkeys(
    'meta+.',
    () => {
      toggleDrawer();
    },
    {
      enabled: getOS().isMac,
      preventDefault: true,
    }
  );

  useHotkeys(
    'ctrl+.',
    () => {
      toggleDrawer();
    },
    {
      enabled: !getOS().isMac,
      preventDefault: true,
    }
  );

  const toggleCommenting = () => {
    if (isCommenting) {
      setIsCommenting(false);
    } else {
      setIsCommenting(true);
      setActiveTab('comment');
      openDrawer();
    }
  };

  useHotkeys(
    'c',
    () => {
      toggleCommenting();
      openDrawer();
    },
    {
      enabled: isCapable('comment', userCapabilities).capable,
    },
    [isCommenting, userCapabilities]
  );

  useHotkeys(
    'Escape',
    () => {
      setIsCommenting(false);
    },
    {
      enabled: isCommenting,
    },
    [isCommenting]
  );

  const showCommentButton =
    isCapable('comment', userCapabilities).capable &&
    ['content'].includes(contentView ?? '') &&
    mediaGroup.media.length !== 0 &&
    !mediaGroup.isGeneratingContent;

  return (
    <div className={styles.header}>
      <nav className={styles.navigation}>
        <Tooltip
          align="start"
          content="Close"
          shortkeys={['Esc']}
          size="medium"
          variant="secondary"
        >
          {isNotesSaving || isQuickViewNotesSaving ? (
            <Button disabled className={styles.button} variant="icon">
              <Close size={20} />
            </Button>
          ) : (
            <ButtonLink
              className={styles.button}
              variant="icon"
              onClick={() => setQuickViewMediaGroupId(null)}
              to={backgroundLocation || getCloseTo(mediaGroup)}
            >
              <Close size={20} />
            </ButtonLink>
          )}
        </Tooltip>
      </nav>
      {isNote && (
        <div className={styles.notesUtils}>
          <div className={styles.savingIndicator}>
            {(isNotesSaving || isQuickViewNotesSaving) && (
              <>
                <Spinner size={16} />
                <span className={styles.savingLabel}>Saving...</span>
              </>
            )}
          </div>
        </div>
      )}
      <div className={styles.utils}>
        <DetailsModalActionsDropdown mediaGroupId={mediaGroup.id} />
        {showCommentButton && (
          <Tooltip
            content="Comment"
            icons={['c']}
            size="medium"
            variant="secondary"
          >
            <Button
              className={clsx(styles.button, isCommenting && styles.active)}
              onClick={() => toggleCommenting()}
              variant="icon"
            >
              <Comment size={20} />
            </Button>
          </Tooltip>
        )}
        <Tooltip
          align="end"
          content={`${drawerIsOpen ? 'Hide' : 'Show'} Sidebar`}
          shortkeys={['⌘', '.']}
          size="medium"
          variant="secondary"
        >
          <Button
            className={styles.button}
            variant="icon"
            onClick={() => toggleDrawer()}
          >
            <DrawerRight size={20} />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
