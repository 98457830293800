import { Link } from 'react-router-dom';

import Head from '@components/Head';

export default function HelpAndSupportPage() {
  return (
    <>
      <Head title="Help and Support" />
      <div className="body4">
        <h1 className="h5">Help & Support</h1>
        <p>
          For any issues, regarding Spaceduck, please reach out to us on{' '}
          <a href="mailto:support@spaceduck.com" target="_blank">
            support@spaceduck.com
          </a>{' '}
          or join our Slack.
        </p>
        <hr />
        <h2 className="title1">General</h2>
        <p>
          <Link to="/supported-file-types">
            Supported links, Files and sizes
          </Link>
        </p>
      </div>
    </>
  );
}
