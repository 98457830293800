import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { listUserProjectMemberships, patchUserSelf } from '@spaceduck/api';

export const userKeys = {
  all: ['user'] as const,
  listProjects: (filters: {
    userId: string;
    workspaceId: string;
    query: string;
  }) => [...userKeys.all, filters] as const,
};

export const useUserProjectMemberships = ({
  userId,
  workspaceId,
  query,
}: {
  userId: string;
  workspaceId: string;
  query?: string;
}) => {
  const infiniteQuery = useInfiniteQuery({
    queryKey: userKeys.listProjects({
      userId,
      workspaceId,
      query: query ?? '',
    }),
    initialPageParam: undefined as string | undefined,
    queryFn: async (context) => {
      const [_, { userId, workspaceId, query }] = context.queryKey;
      const cursor = context.pageParam;
      const response = await listUserProjectMemberships(userId, {
        workspaceId,
        query,
        cursor,
      });
      const { kind, ...payload } = response;
      return payload;
    },
    getNextPageParam: (lastPage) => lastPage?.nextCursor ?? undefined,
    getPreviousPageParam: () => undefined,
  });
  return { ...infiniteQuery, workspaceId };
};

export const usePatchUserSelf = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchUserSelf,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });
  return mutation;
};
