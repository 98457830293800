import Button from '@ui/Button';
import Toast from '@ui/Toast';
import { openMailTo } from '@utils/openMailTo';
import styles from './WarningToast.module.scss';
import { MouseEventHandler } from 'react';

export default function WarningToast({
  message,
  title,
  onButtonClick,
  buttonText,
}: {
  message?: string;
  title?: string;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  buttonText?: string;
}) {
  const onClick = onButtonClick ?? (() => openMailTo('[SUPPORT]', ''));
  return (
    <Toast
      title={title}
      body={message || 'Something went wrong, please contact support'}
      className={styles.warningToast}
      iconVariant="warning"
      unwrapChild={true}
    >
      <div className={styles.buttonWrapper}>
        <Button className={styles.button} onClick={onClick} variant="link">
          {!buttonText && `Contact`}
          {buttonText}
        </Button>
      </div>
    </Toast>
  );
}
