import { type ReactNode } from 'react';
import MenuMoreSection from '@layouts/sidebar/MenuMoreSection';
import styles from './SettingsSidebarContent.module.scss';
import '@layouts/sidebar/Menu.scss';

type SettingsSidebarContentProps = {
  backButton?: ReactNode;
  children?: ReactNode;
  includeMoreMenu?: boolean;
  menu?: ReactNode;
};

export default function SettingsSidebarContent({
  backButton,
  children,
  includeMoreMenu,
  menu,
}: SettingsSidebarContentProps) {
  return (
    <div className="menu">
      <nav className={styles.settings}>
        {!!backButton && <div className={styles.relativeNav}>{backButton}</div>}
        {!!menu && <div className={styles.account}>{menu}</div>}
        {children}
      </nav>
      {!!includeMoreMenu && <MenuMoreSection />}
    </div>
  );
}
