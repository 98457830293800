import TableCell from '@tiptap/extension-table-cell';
import { NodeViewRendererProps, mergeAttributes } from '@tiptap/react';
import { Editor } from '@tiptap/core';
import tippy from 'tippy.js';
import { Node } from '@tiptap/pm/model';

export function createButton(text?: string, className?: string) {
  const button = document.createElement('button');
  button.type = 'button';
  button.className = className ?? 'cellMenuItem';
  if (text) {
    button.textContent = text;
  }
  return button;
}

type CellType = 'td' | 'th';

export const getTableCellNodeView = (
  props: NodeViewRendererProps,
  cellType: CellType
) => {
  const { editor, HTMLAttributes, node } = props;

  // Creating elements with JS as TipTap React wrapper adds extra elements
  const dom = document.createElement(cellType);
  mergeAttributes(dom, HTMLAttributes);

  const wrapper = document.createElement('div');
  wrapper.className = 'cellWrapper';

  const menuWrapper = document.createElement('div');
  menuWrapper.className = 'cellMenuWrapper';

  const trigger = createButton(undefined, 'cellMenuToggle iconCaret');
  const menuBox = document.createElement('div');
  menuBox.style.display = 'none';

  const triggerWrapper = document.createElement('div');
  triggerWrapper.className = 'cellMenuToggleWrapper';
  triggerWrapper.append(menuBox);
  triggerWrapper.append(trigger);

  const menuContent = createMenu(cellType, node, editor);
  menuWrapper.append(triggerWrapper);

  const content = document.createElement('div');
  content.className = 'cellContent';
  wrapper.append(content);
  wrapper.append(menuWrapper);
  dom.append(wrapper);

  const tippyMenu = tippy(trigger, {
    allowHTML: true,
    appendTo: () => document.getElementById('tippyTableCellMenuRoot')!,
    getReferenceClientRect: () => {
      const rect = triggerWrapper.getBoundingClientRect();

      const data = {
        top: rect?.top ?? 0,
        right: rect?.right ?? 0,
        bottom: rect?.bottom ?? 0,
        left: rect?.left ?? 0,
        width: rect?.width ?? 0,
        height: rect?.height ?? 0,
        x: rect?.x ?? 0,
        y: rect?.y ?? 0,
      };

      return { ...data, toJSON: () => data };
    },
    content: menuContent,
    interactive: true,
    offset: [0, 0],
    placement: 'bottom-end',
    trigger: 'none',
  });

  triggerWrapper.addEventListener('click', (ev) => {
    if (ev.target instanceof HTMLElement) {
      ev.target
        .closest(cellType)
        ?.querySelector<HTMLElement>('.cellContent')
        ?.focus();
    }
    if (document.getElementById('tippyTableCellMenuRoot')) {
      tippyMenu.show();
    }
  });

  return {
    dom,
    contentDOM: content,
  };
};

export default TableCell.extend({
  addNodeView() {
    return (props) => getTableCellNodeView(props, 'td');
  },
});

const createMenu = (cellType: CellType, node: Node, editor: Editor) => {
  // Creating elements with JS as TipTap React wrapper adds extra elements
  const cellTypeBtn = createButton('Cell type', 'cellMenuItem hasChildren');

  const cellTypeHeaderBtn = createButton(
    'Header',
    `${cellType === 'th' ? 'cellMenuItem active' : 'cellMenuItem'}`
  );
  cellTypeHeaderBtn.addEventListener('click', () => {
    if (node.type.name === 'tableCell') {
      cellTypeHeaderBtn.classList.add('active');
      cellTypeBodyBtn.classList.remove('active');
      editor.chain().focus().toggleHeaderCell().run();
      clearTippyRootContainer();
    }
  });

  const cellTypeBodyBtn = createButton(
    'Body',
    `${cellType === 'td' ? 'cellMenuItem active' : 'cellMenuItem'}`
  );
  cellTypeBodyBtn.addEventListener('click', () => {
    if (node.type.name === 'tableHeader') {
      cellTypeBodyBtn.classList.add('active');
      cellTypeHeaderBtn.classList.remove('active');
      editor?.chain().focus().toggleHeaderCell().run();
      clearTippyRootContainer();
    }
  });

  const cellTypeSubMenuWrapper = document.createElement('div');
  cellTypeSubMenuWrapper.className = 'cellSubMenuWrapper';

  const cellTypeSubMenu = document.createElement('div');
  cellTypeSubMenu.className = 'cellSubMenu';
  cellTypeSubMenu.append(cellTypeHeaderBtn);
  cellTypeSubMenu.append(cellTypeBodyBtn);
  cellTypeSubMenuWrapper.append(cellTypeBtn);
  cellTypeSubMenuWrapper.append(cellTypeSubMenu);

  const insertRowAboveBtn = createButton('Insert row above');
  insertRowAboveBtn.addEventListener('click', () => {
    editor?.chain().focus().addRowBefore().run();
    clearTippyRootContainer();
  });

  const insertRowBelowBtn = createButton('Insert row below');
  insertRowBelowBtn.addEventListener('click', () => {
    editor?.chain().focus().addRowAfter().run();
    clearTippyRootContainer();
  });

  const insertColumnLeftBtn = createButton('Insert column left');
  insertColumnLeftBtn.addEventListener('click', () => {
    editor?.chain().focus().addColumnBefore().run();
    clearTippyRootContainer();
  });

  const insertColumnRightBtn = createButton('Insert column right');
  insertColumnRightBtn.addEventListener('click', () => {
    editor?.chain().focus().addColumnAfter().run();
    clearTippyRootContainer();
  });

  const deleteColumnBtn = createButton('Delete column');
  deleteColumnBtn.addEventListener('click', () => {
    editor?.chain().focus().deleteColumn().run();
    clearTippyRootContainer();
  });

  const deleteRowBtn = createButton('Delete row');
  deleteRowBtn.addEventListener('click', () => {
    editor?.chain().focus().deleteRow().run();
    clearTippyRootContainer();
  });

  const deleteTableBtn = createButton('Delete table');
  deleteTableBtn.addEventListener('click', () => {
    editor?.chain().focus().deleteTable().run();
    clearTippyRootContainer();
  });

  const separator = document.createElement('div');
  separator.className = 'cellMenuSeparator';

  const menu = document.createElement('div');
  menu.className = 'cellMenu';

  menu.append(
    cellTypeSubMenuWrapper,
    separator.cloneNode(),
    insertRowAboveBtn,
    insertRowBelowBtn,
    separator.cloneNode(),
    insertColumnLeftBtn,
    insertColumnRightBtn,
    separator.cloneNode(),
    deleteColumnBtn,
    deleteRowBtn,
    deleteTableBtn
  );

  const menuWrapper = document.createElement('div');
  menuWrapper.className = 'cellMenuFloat';

  const fauxButton = createButton(undefined, 'cellMenuToggle iconCaret');
  menuWrapper.append(fauxButton);
  menuWrapper.append(menu);

  return menuWrapper;
};

const clearTippyRootContainer = () => {
  const root = document.querySelector('#tippyTableCellMenuRoot');
  if (root) {
    root.innerHTML = '';
  }
};
