import createToast from '@utils/createToast';
import type { CreateToastProps } from '@utils/createToast';

export const copyTextToClipboard = async (
  text: string,
  toast?: Partial<CreateToastProps>
) => {
  const clipboard = navigator.clipboard;
  if (!clipboard) {
    return createToast({
      bodyText: 'Unable to access clipboard',
      iconVariant: 'warning',
    });
  }

  try {
    clipboard.writeText(text);
  } catch (error) {
    console.error('Failed to copy to clipboard', error);
    createToast({
      bodyText: 'Unable to copy to clipboard',
      iconVariant: 'danger',
    });
    return;
  }

  createToast({
    titleText: 'Copied!',
    bodyText: 'Text copied to clipboard!',
    iconVariant: 'success',
    ...toast,
  });
};

export const copyUrlToClipboard = async () => {
  copyTextToClipboard(window.location.toString(), {
    bodyText: 'Share link copied to clipboard!',
  });
};
