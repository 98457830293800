import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { upperFirst } from 'lodash';
import { MediaGroupSortOption, mediaGroupSortOptions } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import { useMediaGroupCategory } from '@api/workspace';
import CategoryTable from '@components/category/Category';
import { NewMediaGroupDropdown } from '@components/NewMediaGroupDropdown';
import { useDebouncedSearch } from '@hooks/useDebouncedSearch';
import { Search, SubNav } from '@pages/common';
import Button from '@ui/Button';
import DropdownMenu, { MenuItem } from '@ui/DropdownMenu';
import TopNav from './common/TopNav';
import styles from './Categories.module.scss';

const { Sort } = Icon16;
const { Down } = Icon24;

export default function CategoriesPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const { debouncedSetSearchQuery } = useDebouncedSearch(setSearchQuery);
  const [sortBy, setSortBy] = useState<MediaGroupSortOption>('name');

  const { categoryId } = useParams();
  // TODO: Use URL params
  const { data: categoryData } = useMediaGroupCategory(categoryId || null);

  const category = categoryData?.category;
  const canEdit =
    category?.capabilities?.find(({ capability }) => capability === 'edit')
      ?.capable ?? false;

  return (
    <>
      <TopNav title="Categories" currentBreadcrumb={category?.label ?? ''}>
        {canEdit && category?.id && (
          <NewMediaGroupDropdown
            mediaGroupAttributes={{ categoryId: category?.id }}
            triggerText="New content"
            variant="outlined"
          />
        )}
      </TopNav>
      <SubNav>
        <div className={styles.utilities}>
          <DropdownMenu
            className={styles.sortDropdown}
            isPadded
            triggerContent={
              <Button
                className={styles.sortButton}
                iconAfter={<Down size={16} />}
                iconBefore={<Sort size={15} />}
                variant="outlined"
              >
                Sort by: {sortBy}
              </Button>
            }
          >
            {mediaGroupSortOptions.map((sortByOption, idx) => (
              <MenuItem
                key={idx}
                onSelect={() => {
                  setSortBy(sortByOption);
                }}
              >
                {upperFirst(sortByOption)}
              </MenuItem>
            ))}
          </DropdownMenu>
          <Search
            className={styles.searchBox}
            collapsible
            defaultExpanded={false}
            defaultValue={searchQuery}
            onInput={(ev) => debouncedSetSearchQuery(ev.currentTarget.value)}
            placeholder="Find an entry..."
            size="sm"
            status="success"
          />
        </div>
      </SubNav>
      {!!category ? (
        <CategoryTable
          category={category}
          searchQuery={searchQuery}
          sortBy={sortBy}
        />
      ) : (
        <NoCategory />
      )}
    </>
  );
}

const NoCategory = () => {
  return <div></div>;
};
