import { useEffect, useState } from 'react';
import {
  TableOfContentDataItem,
  TableOfContents,
  getHierarchicalIndexes,
} from '@tiptap-pro/extension-table-of-contents';
import { useEditor } from '@tiptap/react';
import { EditorProps } from 'prosemirror-view';
import { MediaGroupDTO, MediaGroupDetailDTO } from '@spaceduck/api';

import {
  extensions as defaultExtensions,
  webViewExtensions,
} from '@components/detailsModal/tiptap/extensions';
import { useIsInWebViewRoute } from '@hooks/useIsWebView';
import { useFileUpload } from '@hooks/useNotesFileUpload';
import { useNotesSave } from '@hooks/useNotesSave';
import { useThrottledAction } from '@hooks/useThrottledAction';

export const useNotesEditor = (
  mediaGroup?: MediaGroupDetailDTO,
  editorProps?: EditorProps,
  asQuckView?: boolean
) => {
  const isInWebViewRoute = useIsInWebViewRoute();
  const mediaGroupId = mediaGroup?.id;
  const { save, debounceSave } = useNotesSave(asQuckView);
  const [items, setItems] = useState<TableOfContentDataItem[]>([]);

  const { action: throttledSave } = useThrottledAction(() => {
    if (mediaGroupId) {
      save(mediaGroupId, editor?.getJSON(), editor?.getText());
    }
  }, 2000);

  const onUpdate = async () => {
    throttledSave();
    debounceSave(mediaGroupId, editor?.getJSON(), editor?.getText());
  };

  const editorConfig = {
    editorProps,
    extensions: isInWebViewRoute
      ? [...webViewExtensions]
      : [
          ...defaultExtensions,
          TableOfContents.configure({
            getIndex: getHierarchicalIndexes,
            onUpdate(content) {
              setItems(content);
            },
          }),
        ],
    content: mediaGroup?.document,
    onUpdate,
  };

  const editor = useEditor(editorConfig, [mediaGroupId]);

  const {
    handleDrop: handleFileDrop,
    inputRef,
    handleInputChange,
    mediaGroupIds,
  } = useFileUpload(editor, mediaGroup);

  editor?.setOptions({
    editorProps: {
      handleDrop: (view, event, slice, moved) => {
        const coordinates = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        });

        if (coordinates) {
          if (
            /(blockquote|listItem)/.test(
              editor.$pos(coordinates.pos).node.type.name
            )
          ) {
            return true;
          }
        }

        const mediaGroupData = event.dataTransfer?.getData('mediaGroup');
        if (mediaGroupData) {
          let mediaGroup = null;
          try {
            mediaGroup = JSON.parse(mediaGroupData) as Partial<MediaGroupDTO>;
          } catch (ex) {
            console.error('Could not parse media group data', ex);
          }

          if (!mediaGroup) {
            return false;
          }

          if (mediaGroup.id === mediaGroupId) {
            // Prevent drop on self
            return true;
          }

          const { schema } = view.state;
          const coordinates = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });

          const nodeType =
            mediaGroup.kind === 'document' ? 'outgoing-link' : 'content-block';

          const node = schema?.nodes?.[nodeType]?.create({
            id: mediaGroup.id,
          });

          if (coordinates && node && mediaGroup.id) {
            const transaction = view.state.tr.insert(coordinates.pos, node);
            view.dispatch(transaction);
          }
          return true;
        }

        return handleFileDrop(view, event, slice, moved);
      },
      handleKeyDown(_view, event) {
        if (event.key === 'Tab') {
          event.stopPropagation();
        }

        if (event.key === 'Enter' && !event.shiftKey) {
          const { state } = editor;
          const { selection } = state;
          const { $from, empty } = selection;

          if (!empty) return false;

          const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;

          if (!isAtEnd) return false;

          // TODO: Find a more concise way to remove multiple marks
          editor
            .chain()
            .unsetColor()
            .unsetHighlight()
            .unsetBold()
            .unsetItalic()
            .unsetCode()
            .unsetStrike()
            .unsetUnderline()
            .run();

          return false;
        }
      },
    },
  });

  useEffect(() => {
    queueMicrotask(() => {
      if (!editor) {
        return;
      }
      for (const key in mediaGroupIds) {
        const content = editor.getHTML();
        if (!content) return;
        editor.setEditable(false, false);
        const container = document.createElement('div');
        container.innerHTML = content;

        for (const contentBlockComponent of Array.from(
          container.querySelectorAll('content-block')
        )) {
          if (contentBlockComponent.getAttribute('loading') === 'true') {
            const ref = contentBlockComponent.getAttribute('data-ref');
            if (ref) {
              const match = mediaGroupIds[key];
              if (!match) {
                continue;
              }
              contentBlockComponent.removeAttribute('loading');
              contentBlockComponent.setAttribute('id', match);
            }
          }
        }

        editor.setEditable(true, false);
        editor.commands.setContent(container.innerHTML);
        editor.setEditable(true);
      }
    });
  }, [mediaGroupIds]);

  return {
    editor,
    imageUploadInputRef: inputRef,
    handleImageUploadInputChange: handleInputChange,
    tableOfContentItems: items,
  };
};
