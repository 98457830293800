import { PluginKey } from 'prosemirror-state';
import { Extension } from '@tiptap/core';
import { Editor, Range } from '@tiptap/react';
import Suggestion, { SuggestionProps } from '@tiptap/suggestion';
import { ReactRenderer } from '@tiptap/react';
import tippy, { type Instance as TippyInstance } from 'tippy.js';

import ContentBlockList, {
  CommandListRef,
} from './contentBlockMenu/ContentBlockList';

export default Extension.create({
  name: 'contentBlockMenu',
  addOptions() {
    return {
      suggestion: {
        char: '+',
        startOfLine: false,
        allowSpaces: true,
        command: ({
          editor,
          range,
          props,
        }: {
          editor: Editor;
          range: Range;
          props: SuggestionProps;
        }) => {
          props.command({ editor, range, props });
        },
        render: () => {
          let component: ReactRenderer<CommandListRef> | undefined = undefined;
          let popup: TippyInstance[] | undefined = undefined;

          return {
            onStart: (props: any) => {
              component = new ReactRenderer(ContentBlockList, {
                props,
                editor: props.editor,
              });

              popup = tippy('body', {
                getReferenceClientRect: props.clientRect,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
                theme: 'sdPopper',
              });
            },
            onUpdate(props: any) {
              component?.updateProps(props);

              popup?.[0]?.setProps({
                getReferenceClientRect: props.clientRect,
              });
            },
            onKeyDown(props: any) {
              if (props.event.key === 'Escape') {
                component?.destroy();
                popup?.[0]?.destroy();
                return true;
              }

              return component?.ref?.onKeyDown(props);
            },
            onExit() {
              component?.destroy();
              popup?.[0]?.destroy();
            },
          };
        },
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        pluginKey: new PluginKey('ContentBlockMenu'),
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});
