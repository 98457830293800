import { CategoryFieldKindSettings } from '@spaceduck/api';

import type {
  AddTagOption,
  DeleteTagOption,
  EditTagOption,
  StandardCellValue,
  TableColumnMeta,
} from '@/types/Category';
import {
  Date,
  Link,
  Phone,
  Number,
  Text,
  Tag,
  Relation,
  Checkbox,
} from './cellContent/index';

type CellContentProps = {
  addTagOption: AddTagOption;
  columnData: TableColumnMeta;
  deleteTagOption: DeleteTagOption;
  editTagOption: EditTagOption;
  kindAndSettings: CategoryFieldKindSettings;
} & StandardCellValue;

export default function CellContent({
  addTagOption,
  canEdit,
  clearSelectedCell,
  columnData,
  deleteTagOption,
  editTagOption,
  handleDelete,
  handleUpdate,
  info,
  kindAndSettings,
  setSelectedCell,
  showPreview,
  value,
}: CellContentProps) {
  const baseProps = {
    canEdit,
    clearSelectedCell,
    handleDelete,
    handleUpdate,
    info,
    setSelectedCell,
    showPreview,
    value,
  };

  switch (kindAndSettings.kind) {
    case 'checkbox':
      return <Checkbox {...baseProps} />;
    case 'date':
      return <Date {...baseProps} />;
    case 'email':
      return <Link {...baseProps} type="email" />;
    case 'multi-select':
      return (
        <Tag
          {...baseProps}
          addTagOption={addTagOption}
          deleteTagOption={deleteTagOption}
          editTagOption={editTagOption}
          options={kindAndSettings.settings.options}
          type="multi-select"
        />
      );
    case 'number':
      return <Number {...baseProps} />;
    case 'phone':
      return <Phone {...baseProps} />;
    case 'relationship':
      return <Relation {...baseProps} columnData={columnData} />;
    case 'select':
      return (
        <Tag
          {...baseProps}
          addTagOption={addTagOption}
          deleteTagOption={deleteTagOption}
          editTagOption={editTagOption}
          options={kindAndSettings.settings.options}
          type="select"
        />
      );
    case 'text':
      return <Text {...baseProps} />;
    case 'url':
      return <Link {...baseProps} type="url" />;
    default:
      return null;
  }
}
