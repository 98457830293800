import React, {
  createContext,
  useContext,
  useState,
  ReactElement,
  ReactNode,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

interface ModalInfo {
  component: ReactElement;
  props?: Record<string, any> & { closeModal?: () => void };
  closeModal?: () => void;
}

interface ModalManagerContextProps {
  modals: ModalInfo[];
  openModal: (modalInfo: ModalInfo) => void;
  closeModal: () => void;
}

const ModalManagerContext = createContext<ModalManagerContextProps | undefined>(
  undefined
);

export const ModalManagerProvider = ({ children }: { children: ReactNode }) => {
  const [modals, setModals] = useState<ModalInfo[]>([]);

  const openModal = (modalInfo: ModalInfo) => {
    setModals((currentModals) => [...currentModals, modalInfo]);
  };

  const closeModal = () => {
    modals.at(-1)?.closeModal?.();
    setModals((currentModals) => currentModals.slice(0, -1));
  };

  useHotkeys(
    'Escape',
    () => {
      closeModal();
    },
    {
      enabled: !!modals?.length,
    },
    [modals]
  );

  return (
    <ModalManagerContext.Provider value={{ modals, openModal, closeModal }}>
      {children}
      {modals.map((modal, index) =>
        React.cloneElement(modal.component, {
          key: index,
          ...modal.props,
          closeModal,
        })
      )}
    </ModalManagerContext.Provider>
  );
};

export const useModalManager = (): ModalManagerContextProps => {
  const context = useContext(ModalManagerContext);
  if (!context) {
    throw new Error(
      'useModalManager must be used within a ModalManagerProvider'
    );
  }
  return context;
};
