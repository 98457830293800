export type ConsentArg = 'default' | 'update';
export type ConsentGrant = 'granted' | 'denied';
export type ConsentGrantConfig = {
  ad_storage: ConsentGrant;
  ad_user_data: ConsentGrant;
  ad_personalization: ConsentGrant;
  analytics_storage: ConsentGrant;
};

function attemptGoogleTag(
  action: 'config',
  targetId: string,
  info?: Record<string, string>
): void;
function attemptGoogleTag(
  action: 'get',
  targetId: string,
  fieldName: string,
  callback: (field: string) => void
): void;
function attemptGoogleTag(
  action: 'set',
  key: string,
  value: Record<string, string>
): void;
function attemptGoogleTag(
  action: 'event',
  eventName: string,
  params?: Record<string, string>
): void;
function attemptGoogleTag(
  action: 'consent',
  arg: ConsentArg,
  params?: Partial<ConsentGrantConfig>
): void;
function attemptGoogleTag(...args: any[]) {
  const w = window as { gtag?: (...args: any[]) => void };
  const gtag = w.gtag;

  if (!gtag) {
    return null;
  }

  try {
    gtag(...args);
  } catch (error) {
    console.error('Error running gtag function', error);
    return false;
  }
  return true;
}

export const trackEvent = (
  eventName: string,
  params?: Record<string, string>
) => {
  // https://developers.google.com/tag-platform/gtagjs/reference#event
  return attemptGoogleTag('event', eventName, params);
};

export const setDefaultConsent = (params: ConsentGrantConfig) => {
  return attemptGoogleTag('consent', 'default', params);
};

export const updateConsent = (params: ConsentGrantConfig) => {
  return attemptGoogleTag('consent', 'update', params);
};
