import { Icon16 } from '@spaceduck/icons';
import { pluralize } from '@spaceduck/utils';

import Button from '@ui/Button';
import styles from './SelectionToolbar.module.scss';

const { Delete, EditPencil, Links, Remove } = Icon16;

export default function SelectionToolbar({
  canEdit,
  handleCopyLink,
  handleDelete,
  handleRemoveItem,
  handleRenameItem,
  handleUnToggleAll,
  selectedRows,
}: {
  canEdit: boolean;
  handleCopyLink: () => void;
  handleDelete: () => void;
  handleRemoveItem: () => Promise<void>;
  handleRenameItem: () => void;
  handleUnToggleAll: () => void;
  selectedRows: string[];
}) {
  if (!selectedRows?.length) return null;

  return (
    <div className={styles.selectionToolbarWrapper}>
      <div className={styles.selectionToolbar}>
        <div>
          {selectedRows.length}{' '}
          {pluralize(selectedRows.length > 1, 'items', 'item')} selected
        </div>
        {canEdit && (
          <div className={styles.toolbarButtons}>
            {selectedRows.length === 1 ? (
              <>
                <Button
                  iconBefore={<Remove />}
                  onClick={handleRemoveItem}
                  size="sm"
                  variant="outlined"
                >
                  Remove from category
                </Button>
                <Button
                  iconBefore={<EditPencil />}
                  onClick={handleRenameItem}
                  size="sm"
                  variant="outlined"
                >
                  Rename
                </Button>
                <Button
                  iconBefore={<Links />}
                  onClick={handleCopyLink}
                  size="sm"
                  variant="outlined"
                >
                  Copy link
                </Button>
                <Button
                  iconBefore={<Delete />}
                  onClick={handleDelete}
                  size="sm"
                  variant="outlined"
                >
                  Delete item
                </Button>
                <Button
                  onClick={handleUnToggleAll}
                  size="sm"
                  variant="outlined"
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  iconBefore={<Remove />}
                  onClick={handleRemoveItem}
                  size="sm"
                  variant="outlined"
                >
                  Remove from category
                </Button>
                <Button
                  iconBefore={<Delete />}
                  onClick={handleDelete}
                  size="sm"
                  variant="outlined"
                >
                  Delete items
                </Button>
                <Button
                  onClick={handleUnToggleAll}
                  size="sm"
                  variant="outlined"
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
