import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { DateFieldInstance, isDateFieldInstance } from '@spaceduck/api';

import { useCategoryCellSelectionKeyboard } from '@hooks/useCategoryCellSelection';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import DatePicker from '@ui/DatePicker';
import {
  AvailableTypes,
  StandardCellValue,
  StandardEditCellValue,
} from '@/types/Category';
import { getDisplayDate } from '@/utils/date';
import styles from './Date.module.scss';

export function getDate(dateString?: string | null) {
  if (!dateString) return null;

  try {
    return new Date(dateString);
  } catch {
    return null;
  }
}

function formatDateForDisplay(date?: Date | null) {
  if (!date) return null;

  return getDisplayDate(date);
}

export function formatDateForPersisting(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const calendarDate = date.getDate();

  return `${year}-${`${month}`.padStart(2, '0')}-${`${calendarDate}`.padStart(2, '0')}`;
}

function getInitialValue(value: AvailableTypes) {
  const instance = value.filter(isDateFieldInstance).find(() => true);

  if (!instance) return null;
  const date = getDate(instance.date);
  if (!date) {
    return null;
  }
  return instance;
}

export default function DateValue({
  clearSelectedCell,
  canEdit,
  handleDelete,
  handleUpdate,
  setSelectedCell,
  showPreview,
  info,
  value,
}: StandardCellValue) {
  const initialValue = getInitialValue(value);
  const [shouldShowEditView, setShouldShowEditView] = useState(false);
  const [localValue, setLocalValue] = useState<DateFieldInstance | null>(
    initialValue
  );

  const { setEnabled } = useCategoryCellSelectionKeyboard({
    setSelectedCell,
    enableOnLoad: false,
    onEnter: () => setShouldShowEditView(true),
    onEscape: () => clearSelectedCell(),
  });

  useEffect(() => {
    setEnabled(showPreview);
  }, [showPreview]);

  return (
    <>
      {canEdit && shouldShowEditView && (
        <EditDateValue
          clearSelectedCell={clearSelectedCell}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
          info={info}
          initialValue={initialValue}
          localValue={localValue}
          setLocalValue={setLocalValue}
          setShouldShowEditView={setShouldShowEditView}
        />
      )}
      {showPreview ? (
        <div
          className={clsx(styles.displayDate, styles.preview)}
          onClick={() => (canEdit ? setShouldShowEditView(true) : undefined)}
        >
          <div className={styles.date}>
            <span>
              {localValue?.date
                ? formatDateForDisplay(getDate(localValue.date))
                : null}
            </span>
          </div>
        </div>
      ) : (
        <div
          className={clsx(
            styles.displayDate,
            shouldShowEditView && styles.hidden
          )}
        >
          <div className={styles.date}>
            <span>
              {localValue?.date
                ? formatDateForDisplay(getDate(localValue.date))
                : null}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

const EditDateValue = ({
  handleDelete,
  handleUpdate,
  info,
  initialValue,
  localValue,
  setLocalValue,
  setShouldShowEditView,
}: StandardEditCellValue<DateFieldInstance | null>) => {
  const localValueRef = useRef<DateFieldInstance | null>(localValue);
  const setLocalValueRef = (value: string | null) => {
    const updatedValue =
      value === null
        ? null
        : localValueRef.current
          ? { ...localValueRef.current, date: value }
          : { date: value };

    setLocalValue(updatedValue);
    localValueRef.current = updatedValue;
  };

  const persist = () => {
    // Stop update if value is same as initial value
    if (isEqual(initialValue, localValueRef.current)) {
      return;
    }

    const value = localValueRef.current?.date ? localValueRef.current : null;

    // Optimistic update
    info.table.options.meta?.updateData?.(info.row.index, info.column.id, [
      localValueRef.current,
    ]);

    if (!value) {
      handleDelete?.({
        propertyId: info.column.id,
        rowIndex: info.row.index,
      });

      return;
    }

    handleUpdate?.({
      propertyId: info.column.id,
      rowIndex: info.row.index,
      value: [value],
    });
  };

  const handleChange = (date?: Date) => {
    if (!date) {
      setLocalValueRef(null);
      return;
    }

    setLocalValueRef(formatDateForPersisting(date));
  };

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: () => {
      persist();
      setShouldShowEditView(false);
    },
  });

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      persist();
      setShouldShowEditView(false);
      return true;
    }

    if (ev.key === 'Escape') {
      persist();
      setShouldShowEditView(false);
    }
  };

  return (
    <div className={styles.dateEdit} ref={containerRef}>
      <div className={styles.datepickerWrapper}>
        <DatePicker
          autoComplete="off"
          autoFocus
          dateFormat="dd-MM-yyyy"
          isClearable
          onKeyDown={handleKeyDown}
          onValueChange={(date) => handleChange(date)}
          selected={
            localValueRef.current
              ? getDate(localValueRef.current.date)
              : undefined
          }
          showTimeSelect={false}
        />
      </div>
    </div>
  );
};
