import chromeIntegration from '@assets/img/chrome-integration.png';
import chromeExtension from '@assets/img/chrome-extension.svg';
import chromeSpaceduckIcon from '@assets/img/chrome-spaceduck-icon.svg';
import { ButtonLink } from '@ui/Button';
import styles from './BrowserLoginRedirect.module.scss';
import { Link } from 'react-router-dom';

export default function BrowserLoginRedirect() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={chromeIntegration} alt="Chrome Is Connected" />
        <h1 className="title1">You're logged in!</h1>
        <p className="body4">
          Start using the extension to capture the internet effortlessly. You
          can safely close this window now.
        </p>
        <ButtonLink
          to="https://www.spaceduck.com/browser-extension"
          variant="primary"
          className={styles.fullButton}
        >
          Learn how to use the extension
        </ButtonLink>
        <div className={styles.footer}>
          <Link to="/">Go to Spaceduck</Link>
          <span>⸱</span>
          <Link to="mailto:support@spaceduck.com">Get help</Link>
        </div>
      </div>
      <div className={styles.floatingHint}>
        <div className={styles.text}>
          Click the
          <img src={chromeExtension} alt="chrome extension" />
          icon and be sure to pin the Spaceduck
          <img src={chromeSpaceduckIcon} alt="spaceduck icon" />
          icon for easy access
        </div>
      </div>
    </div>
  );
}
