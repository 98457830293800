import { MediaGroupCategoryDetail } from '@spaceduck/api';

import Checkbox from '@ui/Checkbox';
import type { TableColumnMeta } from '@/types/Category';
import { TableColumnHeaderFirst } from './TableColumnHeader';
import { EditAliasFormData } from '../Forms';
import styles from './FirstColumnTableHeader.module.scss';

export default function FirstColumnTableHeader({
  canEdit,
  category,
  categoryProperties,
  editPropertyAlias,
  handleToggleAll,
  selectAllRows,
}: {
  canEdit: boolean;
  category: MediaGroupCategoryDetail;
  categoryProperties: TableColumnMeta[];
  editPropertyAlias: (data: EditAliasFormData) => Promise<void>;
  handleToggleAll: () => void;
  selectAllRows: boolean;
}) {
  return (
    <div className={styles.sharedTh}>
      {canEdit && (
        <Checkbox
          className={styles.checkbox}
          defaultChecked={selectAllRows}
          onChange={handleToggleAll}
        />
      )}
      <TableColumnHeaderFirst
        category={category}
        categoryProperties={categoryProperties}
        handleEdit={(data) => editPropertyAlias(data)}
        id={'_id'}
      />
    </div>
  );
}
