import clsx from 'clsx';

import styles from './Button.module.scss';

export default function Button(
  props: { isActive?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>
) {
  const { isActive, type = 'button', ...buttonProps } = props;

  return (
    <button
      {...buttonProps}
      type={type}
      className={clsx(styles.button, isActive && styles.active)}
    ></button>
  );
}
