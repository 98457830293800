import { MediaGroupSearchFilterProperties } from '@spaceduck/api';
import { IconProps } from '@spaceduck/icons';
import { Icon16, Icon24 } from '@spaceduck/icons';

const {
  ProjectCategory: ProjectCategory16,
  ColorPalette: ColorPalette16,
  Project: Project16,
  PrivateProject: PrivateProject16,
  Text: Text16,
  Title: Title16,
  Hashtag: Hashtag16,
  Kind: Kind16,
  Links: Links16,
  Status: Status16,
  CalendarDate: CalendarDate16,
  Profile: Profile16,
} = Icon16;

const {
  ColourSwatch: ColourSwatch24,
  Title: Title24,
  Hashtag: Hashtag24,
  Link: Link24,
  ProjectCategory: ProjectCategory24,
  StatusFilter: StatusFilter24,
  Project: Project24,
  PrivateProject: PrivateProject24,
} = Icon24;

const ProjectSuggestionIcon24 = ({
  isPrivate,
  ...props
}: { isPrivate: boolean } & IconProps) => {
  if (isPrivate) {
    return <PrivateProject24 {...props} />;
  }
  return <Project24 {...props} />;
};
const ProjectSuggestionIcon16 = ({
  isPrivate,
  ...props
}: { isPrivate: boolean } & IconProps) => {
  if (isPrivate) {
    return <PrivateProject16 {...props} />;
  }
  return <Project16 {...props} />;
};

type FilterIconProps = IconProps &
  (
    | {
        filter: 'project';
        variant: 'suggestion' | 'filter';
        isPrivate: boolean;
      }
    | {
        filter: 'date';
        variant: 'filter';
      }
    | {
        filter: Exclude<MediaGroupSearchFilterProperties, 'project'>;
        variant: 'filter' | 'suggestion';
      }
  );

export const FilterIcon = (props: FilterIconProps) => {
  if (props.variant === 'filter') {
    switch (props.filter) {
      case 'color':
        return <ColorPalette16 {...props} />;
      case 'project':
        return <ProjectSuggestionIcon16 {...props} />;
      case 'tag':
        return <Hashtag16 {...props} />;
      case 'text':
        return <Text16 {...props} />;
      case 'title':
        return <Title16 {...props} />;
      case 'category':
        return <ProjectCategory16 {...props} />;
      case 'contentType':
        return <Kind16 {...props} />;
      case 'source':
        return <Links16 {...props} />;
      case 'status':
        return <Status16 {...props} />;
      case 'date':
        return <CalendarDate16 {...props} />;
      case 'author':
        return <Profile16 {...props} />;
      default:
        return <></>;
    }
  }
  if (props.variant === 'suggestion') {
    switch (props.filter) {
      case 'color':
        return <ColourSwatch24 {...props} />;
      case 'project':
        return <ProjectSuggestionIcon24 {...props} />;
      case 'tag':
        return <Hashtag24 {...props} />;
      case 'title':
        return <Title24 {...props} />;
      case 'category':
        return <ProjectCategory24 {...props} />;
      case 'contentType':
        return <Kind16 {...props} />;
      case 'source':
        return <Link24 {...props} />;
      case 'status':
        return <StatusFilter24 {...props} />;
      // Text suggestions don't exist
      default:
        return <></>;
    }
  }
};
