import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { workspaceKeys } from './workspace';
import { userKeys } from './users';
import {
  addWebLinkToProject,
  createProject,
  deleteProject,
  getProject,
  joinProject,
  patchProject,
  patchProjectMembership,
  removeUserFromProject,
  restoreProject,
} from '@spaceduck/api';
import { QueryDisabledError } from './errors';

export const projectKeys = {
  all: ['project'] as const,
  one: (projectId: string | null) => [...projectKeys.all, projectId] as const,
  create: () => [...projectKeys.all, 'create'] as const,
};

export const usePatchProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchProject,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      if (data.workspaceId) {
        queryClient.invalidateQueries({
          queryKey: workspaceKeys.one(data.workspaceId),
        });
        queryClient.invalidateQueries({
          queryKey: projectKeys.one(variables.id),
        });
      }
    },
  });
  return mutation;
};

export const useProject = (projectId: string | null) => {
  const enabled = !!projectId;
  const query = useQuery({
    enabled,
    queryKey: projectKeys.one(projectId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getProject(projectId);
    },
  });
  return query;
};

export const useCreateProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: projectKeys.create(),
    mutationFn: createProject,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({
        queryKey: workspaceKeys.one(variables.workspaceId),
      });
    },
  });

  return mutation;
};

export const useDeleteProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
      queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });
  return mutation;
};

export const useRestoreProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: restoreProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
      queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });
  return mutation;
};

export const useAddWebLinkToProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: addWebLinkToProject,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.one(variables.projectId),
      });
    },
  });
  return mutation;
};

export const usePatchProjectMembership = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchProjectMembership,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
      queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });
  return mutation;
};

export const useRemoveUserFromProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: removeUserFromProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
      queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });
  return mutation;
};

export const useJoinProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: joinProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
      queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });
  return mutation;
};
