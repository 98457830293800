import { DocumentEmbedDTO } from '@spaceduck/api';
import { create } from 'zustand';

type NotesStore = {
  isNotesSaving: boolean;
  setIsNotesSaving: (isNotesSaving: boolean) => void;
  isQuickViewNotesSaving: boolean;
  setIsQuickViewNotesSaving: (isQuickViewNotesSaving: boolean) => void;
  documentEmbeds: DocumentEmbedDTO[];
  setDocumentEmbeds: (documentEmbeds: DocumentEmbedDTO[]) => void;
};

export const useNotesStore = create<NotesStore>()((set) => ({
  isNotesSaving: false,
  setIsNotesSaving: (isNotesSaving: boolean) => set(() => ({ isNotesSaving })),
  isQuickViewNotesSaving: false,
  setIsQuickViewNotesSaving: (isQuickViewNotesSaving: boolean) =>
    set(() => ({ isQuickViewNotesSaving })),
  documentEmbeds: [],
  setDocumentEmbeds: (documentEmbeds: DocumentEmbedDTO[]) =>
    set(() => ({ documentEmbeds })),
}));
