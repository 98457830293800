import { useEffect } from 'react';
import {
  Content as DialogContent,
  Portal,
  Root as DialogRoot,
} from '@radix-ui/react-dialog';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApiError, errorSchema } from '@spaceduck/api';

import { useListMediaGroups, useMediaGroupDetail } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import Head from '@components/Head';
import { useModalManager } from '@context/ModalManagerContext';
import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useNotesEditor } from '@hooks/useNotesEditor';
import { useSearch } from '@hooks/useSearch';
import useWorkspaceId from '@hooks/useWorkspaceId';
import {
  type ContentView,
  useDetailsModalStore,
} from '@stores/useDetailsModalStore';
import Spinner from '@ui/Spinner';
import DetailsModalHeader from './DetailsModalHeader';
import DetailsModalBody from './DetailsModalBody';
import DetailsModalPlaceholder from './placeholder/DetailsModalPlaceholder';
import { knownErrors } from '@/const';
import { useUpgradePlanModal } from '@components/UpgradePlanModal';
import { DetailsModelTab } from '@/types/MediaGroup';
import { getCloseTo } from '@utils/mediaGroup';
import styles from './DetailsModal.module.scss';

const RETRIES = 5;

export default function DetailsModal({
  mediaGroupId,
  activeTab,
}: {
  mediaGroupId: string;
  activeTab: DetailsModelTab;
}) {
  // TODO: `projectId` will not be present anymore.
  const projectId = useParams<{ projectId?: string }>().projectId;
  const location = useLocation();

  const {
    currentMediaGroupPageCount,
    isCommenting,
    isInLightboxView,
    isInlineCommenting,
    newCommentPosition,
    setAvailableContentViews,
    setContentView,
    setIsCommenting,
    setIsInLightboxView,
    setUserCapabilities,
    topRef,
    setQuickViewMediaGroupId,
    swapQuickViewMediaGroupId,
    setSwapQuickViewMediaGroupId,
  } = useDetailsModalStore((store) => ({
    currentMediaGroupPageCount: store.currentMediaGroupPageCount,
    isCommenting: store.isCommenting,
    isInLightboxView: store.isInLightboxView,
    isInlineCommenting: store.isInlineCommenting,
    newCommentPosition: store.newCommentPosition,
    resetDrawer: store.resetDrawer,
    setAvailableContentViews: store.setAvailableContentViews,
    setContentView: store.setContentView,
    setIsCommenting: store.setIsCommenting,
    setIsInLightboxView: store.setIsInLightboxView,
    setUserCapabilities: store.setUserCapabilities,
    topRef: store.topRef,
    setQuickViewMediaGroupId: store.setQuickViewMediaGroupId,
    swapQuickViewMediaGroupId: store.swapQuickViewMediaGroupId,
    setSwapQuickViewMediaGroupId: store.setSwapQuickViewMediaGroupId,
  }));
  const workspaceId = useWorkspaceId();
  const { debouncedSearchValue, mediaGroupFilters, excludeProjectLibraries } =
    useSearch();
  const {
    data: featureMediaGroupsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    enabled,
  } = useListMediaGroups(workspaceId, {
    query: debouncedSearchValue,
    excludeProjectLibraries,
    ...mediaGroupFilters,
    project: projectId ? [projectId] : undefined,
  });

  const { data, isError, isPending, failureCount, failureReason, ...rest } =
    useMediaGroupDetail(mediaGroupId, { retry: RETRIES });

  const userCanEdit = isCapable(
    'edit',
    data?.mediaGroup?.userCapabilities
  ).capable;

  const {
    editor,
    handleImageUploadInputChange,
    imageUploadInputRef,
    tableOfContentItems,
  } = useNotesEditor(data?.mediaGroup, {
    editable: () => userCanEdit ?? false,
  });

  const error = rest.error as ApiError<Zod.infer<typeof errorSchema>> | null;
  const showUpgradeModal = error?.payload?.errorKind === 'forbidden-by-plan';

  const backgroundLocation = useBackgroundLocation();
  const { modals } = useModalManager();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(backgroundLocation || getCloseTo(data?.mediaGroup));
    setQuickViewMediaGroupId(null);
  };

  const { open: openUpgradePlanModal, close: closeUpgradeModal } =
    useUpgradePlanModal({
      title: 'Browsing limit exceeded',
      header: 'Plan management',
      message: 'Cannot browse this entry, please upgrade your plan',
      closeModal: handleClose,
    });

  useEffect(() => {
    if (swapQuickViewMediaGroupId) {
      setQuickViewMediaGroupId(swapQuickViewMediaGroupId);
      setSwapQuickViewMediaGroupId(null);
    }
  }, [mediaGroupId, swapQuickViewMediaGroupId]);

  useEffect(() => {
    if (activeTab !== 'comment' && isCommenting) {
      setIsCommenting(false);
    }
  }, [activeTab, isCommenting, setIsCommenting]);

  useHotkeys(
    'Escape',
    handleClose,
    {
      enabled:
        !isCommenting &&
        !newCommentPosition &&
        !modals.length &&
        activeTab !== 'info' &&
        !isInLightboxView &&
        !isInlineCommenting,
    },
    [
      isCommenting,
      newCommentPosition,
      modals.length,
      activeTab,
      isInLightboxView,
      isInlineCommenting,
    ]
  );

  useEffect(() => {
    if (
      enabled &&
      hasNextPage &&
      !isFetchingNextPage &&
      (featureMediaGroupsData?.pages?.length ?? 0) < currentMediaGroupPageCount
    ) {
      fetchNextPage();
    }
  }, [enabled, featureMediaGroupsData?.pages, currentMediaGroupPageCount]);

  useEffect(() => {
    setIsCommenting(false);
    setUserCapabilities(data?.userCapabilities ?? []);
    if (data?.mediaGroup.kind === 'document') {
      setContentView('notes');
      return;
    }
    if (data?.mediaGroup.kind === 'bookmark') {
      setContentView('content');
      return;
    }
    if (data?.mediaGroup.kind === 'extract') {
      setContentView('content');
      return;
    }
    if (!data?.mediaGroup?.media?.length) {
      setAvailableContentViews([]);
      setContentView(undefined);
      return;
    }
    const includesVideo = !!data.mediaGroup.media.find(({ mediaType }) =>
      mediaType.startsWith('video/')
    );
    setAvailableContentViews([
      'content',
      'notes',
      ...(includesVideo ? ['transcript' as ContentView] : []),
    ]);
    setContentView('content');
  }, [data]);

  useEffect(() => {
    setIsInLightboxView(false);
  }, [location]);

  useEffect(() => {
    if (error?.payload?.errorKind === 'forbidden-by-plan') {
      openUpgradePlanModal();
      return closeUpgradeModal;
    }
  }, [error]);

  useEffect(() => {
    // Mimick effects of Overlay
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.pointerEvents = 'auto';
    };
  }, []);

  if (
    (isError && !showUpgradeModal && error?.message === 'Not found') ||
    failureReason?.message === 'Not found'
  ) {
    throw new Error(knownErrors.mediaGroupError, { cause: rest.error });
  }

  return (
    <>
      <Head title={data?.mediaGroup.label ?? 'Browse'} />
      <DialogRoot open={!showUpgradeModal}>
        <Portal>
          {/* Removed Overlay as it breaks scrolling in menus.
        TODO: Replace with official solution when available - https://github.com/radix-ui/primitives/issues/1159 */}
          {/* <Overlay className={styles.overlay} /> */}
          <div className={styles.overlay}></div>
          <DialogContent className={styles.dialogContent} ref={topRef}>
            {!data && (isError || (isPending && failureCount > 0)) && (
              <DetailsModalPlaceholder
                state="error"
                errorMessage={
                  failureCount < RETRIES
                    ? "Something went wrong. Let's try that again..."
                    : undefined
                }
                errorAction={
                  failureCount < RETRIES ? (
                    <div className={styles.spinner}>
                      <Spinner />
                    </div>
                  ) : undefined
                }
              />
            )}
            {data && (
              <>
                <DetailsModalHeader
                  mediaGroup={data.mediaGroup}
                  isNote={data.mediaGroup.kind === 'document'}
                />
                <DetailsModalBody
                  activeTab={activeTab}
                  editor={editor}
                  imageUploadInputRef={imageUploadInputRef}
                  handleImageUploadInputChange={handleImageUploadInputChange}
                  mediaGroupId={mediaGroupId}
                  tableOfContentItems={tableOfContentItems}
                />
                <div>
                  <div id="tippyHoverMenuRoot"></div>
                  <div id="tiptapSelectionMenuRoot"></div>
                  <div id="tippySlashMenuRoot"></div>
                  <div id="tippyTableCellMenuRoot"></div>
                </div>
              </>
            )}
          </DialogContent>
        </Portal>
      </DialogRoot>
    </>
  );
}
