import { z } from 'zod';
import { useState } from 'react';
import { patchWorkspaceMemberSchema } from '@spaceduck/api';

import { catchApiErrorIntoToast } from '@api/util';
import { useUpgradePlanModal } from '@components/UpgradePlanModal';
import { useConfirmModal } from '@ui/ConfirmModal';
import createToast from '@utils/createToast';
import { usePoll } from './usePoll';

export const useChangeSeats = <T>(
  fn: (params: T) => Promise<z.infer<typeof patchWorkspaceMemberSchema>>,
  pollFn: (params: T) => Promise<boolean>,
  onSuccess: (params: T) => void
) => {
  const { open: openConfirmRecoverPaymentModal } = useConfirmModal<string>({
    title: 'Payment failed',
    subtitle:
      'It looks like your payment did not succeed, go to manage account and update or double check your payment information.',
    confirmText: 'Manage account',
    onConfirm: async (stripe_confirm_url?: string) => {
      if (stripe_confirm_url) {
        window.location.href = stripe_confirm_url;
      }
    },
    confirmVariant: 'primary',
  });

  const { open: openUpgradeModal } = useUpgradePlanModal({
    header: 'Seat management',
    title: 'Contributor seats',
    message:
      "You've reached the maximum number of contributors allowed on your plan. Upgrade to Pro or Team for unlimited contributors.",
  });
  const [pollingComplete, setPollingComplete] = useState<boolean>(true);
  const [fnComplete, setFnComplete] = useState(true);
  const poll = usePoll(1000, !pollingComplete, async (params?: T) => {
    if (!params) {
      return;
    }
    const pollingComplete = await pollFn(params);
    setPollingComplete(pollingComplete);
    if (pollingComplete) {
      onSuccess(params);
    }
  });

  return {
    do: catchApiErrorIntoToast(async (params: T) => {
      setFnComplete(false);

      let response:
        | {
            status: 'success' | 'pending' | 'failed';
            reason: 'subscription_does_not_exist' | 'invoice_pending' | null;
            invoice: string | null;
          }
        | undefined = undefined;

      try {
        response = await fn(params);
        if (response.status === 'success') {
          onSuccess(params);
        }
        if (response.reason === 'invoice_pending') {
          if (response.status === 'failed' && response.invoice) {
            openConfirmRecoverPaymentModal(response.invoice);
          } else if (response.status === 'pending') {
            poll(params);
          }
        } else if (response.reason === 'subscription_does_not_exist') {
          openUpgradeModal();
        }
      } catch (ex: any) {
        if (
          ex?.message ===
          'Requested action would result in workspace having no owner'
        ) {
          createToast({
            titleText: 'Cannot change role',
            bodyText: 'Each organization must have at least one owner.',
            iconVariant: 'warning',
          });
        } else {
          throw ex;
        }
      }

      setFnComplete(true);
      return response;
    }),
    complete: pollingComplete && fnComplete,
  };
};
