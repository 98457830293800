import clsx from 'clsx';

import UISpinner from '@ui/Spinner';
import styles from './Spinner.module.scss';

export default function Spinner({
  align = 'center',
  className,
}: {
  align?: 'center' | 'end' | 'start';
  className?: string;
}) {
  return (
    <div className={clsx(styles.container, styles[align], className)}>
      <UISpinner />
    </div>
  );
}
