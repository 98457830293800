import { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import {
  type MediaGroupFiltersSchema,
  mediaGroupSearchFilterLabels,
  SearchSuggestionDTO,
} from '@spaceduck/api';

import { reportSearchEvent } from '@api/search';
import { useSearchStore } from '@stores/useSearchStore';
import { trackEvent } from '@/lib/analytics/google';

type DateSearchQuery = {
  filter: 'date';
  maxDatetime: Date;
  minDatetime: Date;
};

export type SearchQuery =
  | { exclude: boolean; fromSuggestion: SearchSuggestionDTO }
  | string
  | DateSearchQuery;
export const isDateSearchQuery = (
  value: SearchQuery
): value is DateSearchQuery => {
  return (
    typeof value !== 'string' && 'filter' in value && value.filter === 'date'
  );
};
export const isFromSuggestionSearchQuery = (
  value: SearchQuery
): value is { exclude: boolean; fromSuggestion: SearchSuggestionDTO } => {
  return typeof value !== 'string' && 'fromSuggestion' in value;
};
const searchQueryToStringEditable = (searchQuery: SearchQuery): string => {
  if (typeof searchQuery === 'string') return searchQuery;
  if (isDateSearchQuery(searchQuery)) return 'date:';
  return `${searchQuery.exclude ? '-' : ''}${mediaGroupSearchFilterLabels[searchQuery.fromSuggestion.filter]}:${
    searchQuery.fromSuggestion.label
  }`;
};

const filterSearchQueriesToFilters = (
  searchQueries: Array<SearchQuery>
): MediaGroupFiltersSchema => {
  const filters = searchQueries.reduce(
    (filters: MediaGroupFiltersSchema, current) => {
      if (typeof current === 'string') {
        return filters;
      }
      if (isDateSearchQuery(current)) {
        filters['maxDatetime'] = current.maxDatetime;
        filters['minDatetime'] = current.minDatetime;
        return filters;
      }

      if (current.fromSuggestion.filter === 'status') {
        const filterKey =
          `${current.fromSuggestion.filter}${current.exclude ? 'Not' : ''}` as const;
        const currentFilter = filters[filterKey] || [];
        currentFilter.push(current.fromSuggestion.id);
        filters[filterKey] = currentFilter;
        return filters;
      }

      const filterKey =
        `${current.fromSuggestion.filter}${current.exclude ? 'Not' : ''}` as const;
      const currentFilter = filters[filterKey] || [];

      if (
        current.fromSuggestion.filter === 'tag' ||
        current.fromSuggestion.filter === 'title'
      ) {
        currentFilter.push(current.fromSuggestion.label);
        filters[filterKey] = currentFilter;
      } else {
        currentFilter.push(current.fromSuggestion.id);
        filters[filterKey] = currentFilter;
      }

      return filters;
    },
    {}
  );

  return filters;
};

export const useSearch = () => {
  const {
    attributionToken,
    isLoading,
    searchElementRef,
    searchInputValue,
    searchQueries,
    debouncedSearchValue,
    setAttributionToken,
    setIsLoading,
    setSearchElementRef,
    setSearchInputValue,
    setSearchQueries,
    setShowAltPlaceholder,
    setDebouncedSearchValue,
    showAltPlaceholder,
    setMediaGroupFilters,
    mediaGroupFilters,
    excludeProjectLibraries,
    setExcludeProjectLibraries,
  } = useSearchStore();

  const removeSearchQuery = (idx: number) => {
    setSearchQueries(searchQueries.filter((_, _idx) => idx !== _idx));
  };

  const handleKeyDown = (ev: KeyboardEvent) => {
    const currentTarget = ev?.currentTarget as HTMLInputElement;
    if (ev.key === 'Backspace') {
      if (!currentTarget?.value.length && searchQueries.length) {
        ev.preventDefault();
        const lastListItem = searchQueries.at(-1);
        setSearchQueries(searchQueries.slice(0, -1));
        setSearchInputValue(searchQueryToStringEditable(lastListItem!));
      }
    } else if (ev.key === 'Enter') {
      ev.preventDefault();
      if (searchInputValue) {
        setSearchQueries([...searchQueries, searchInputValue.trim()]);
        setSearchInputValue('');
      }
    }
  };

  const clearSearch = () => {
    setSearchQueries([]);
    setSearchInputValue('');
  };

  const resetSearch = () => {
    clearSearch();
    setDebouncedSearchValue(undefined);
    setExcludeProjectLibraries(false);
  };

  const handleFocus = () => {
    if (!(searchQueries.length && searchInputValue)) {
      setShowAltPlaceholder(true);
    }
  };

  const handleBlur = () => {
    setShowAltPlaceholder(false);
  };

  const debouncedSearch = useCallback(
    debounce(async (value: string) => {
      setDebouncedSearchValue(value);
      if (value) {
        trackEvent('search', { search_term: value });
      }
    }, 150),
    []
  );

  const handleInputUpdate = useCallback(
    async (value: string) => {
      setSearchInputValue(value);
      const searchPayload = [
        ...searchQueries.filter((q): q is string => typeof q === 'string'),
      ];

      if (value) searchPayload.push(value);
      debouncedSearch(searchPayload.join(' '));
    },
    [searchQueries]
  );

  useEffect(() => {
    const filters = filterSearchQueriesToFilters(searchQueries);
    setMediaGroupFilters(filters);
  }, [searchQueries.length]);

  const updateSearchInput = async (newSearchInputValue: string) => {
    if (searchElementRef.current) {
      searchElementRef.current.value = newSearchInputValue;
    }
    setSearchQueries([]);
    handleInputUpdate(newSearchInputValue);
  };

  const handleSearchClick = useCallback(
    (mediaGroupId: string) => {
      if (attributionToken) {
        reportSearchEvent(attributionToken, mediaGroupId);
      }
      trackEvent('select_content', {
        content_type: 'mediaGroup',
        content_id: mediaGroupId,
      });
    },
    [attributionToken]
  );

  const hasQuery =
    !!debouncedSearchValue ||
    Object.keys(mediaGroupFilters).length > 0 ||
    searchQueries.length > 0;

  return {
    hasQuery,
    isLoading,
    setIsLoading,
    debouncedSearchValue,
    setAttributionToken,
    handleSearchClick,
    handleBlur,
    handleFocus,
    handleKeyDown,
    removeSearchQuery,
    searchElementRef,
    setSearchElementRef,
    searchInputValue,
    setSearchInputValue,
    setSearchQueries,
    searchQueries,
    showAltPlaceholder,
    updateSearchInput,
    mediaGroupFilters,
    excludeProjectLibraries,
    setExcludeProjectLibraries,
    handleInputUpdate,
    clearSearch,
    resetSearch,
  };
};
