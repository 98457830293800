import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import Tippy from '@tippyjs/react';
import { useInView } from 'react-intersection-observer';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import {
  isRelationshipFieldInstance,
  MediaGroupContentType,
  MediaGroupDTO,
  RelationshipFieldInstance,
} from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import { useListMediaGroups } from '@api/mediaGroup';
import { ContentType } from '@components/icons';
import { useCategoryCellSelectionKeyboard } from '@hooks/useCategoryCellSelection';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { css } from '@lib/css';
import Button from '@ui/Button';
import ScrollArea from '@ui/ScrollArea';
import { urlFor } from '@/urls';
import type {
  AvailableTypes,
  StandardCellValue,
  StandardEditCellValue,
  TableColumnMeta,
} from '@/types/Category';
import styles from './Relation.module.scss';

const { Add, Delete } = Icon16;
const { Search } = Icon24;

const RelationEntry = ({
  instance,
}: {
  instance: RelationshipFieldInstance;
}) => {
  const { contentType, label } = instance;
  if (!contentType) {
    return;
  }

  const mediaGroupURL = urlFor('mediaGroup', {
    mediaGroupId: instance.mediaGroupId,
  });

  return (
    <a
      className={styles.relationEntry}
      href={mediaGroupURL}
      onClick={(ev) => ev.stopPropagation()}
      target="_blank"
    >
      <ContentType contentType={contentType} />
      {label}
    </a>
  );
};

export function getInitialValue(value: AvailableTypes) {
  return value?.filter(isRelationshipFieldInstance) ?? [];
}

export default function RelationValue({
  canEdit,
  clearSelectedCell,
  columnData,
  handleDelete,
  handleUpdate,
  info,
  setSelectedCell,
  showPreview,
  value,
}: StandardCellValue & { columnData: TableColumnMeta }) {
  const initialValue = getInitialValue(value);
  const [shouldShowEditView, setShouldShowEditView] = useState(false);
  const [localValue, setLocalValue] = useState(initialValue);

  const { setEnabled } = useCategoryCellSelectionKeyboard({
    setSelectedCell,
    enableOnLoad: false,
    onEnter: () => setShouldShowEditView(true),
    onEscape: () => {
      shouldShowEditView ? setShouldShowEditView(false) : clearSelectedCell();
    },
  });

  useEffect(() => {
    setEnabled(showPreview);
  }, [showPreview]);

  return (
    <>
      {canEdit && shouldShowEditView && (
        <EditRelationValue
          columnData={columnData}
          clearSelectedCell={clearSelectedCell}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
          info={info}
          initialValue={initialValue}
          localValue={localValue}
          setLocalValue={setLocalValue}
          setShouldShowEditView={setShouldShowEditView}
        />
      )}
      {showPreview ? (
        <div
          className={clsx(styles.relations, styles.preview)}
          onClick={canEdit ? () => setShouldShowEditView(true) : undefined}
        >
          <div className={styles.relationsPreview}>
            {localValue
              .filter((entry) => entry.mediaGroupId)
              .map((entry, idx) => (
                <div className={styles.relationsPreviewItem} key={idx}>
                  <RelationEntry instance={entry} />
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div
          className={clsx(
            styles.relations,
            shouldShowEditView && styles.hidden
          )}
        >
          <div className={styles.sizeWrapper}>
            <div className={styles.overflowWrapper}>
              <div className={styles.relationsInner}>
                {localValue
                  .filter((entry) => entry.mediaGroupId)
                  .map((entry, idx) => (
                    <RelationEntry instance={entry} key={idx} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const FILTER_TEXT_DEBOUNCE = 250;

const EditRelationValue = ({
  columnData,
  handleDelete,
  handleUpdate,
  info,
  initialValue,
  localValue,
  setLocalValue,
  setShouldShowEditView,
}: StandardEditCellValue<RelationshipFieldInstance[]> & {
  columnData: TableColumnMeta;
}) => {
  const workspaceId = useWorkspaceId();
  const localValueRef = useRef(localValue);
  const setLocalValueRef = (value: RelationshipFieldInstance[]) => {
    localValueRef.current = value;
    setLocalValue(value);
  };

  const [categoryFilterText, setCategoryFilterText] = useState('');
  const [tempCategoryFilterText, setTempCategoryFilterText] = useState('');

  const debouncedFilterTextChange = useMemo(
    () =>
      setCategoryFilterText
        ? debounce(setCategoryFilterText, FILTER_TEXT_DEBOUNCE)
        : null,
    [setCategoryFilterText]
  );
  const handleCategoryFilterTextChange = (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTempCategoryFilterText(ev.currentTarget.value);
    debouncedFilterTextChange?.(ev.currentTarget.value);
  };
  const { data, enabled, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useListMediaGroups(
      workspaceId,
      {
        category:
          columnData.kind === 'relationship'
            ? [columnData.settings.mediaGroupCategoryId]
            : undefined,
        query: categoryFilterText,
      },
      columnData.kind === 'relationship'
    );
  const availableMediaGroups = data?.pages.flatMap((page) => page.mediaGroups);
  const { ref: loadMoreRef, inView } = useInView();

  useEffect(() => {
    if (enabled && inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [enabled, inView, isFetchingNextPage, hasNextPage]);

  const [linkedItems, linkableItems] = useMemo(() => {
    let mediaGroups = availableMediaGroups;
    if (tempCategoryFilterText) {
      const pattern = new RegExp(tempCategoryFilterText, 'i');
      mediaGroups = availableMediaGroups?.filter((mediaGroup) =>
        pattern.test(mediaGroup.label)
      );
    }

    const linkedItems: MediaGroupDTO[] = [];
    const linkableItems: MediaGroupDTO[] = [];
    const selectedItems = localValueRef.current.map(
      ({ mediaGroupId }) => mediaGroupId
    );

    mediaGroups?.forEach((mediaGroup) => {
      if (selectedItems.includes(mediaGroup.id)) {
        linkedItems.push(mediaGroup);
      } else {
        linkableItems.push(mediaGroup);
      }
    });

    return [linkedItems, linkableItems];
  }, [availableMediaGroups, tempCategoryFilterText, localValueRef.current]);

  const persist = async () => {
    // Stop update if value is same as initial value
    if (isEqual(initialValue, localValueRef.current)) {
      return;
    }

    const value = localValueRef.current
      ? localValueRef.current.filter((entry) => !!entry.mediaGroupId)
      : null;

    // Optimistic update
    info.table.options.meta?.updateData?.(
      info.row.index,
      info.column.id,
      value ?? []
    );

    if (!value) {
      handleDelete?.({
        propertyId: info.column.id,
        rowIndex: info.row.index,
      });
      return;
    }

    await handleUpdate?.({
      propertyId: info.column.id,
      rowIndex: info.row.index,
      value,
    });
  };

  const handleAddEntry = ({
    id,
    label,
    contentType,
  }: {
    id: string;
    label?: string;
    contentType: MediaGroupContentType;
  }) => {
    setLocalValueRef([
      ...localValueRef.current,
      { mediaGroupId: id, label, contentType },
    ]);
  };

  const handleRemoveEntry = (mediaGroupId: string) => {
    setLocalValueRef([
      ...localValueRef.current.filter(
        (entry) => entry.mediaGroupId !== mediaGroupId
      ),
    ]);
  };

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: () => {
      persist();
      setShouldShowEditView(false);
    },
  });

  return (
    <div className={styles.relationsEdit} ref={containerRef}>
      <ScrollArea
        className={clsx(
          styles.relationsScrollArea,
          localValueRef.current.length > 0 && styles.hasEntries
        )}
        orientation="vertical"
        style={css({
          '--width': '100%',
          '--maxHeight': '100%',
        })}
      >
        {localValue.map((mediaGroup, idx) => {
          if (!mediaGroup) return <div key={idx}></div>;

          const { mediaGroupId, contentType, label } = mediaGroup;
          const mediaGroupURL = urlFor('mediaGroup', {
            mediaGroupId,
          });

          return (
            <div className={styles.inputWrapper} key={idx}>
              <div
                className={styles.relation}
                key={idx}
                onClick={() => window.open(mediaGroupURL)}
              >
                {!!contentType && <ContentType contentType={contentType} />}
                <div className={styles.relationLabel}>{label}</div>
              </div>
              <Button
                onClick={() => handleRemoveEntry(mediaGroup.mediaGroupId)}
                variant="icon"
              >
                <Delete />
              </Button>
            </div>
          );
        })}
      </ScrollArea>
      <Tippy
        content={
          <div className={styles.addRelations}>
            <div className={styles.searchWrapper}>
              <Search size={20} />
              <input
                className={styles.searchbar}
                onChange={(ev) => handleCategoryFilterTextChange?.(ev)}
                placeholder={`Search "Relation"...`}
                type="search"
                value={tempCategoryFilterText}
              />
            </div>
            {!!linkedItems.length && (
              <>
                <h3>Linked items</h3>
                <ScrollArea
                  orientation="vertical"
                  style={css({
                    '--width': '100%',
                    '--maxHeight': '180px',
                  })}
                >
                  <ul>
                    {linkedItems.map((mediaGroup) => (
                      <li
                        key={mediaGroup.id}
                        onClick={() => handleRemoveEntry(mediaGroup.id)}
                      >
                        <ContentType contentType={mediaGroup.contentType} />
                        <div>{mediaGroup.label}</div>
                      </li>
                    ))}
                  </ul>
                </ScrollArea>
              </>
            )}
            {!!linkableItems.length && (
              <>
                <h3>Link another item</h3>
                <ScrollArea
                  orientation="vertical"
                  style={css({
                    '--width': '100%',
                    '--maxHeight': '180px',
                  })}
                >
                  <ul className={styles.linkableItems}>
                    {linkableItems.map((mediaGroup) => (
                      <li
                        key={mediaGroup.id}
                        onClick={() =>
                          handleAddEntry({
                            id: mediaGroup.id,
                            label: mediaGroup.label,
                            contentType: mediaGroup.contentType,
                          })
                        }
                      >
                        <ContentType contentType={mediaGroup.contentType} />
                        <div>{mediaGroup.label}</div>
                      </li>
                    ))}
                  </ul>
                  <div
                    ref={loadMoreRef}
                    style={{ width: '100%', height: '10px' }}
                  >
                    {/* Triggers page fetch when in view */}
                  </div>
                </ScrollArea>
              </>
            )}
          </div>
        }
        hideOnClick={false}
        interactive={true}
        placement="bottom-start"
        trigger="click"
      >
        <div>
          <Button
            className={styles.addButton}
            iconBefore={<Add />}
            variant="ghost"
          >
            Add a Relation
          </Button>
        </div>
      </Tippy>
    </div>
  );
};
