import { ReactNode, useEffect } from 'react';

import { useModalManager } from '@context/ModalManagerContext';
import { ButtonLink } from '@ui/Button';
import Dialog from '@ui/Dialog';
import styles from './ForceUpgradeModal.module.scss';
import Badge from './Badge';
import { urlFor } from '@/urls';
import { WorkspaceAvatar } from './UserAvatar';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import { useWorkspace } from '@/api/workspace';
import { useGetSubscriptionInfo } from '@/api/billing';

type ForceUpgradeModalProps = {
  currentViewers: number;
  currentSeats: number;
  maxViewers: number | null;
  maxSeats: number;
  workspaceId: string;
  plan: string;
  icon?: ReactNode;
};

const ForceUpgradeModal = ({
  currentViewers,
  currentSeats,
  maxViewers,
  maxSeats,
  workspaceId,
  closeModal,
  plan,
  icon,
}: ForceUpgradeModalProps & {
  closeModal: () => void;
}) => {
  return (
    <Dialog
      isOpen={true}
      className={styles.modal}
      maxWidth="32.5rem"
      showHeader={false}
      footer={
        <div className={styles.modalFooter}>
          <ButtonLink
            onClick={closeModal}
            variant="primary"
            size="sm"
            to={urlFor('workspaceSettingsPeople', { workspaceId })}
          >
            Manage seats
          </ButtonLink>
        </div>
      }
      padding="md"
    >
      <div className={styles.modalContent}>
        <div className={styles.title}>
          {icon}
          <span>Maximum user limits reached</span>
        </div>
        <ul>
          <li>
            The {plan} plan allows a maximum of {maxSeats} Contributors; you
            currently have {currentSeats}.
          </li>
          <li>
            The {plan} plan allows a maximum of {maxViewers} Viewers; you
            currently have {currentViewers}.
          </li>
        </ul>
        <div className={styles.badges}>
          <Badge color={2} outline={1} size="md">
            {currentSeats} / {maxSeats} Contributors
          </Badge>
          {!!maxViewers && (
            <Badge color={2} outline={1} size="md">
              {currentViewers} / {maxViewers} Viewers
            </Badge>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export function useForceUpgradeModal() {
  const workspaceId = useWorkspaceId();
  const { data: workspaceData } = useWorkspace(workspaceId);
  const { data } = useGetSubscriptionInfo(workspaceId);
  const { openModal, closeModal } = useModalManager();

  useEffect(() => {
    if (
      workspaceData &&
      data &&
      (data.assignedSeats > data.seats ||
        (data.viewers && data.assignedViewers > data.viewers))
    ) {
      openModal({
        component: (
          <ForceUpgradeModal
            closeModal={closeModal}
            {...{
              icon: (
                <WorkspaceAvatar
                  workspaceName={workspaceData.workspace.label}
                  workspaceAvatar={workspaceData.workspace.avatarUrl}
                />
              ),
              currentSeats: data.assignedSeats,
              maxSeats: data.seats,
              plan: data.plan,
              workspaceId: workspaceData.workspace.id,
              currentViewers: data.assignedViewers,
              maxViewers: data.viewers,
            }}
          />
        ),
      });
      return () => {
        closeModal();
      };
    }
  }, [data, workspaceData]);
}
