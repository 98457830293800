import upperFirst from 'lodash/upperFirst';
import clsx from 'clsx';
import { useState } from 'react';
import { getDate, getMonth, getYear } from 'date-fns';
import { Icon16, Icon24 } from '@spaceduck/icons';
import type { ProjectDetailDTO, ProjectStatus } from '@spaceduck/api';

import { usePatchProject } from '@api/project';
import StatusBadge from '@components/StatusBadge';
import DatePicker from '@ui/DatePicker';
import Button from '@ui/Button';
import DropdownMenu, { MenuItem } from '@ui/DropdownMenu';
import {
  projectStatusOptions,
  activeProjectKeys,
  archivedProjectKeys,
} from '@/const';
import createToast from '@utils/createToast';
import ProjectMenuAccordion from './ProjectMenuAccordion';
import styles from './ProjectMenuInfo.module.scss';
import { getDisplayDate } from '@/utils/date';

const { CalendarDate, Close } = Icon16;
const { Check } = Icon24;

type ProjectMenuInfoProps = {
  project: ProjectDetailDTO;
};

function formatDateForServer(date: Date | null) {
  if (!date) return null;
  // Server format YYYY-MM-DD
  const year = getYear(date);
  const month = getMonth(date) + 1;
  const day = getDate(date);

  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

export default function ProjectMenuInfo({ project }: ProjectMenuInfoProps) {
  const [targetDate, setTargetDate] = useState<Date | null>(() => {
    if (!project.targetDate) return null;

    const dateArr = project.targetDate.split('-');

    const [year, month, day] = dateArr;

    if (year === undefined || month === undefined || day === undefined) {
      return null;
    }

    try {
      return new Date(Number(year), Number(month) - 1, Number(day));
    } catch {
      return null;
    }
  });
  const { capabilities, status } = project;
  const editCapability = capabilities?.find(
    ({ capability }) => capability === 'edit'
  );
  const canEdit = editCapability?.capable ?? false;
  const { mutateAsync: patchProject } = usePatchProject();
  const handleStatusUpdate = async (status: ProjectStatus) => {
    await patchProject({
      id: project.id,
      patch: { status },
    });
    createToast({
      bodyText: 'Space successfully updated',
      iconVariant: 'success',
    });
  };

  const handleDateChange = async (date: Date | null) => {
    setTargetDate(date ?? null);
    await patchProject({
      id: project.id,
      patch: {
        targetDate: formatDateForServer(date),
      },
    });
    createToast({
      bodyText: 'Space successfully updated',
      iconVariant: 'success',
    });
  };

  return (
    <ProjectMenuAccordion label="Space info">
      <div className={styles.table}>
        <div className={styles.row}>
          <span>Status</span>
          {canEdit ? (
            <DropdownMenu
              align="start"
              side="left"
              className={styles.statusMenu}
              triggerContent={
                <div>
                  <StatusBadge
                    status={status}
                    className={clsx(styles.statusBadge, styles[status])}
                  />
                </div>
              }
            >
              <div className={styles.menuContent}>
                {[...activeProjectKeys, ...archivedProjectKeys].map((key) => {
                  return (
                    <MenuItem
                      className={styles.statusMenuItem}
                      key={key}
                      onSelect={() => handleStatusUpdate(key)}
                    >
                      <span className={clsx(styles.dot, styles[key])}></span>
                      {projectStatusOptions[key]}
                      {key === status && <Check />}
                    </MenuItem>
                  );
                })}
              </div>
            </DropdownMenu>
          ) : (
            <StatusBadge
              status={status}
              className={styles[`status${upperFirst(status)}`]}
            />
          )}
        </div>
        <div className={styles.row}>
          <span>Target date</span>
          <span>
            <span className={styles.datePickerWrapper}>
              <DatePicker
                customInput={
                  <Button
                    iconBefore={<CalendarDate />}
                    isMuted={!targetDate}
                    size="xs"
                    type="button"
                    variant="outlined"
                  >
                    {targetDate ? getDisplayDate(targetDate) : 'Date'}
                  </Button>
                }
                dateFormatCalendar="YYYY-MM-DD"
                minDate={new Date()}
                selected={targetDate}
                onValueChange={handleDateChange}
                popperPlacement="left-start"
                showFullMonthYearPicker
              />
              {!!targetDate && (
                <Button
                  className={styles.datePickerReset}
                  onClick={() => handleDateChange(null)}
                  isSquare
                  variant="outlined"
                  size="xs"
                >
                  <Close size={12} />
                </Button>
              )}
            </span>
          </span>
        </div>
      </div>
    </ProjectMenuAccordion>
  );
}
