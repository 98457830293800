import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { exists } from '@spaceduck/utils';

import Checkbox from '@ui/Checkbox';
import DropdownMenu, { DropdownMenuProps } from '@ui/DropdownMenu';
import ScrollArea from '@ui/ScrollArea';
import Tag from '@ui/Tag';
import styles from './SearchableDropdown.module.scss';
import { Icon24 } from '@spaceduck/icons';
import { css } from '@/lib/css';
const { Add, Down, Search } = Icon24;

export type Option = {
  icon?: React.ReactNode;
  label?: string;
  value: string;
};

type SearchableDropdownProps = {
  addEntryCallback?: (keyword: string) => void;
  addEntryText?: string;
  dropdownMenuProps?: Omit<DropdownMenuProps, 'children' | 'triggerContent'>;
  dropdownProps?: Pick<DropdownProps, 'className' | 'singleSelection'>;
  includeSearchIcon?: boolean;
  initialValue?: string[];
  onValueChange?: (selectedValues: string[]) => void;
  options: Option[];
  placeholderText: string;
  showSearchInput?: boolean;
  triggerContent?: React.ReactNode;
};

export default function SearchableDropdown({
  addEntryCallback,
  addEntryText,
  dropdownMenuProps,
  dropdownProps,
  includeSearchIcon,
  initialValue = [],
  onValueChange,
  options,
  placeholderText,
  showSearchInput,
  triggerContent,
}: SearchableDropdownProps) {
  const [selectedOptions, setSelectedOptions] =
    useState<string[]>(initialValue);
  const [dropdownIsExpanded, setDropdownIsExpanded] = useState(false);
  const valueHasChanged = useRef(false);

  useEffect(() => {
    if (!(selectedOptions === initialValue && !valueHasChanged.current)) {
      valueHasChanged.current = true;
      onValueChange?.(selectedOptions);
    }
  }, [selectedOptions]);

  const addOption = (value: string) => {
    setSelectedOptions((options) => {
      if (!options.includes(value)) {
        return [...options, value];
      }

      return options;
    });
  };

  const removeOption = (value: string) => {
    setSelectedOptions((options) =>
      options.filter((option) => option !== value)
    );
  };

  const selectedIdsAsOptions = selectedOptions
    .map((selectedOption) =>
      options.find((option) => option.value === selectedOption)
    )
    .filter(exists);

  return (
    <div
      className={clsx(
        styles.dropdownWrapper,
        selectedOptions.length > 0 && styles.hasSelectedOptions,
        dropdownIsExpanded && styles.dropdownIsExpanded
      )}
    >
      {!triggerContent && (
        <DisplayedInput
          placeholderText={placeholderText}
          removeOption={removeOption}
          selectedOptions={selectedIdsAsOptions}
          onClick={() => setDropdownIsExpanded((state) => !state)}
        />
      )}
      <DropdownMenu
        {...dropdownMenuProps}
        className={clsx(styles.dropdownMenu, dropdownMenuProps?.className)}
        open={dropdownIsExpanded}
        setOpen={setDropdownIsExpanded}
        triggerContent={
          triggerContent ?? <div className={styles.dropdownTrigger} />
        }
      >
        <Dropdown
          {...dropdownProps}
          addEntryCallback={addEntryCallback}
          addEntryText={addEntryText}
          addOption={addOption}
          includeSearchIcon={includeSearchIcon}
          options={options}
          placeholderText={placeholderText}
          removeOption={removeOption}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          showSearchInput={showSearchInput}
        />
      </DropdownMenu>
    </div>
  );
}

type DisplayedInputProps = {
  placeholderText?: string;
  removeOption: (value: string) => void;
  selectedOptions: Option[];
  onClick: () => void;
};

const DisplayedInput = ({
  placeholderText,
  removeOption,
  selectedOptions,
  onClick,
}: DisplayedInputProps) => {
  const handleRemoveClick = (option: string) => {
    removeOption(option);
  };

  return (
    <div className={styles.resultsBox} onClick={onClick}>
      {selectedOptions.length ? (
        <ul>
          {selectedOptions.map((option, idx) => (
            <li key={idx}>
              <Tag
                onRemoveClick={() => handleRemoveClick(option.value)}
                size="sm"
                variant="tertiary"
              >
                {option.label ?? option.value}
              </Tag>
            </li>
          ))}
        </ul>
      ) : (
        <p className={clsx('body5', styles.placeholder)}>{placeholderText}</p>
      )}
      <span className={styles.arrow}>
        <Down size={20} />
      </span>
    </div>
  );
};

type DropdownProps = {
  addEntryCallback?: (keyword: string) => void;
  addEntryText?: string;
  addOption: (value: string) => void;
  className?: string;
  includeSearchIcon?: boolean;
  name?: string;
  options: Option[];
  placeholderText?: string;
  removeOption: (value: string) => void;
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
  showSearchInput?: boolean;
  singleSelection?: boolean;
};

const Dropdown = ({
  addEntryCallback,
  addEntryText,
  addOption: addOptionByValue,
  className,
  includeSearchIcon = false,
  name,
  options,
  placeholderText,
  removeOption,
  selectedOptions,
  setSelectedOptions,
  showSearchInput = true,
  singleSelection = false,
}: DropdownProps) => {
  const [keyword, setKeyword] = useState<string>('');

  const handleSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(ev.currentTarget.value);
  };

  const handleCheckboxChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = ev.currentTarget;

    if (singleSelection) {
      if (selectedOptions?.[0] !== value) {
        setSelectedOptions([value]);
      }
    } else {
      if (checked) {
        addOptionByValue(value);
      } else {
        removeOption(value);
      }
    }
  };

  const results = (
    keyword
      ? options.filter(
          ({ label }) => label && new RegExp(keyword, 'i').test(label)
        )
      : options
  ).map(({ value, icon, label }, idx) => {
    return (
      <Checkbox
        checked={selectedOptions.includes(value)}
        className={styles.checkboxItem}
        icon={icon}
        key={idx}
        name={name}
        onChange={handleCheckboxChange}
        singleSelection={singleSelection}
        value={value}
      >
        {label ?? value}
      </Checkbox>
    );
  });

  return (
    <div className={styles.searchBox}>
      {showSearchInput && (
        <div
          className={clsx(
            styles.searchBar,
            includeSearchIcon && styles.hasSearchIcon
          )}
        >
          {!!includeSearchIcon && (
            <span>
              <Search size={20} />
            </span>
          )}
          <input
            onChange={handleSearchChange}
            placeholder={placeholderText ?? 'Search'}
            type="search"
            value={keyword}
          />
        </div>
      )}
      {results.length > 0 ? (
        <ScrollArea
          style={css({
            '--width': '100%',
            '--maxHeight':
              'calc(var(--radix-popper-available-height) - var(--size-20))',
          })}
          type="auto"
        >
          <div className={clsx(styles.results, className)}>{results}</div>
        </ScrollArea>
      ) : addEntryCallback ? (
        <CreateNewEntryButton
          onClick={() => addEntryCallback(keyword)}
          searchText={keyword}
          text={addEntryText ?? 'Add'}
        />
      ) : (
        <div className={styles.noResults}>No results found</div>
      )}
    </div>
  );
};

const CreateNewEntryButton = ({
  onClick,
  searchText,
  text,
}: {
  onClick: (searchText: string) => void;
  searchText: string;
  text: string;
}) => {
  return (
    <div
      className={styles.createEntryButton}
      onClick={() => onClick(searchText)}
    >
      <Add size={20} />
      <div className={styles.addText}>
        {text} <span>"{searchText}"</span>
      </div>
    </div>
  );
};
