import { useCallback } from 'react';
import clsx from 'clsx';
import { Icon16 } from '@spaceduck/icons';

import { useMediaGroupDetail } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import { useNavigateWithState } from '@hooks/useNavigateWithState';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Tabs, { Tab } from '@ui/Tabs';
import { urlFor } from '@/urls';
import { DetailsModelTab } from '@/types/MediaGroup';
import Comments from './sidebar/Comments';
import Info from './sidebar/Info';
import BrowseRepository from './sidebar/BrowseRepository';
import styles from './DetailsModalSidebar.module.scss';
import { asMilliseconds } from '@spaceduck/utils';

const {
  Comment: CommentIcon,
  Info: InfoIcon,
  RepositoryBrowse: RepositoryBrowseIcon,
  TableOfContents: TableOfContentsIcon,
} = Icon16;

const getUrl = (activeTab: DetailsModelTab, mediaGroupId: string) => {
  switch (activeTab) {
    case 'browseRepository':
      return urlFor('mediaGroupBrowseRepository', { mediaGroupId });
    case 'comment':
      return urlFor('mediaGroupComments', { mediaGroupId });
    case 'tableOfContent':
      return urlFor('mediaGroupTableOfContent', { mediaGroupId });
    case 'activity':
    case 'references':
    case 'info':
    default:
      return urlFor('mediaGroup', { mediaGroupId });
  }
};

export default function DetailsModalSidebar({
  mediaGroupId,
  activeTab,
  enlarged,
}: {
  mediaGroupId: string;
  activeTab: DetailsModelTab;
  enlarged: boolean;
}) {
  const { drawerIsOpen, openDrawer, toggleDrawer } = useDetailsModalStore(
    (store) => ({
      drawerIsOpen: store.drawerIsOpen,
      openDrawer: store.openDrawer,
      toggleDrawer: store.toggleDrawer,
    })
  );
  const { data, isLoading } = useMediaGroupDetail(mediaGroupId, {
    refetchInterval: asMilliseconds({ seconds: 3 }),
  });
  const navigateWithState = useNavigateWithState();
  const setActiveTab = (activeTab: DetailsModelTab) => {
    const url = getUrl(activeTab, mediaGroupId);
    navigateWithState(url);
  };

  if (!data) return null; // TODO: add error state

  const { mediaGroup, userCapabilities } = data;

  const makeTabs = useCallback(() => {
    const tabs: Tab[] = [
      {
        isActive: true,
        icon: <InfoIcon />,
        content: (
          <Info
            mediaGroup={mediaGroup}
            userCanEdit={isCapable('edit', userCapabilities).capable}
          />
        ),
        value: 'info',
      },
    ];

    if (isCapable('comment', userCapabilities).capable) {
      tabs.push({
        icon: <CommentIcon />,
        content: (
          <Comments isLoading={isLoading} mediaGroupId={mediaGroup.id} />
        ),
        isActive: false,
        value: 'comment',
      });
    }

    tabs.push({
      icon: <RepositoryBrowseIcon />,
      content: (
        <BrowseRepository isLoading={isLoading} mediaGroupId={mediaGroup.id} />
      ),
      isActive: false,
      value: 'browseRepository',
    });

    if (mediaGroup.kind === 'document') {
      tabs.push({
        icon: <TableOfContentsIcon />,
        content: <div id="detailsModalSidebarTableOfContents"></div>,
        isActive: false,
        value: 'tableOfContent',
      });
    }

    return tabs;
  }, [mediaGroup]);

  return (
    <div
      className={clsx(styles.sidebar, enlarged && styles.enlarged)}
      data-state={drawerIsOpen ? 'open' : 'closed'}
    >
      <div className={styles.tabContainer}>
        <Tabs
          activeTab={activeTab}
          asTriggerButtons
          className={styles.tabs}
          contentClassName={styles.tabContent}
          onClick={(value?: string) => {
            if (activeTab === value) {
              toggleDrawer();
            } else {
              if (!drawerIsOpen) {
                openDrawer();
              }
              setActiveTab(value as DetailsModelTab);
            }
          }}
          orientation="vertical"
          tabs={makeTabs()}
          tabButtonProps={{ size: 'xs', variant: 'ghost' }}
          tabWrapperProps={{ className: styles.tabWrapper, variant: 'ghost' }}
        />
      </div>
    </div>
  );
}
