import { useState } from 'react';
import { clsx } from 'clsx';
import * as RadixCollapsible from '@radix-ui/react-collapsible';

import styles from './Collapsible.module.scss';
import { Icon16 } from '@spaceduck/icons';
import type { AllOrNone } from '@spaceduck/utils';
const { DownArrow, RightArrow } = Icon16;

type CollapsibleProps = {
  className?: string;
  children?: React.ReactNode;
  initiallyExpanded?: boolean;
  triggerContent: React.ReactNode;
  hasUnstyledTrigger?: boolean;
  contentClassName?: string;
} & AllOrNone<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

export default function Collapsible({
  className,
  children,
  initiallyExpanded,
  triggerContent,
  hasUnstyledTrigger = false,
  contentClassName,
  isOpen: externalIsOpen,
  setIsOpen: externalSetIsOpen,
}: CollapsibleProps) {
  const [internalIsOpen, setInternalIsOpen] = useState(
    () => !!initiallyExpanded
  );
  const isOpen = externalIsOpen ?? internalIsOpen;
  const setIsOpen = externalSetIsOpen ?? setInternalIsOpen;

  if (!children)
    return (
      <div className={styles.trigger}>
        <div className={styles.triggerContent}>{triggerContent}</div>
      </div>
    );

  return (
    <RadixCollapsible.Root
      className={clsx(styles.collapsibleRoot, className)}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <RadixCollapsible.Trigger asChild>
        {hasUnstyledTrigger ? (
          triggerContent
        ) : (
          <button className={styles.trigger}>
            {isOpen ? <DownArrow /> : <RightArrow />}
            <div className={styles.triggerContent}>{triggerContent}</div>
          </button>
        )}
      </RadixCollapsible.Trigger>
      <RadixCollapsible.Content
        className={clsx(styles.content, contentClassName)}
      >
        {children}
      </RadixCollapsible.Content>
    </RadixCollapsible.Root>
  );
}
