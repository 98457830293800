import { useState } from 'react';
import clsx from 'clsx';

import { usePoll } from '@hooks/usePoll';
import Button from '@ui/Button';
import styles from './Auth.module.scss';
import Logo from '@figma/components/icons/Logo';
import { completeChallenge, createChallenge } from '@figma/lib/api';
import { saveToken } from '@figma/lib/plugin';
import { Icon24 } from '@spaceduck/icons';
const { Loading } = Icon24;

function Login() {
  const [codeChallenge, setCodeChallenge] = useState<string>('');
  const [codeVerifier, setCodeVerifier] = useState<string>('');
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [error, setError] = useState<'expired' | 'invalid' | null>(null);
  const [pollComplete, setPollComplete] = useState<boolean>(false);

  const poll = usePoll(1000, isWaiting && !pollComplete, async () => {
    if (!codeVerifier || !codeChallenge) {
      return;
    }

    const data = await completeChallenge({
      codeVerifier,
      codeChallenge,
    });

    switch (data.kind) {
      case 'challenge_not_claimed':
        // keep looping, don't clear the interval
        return;
      case 'invalid_challenge':
        setError('invalid');
        break;
      case 'challenge_expired':
        setError('expired');
        break;
      case 'success':
        saveToken(data.accessToken);
        break;
    }
    setPollComplete(true);
  });

  const onAuthenticate = async () => {
    setError(null);
    const { codeChallenge, codeVerifier } = await createChallenge();
    setCodeChallenge(codeChallenge);
    setCodeVerifier(codeVerifier);
    window.open(
      `/auth/integrate?codeChallenge=${encodeURIComponent(codeChallenge)}`,
      '_blank'
    );
    setIsWaiting(true);
    poll();
  };

  const errorMessage =
    error === 'expired' ? (
      <>
        <h3 className="title3">Expired</h3>
        <p>The authorization attempt expired. Please try again</p>
      </>
    ) : (
      <>
        <h3 className="title3">Something went wrong</h3>
        <p>
          Something ain't right, try again or get in touch with us if the issue
          isn't solved
        </p>
      </>
    );

  const renderLogin = () => (
    <>
      <div className={styles.logo}>
        <Logo />
      </div>
      <h3 className="title3">Log in or Sign up</h3>
      <Button onClick={onAuthenticate}>Continue</Button>
      <p className={clsx('body5', styles.help)}>
        Need help? <a href="https://spaceduck.com/">Get in touch</a>
      </p>
    </>
  );

  const renderError = () => (
    <>
      <div className={styles.logo}>
        <Logo />
      </div>
      {errorMessage}
      <Button onClick={onAuthenticate}>Try again</Button>
      <p className={clsx('body5', styles.help)}>
        Need help? <a href="https://spaceduck.com/">Get in touch</a>
      </p>
    </>
  );

  const renderLoading = () => (
    <div className={styles.loadingContainer}>
      <Loading size={20} />
      <h3 className="title3">Authorize Figma</h3>
      <p className={clsx('body5', styles.body)}>
        <span>
          Please authorize Figma from your browser.
          <br />
        </span>
        <span>If a tab did not open, please&nbsp;</span>
        <a
          href={`/auth/integrate?codeChallenge=${codeChallenge}`}
          target="_blank"
        >
          click here
        </a>
      </p>
    </div>
  );

  return (
    <div className={styles.background}>
      <div className={styles.light}></div>
      <div className={styles.container}>
        {error ? renderError() : !isWaiting ? renderLogin() : renderLoading()}
      </div>
    </div>
  );
}

export default Login;
