import { Link } from 'react-router-dom';

import styles from './OptionalLinks.module.scss';
import { Icon12 } from '@spaceduck/icons';
const { OpenNewWindow } = Icon12;

type OptionalLinkProps = {
  href?: string | null;
  children: React.ReactNode;
} & (
  | {
      isExternal: true;
    }
  | {
      isExternal?: false;
      state?: any;
    }
);

export default function OptionalLink(linkData: OptionalLinkProps) {
  const { children, href } = linkData;
  if (!href) return children;

  if (linkData.isExternal) {
    return (
      <a
        className={styles.optionalLink}
        href={href}
        rel="nofollow"
        target="_blank"
      >
        {children}
        <OpenNewWindow size={10} />
      </a>
    );
  }

  return href ? (
    <Link state={linkData.state} to={href}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
}
