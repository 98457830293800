import LightboxPlugin, { useController } from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { Icon24 } from '@spaceduck/icons';

import Button from '@ui/Button';
import styles from './Lightbox.module.scss';

const { Close } = Icon24;

export default function Lightbox({
  close,
  embedUrl,
  open,
}: {
  open: boolean;
  embedUrl: string;
  close: () => void;
}) {
  return (
    <LightboxPlugin
      className={styles.lightbox}
      open={open}
      close={close}
      slides={[
        {
          width: 560,
          height: 315,
          src: embedUrl,
        },
      ]}
      toolbar={{
        buttons: [<LightboxCloseButton key="closeButton" />],
      }}
      render={{
        slide: ({ slide }) => {
          if (slide.type === 'image') return null;
          const { height = 1, src, width = 1 } = slide;

          return (
            <div className={styles.lightboxVideoWrapper}>
              <div
                className={styles.lightboxVideo}
                style={{ paddingTop: `${(height / width) * 100}%` }}
              >
                <iframe
                  width={width}
                  height={height}
                  src={src}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          );
        },
      }}
    />
  );
}

const LightboxCloseButton = () => {
  const { close } = useController();

  return (
    <Button onClick={close} size="md" variant="outlined">
      <Close />
    </Button>
  );
};
