import type { MediaGroupContentType, MediaGroupDTO } from '@spaceduck/api';
import { Icon16, Icon24, IconProps } from '@spaceduck/icons';

const { Image, Multimedia, Video, Document, File, PDF } = Icon16;
const { Bookmark, Zip, Highlight, QuoteBottom } = Icon24;

type ContentTypeProps = {
  contentType: MediaGroupContentType;
  mediaGroup?: MediaGroupDTO;
} & IconProps;

export default function ContentType({
  className,
  color,
  contentType,
  size = 16,
  mediaGroup,
}: ContentTypeProps) {
  const iconProps = { className, color, size };
  switch (contentType) {
    case 'image':
      return <Image {...iconProps} />;
    case 'mixed':
      return <Multimedia {...iconProps} />;
    case 'video':
      return <Video {...iconProps} />;
    case 'document':
      return <Document {...iconProps} />;
    case 'pdf':
      return <PDF {...iconProps} />;
    case 'bookmark':
      return <Bookmark {...iconProps} />;
    case 'file':
      // TODO: Get media type from mediaGroup
      if (mediaGroup?.media[0]?.mediaType === 'application/zip') {
        return <Zip {...iconProps} />;
      }
      return <File {...iconProps} />;
    case 'highlight':
      return <Highlight {...iconProps} />;
    case 'quote':
      return <QuoteBottom {...iconProps} />;
    default:
      return <File {...iconProps} />;
  }
}
