import { ApiError, errorSchema } from "./errors";
import { SessionStore } from "./sessionStoreInterface";
import { exists } from "./util";
import { z } from "zod";

type TypeOrArrayOf<T> = T | T[];
export type QueryParams = Record<
  string,
  TypeOrArrayOf<number | string | boolean | null> | undefined
>;

type ApiParameters = {
  endpoint: string;
  method: "DELETE" | "GET" | "PATCH" | "POST" | "PUT";
  body?: FormData | Record<string, unknown>;
  authenticated?: boolean;
  params?: QueryParams;
  accept?: string;
};

type ParsedApiParameters<S extends z.ZodType> = ApiParameters & {
  responseSchema: S;
};

export class ApiClient {
  private static instance: ApiClient | null = null;
  private sessionStore: SessionStore;
  private baseUrl: string;
  private name: string;

  constructor(baseUrl: string, sessionStore: SessionStore, name: string) {
    this.sessionStore = sessionStore;
    this.baseUrl = baseUrl;
    this.name = name;
    if (ApiClient.instance) {
      console.warn("ApiClient has already been initialized. It's a singleton.");
      return ApiClient.instance;
    }
    ApiClient.instance = this;
    return this;
  }

  public static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      throw new Error("ApiClient must be initialized with a session store.");
    }
    return ApiClient.instance;
  }

  public static init(baseUrl: string, sessionStore: SessionStore, name: string) {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient(baseUrl, sessionStore, name);
    }
  }

  public static async unparsedCall(params: ApiParameters) {
    return ApiClient.getInstance().internalUnparsedCall(params);
  }

  private async internalUnparsedCall({
    endpoint,
    method,
    body,
    authenticated,
    params,
    accept = "application/json"
  }: ApiParameters) {
    const fetch = await this.getFetch(authenticated ?? true);
    const headers = {
      Accept: accept,
      ...(body instanceof FormData ? {} : { "Content-Type": "application/json" }),
      "X-Client-Name": this.name
    };
    const url = this.appendSearchParams(endpoint, params ?? null);
    const response = await fetch(url, {
      method,
      body: body instanceof FormData ? body : JSON.stringify(body),
      headers
    });

    if (!response) {
      throw new Error("No response from server");
    }

    if (response.status === 404) {
      throw new Error("Not found");
    }

    return response;
  }

  public static async call<S extends z.ZodType>(
    params: ParsedApiParameters<S>
  ): Promise<z.infer<S>> {
    return ApiClient.getInstance().internalCall(params);
  }

  private async internalCall<S extends z.ZodType>({
    endpoint,
    method,
    body,
    responseSchema,
    authenticated,
    params
  }: ParsedApiParameters<S>): Promise<z.infer<S>> {
    const response = await this.internalUnparsedCall({
      endpoint,
      method,
      body,
      authenticated,
      params
    });

    const responseData: unknown = await response.json();

    // Check if the server responded with an error
    const error = errorSchema.safeParse(responseData);
    if (error.success) {
      console.error("Error from server", error.data);
      throw new ApiError(error.data);
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return responseSchema.parse(responseData) as z.infer<S>;
    } catch (err) {
      if (err instanceof z.ZodError) {
        // This is a common error during development
        // So we provide some extra information/formatting to help debug
        console.groupCollapsed(
          "%c[Debug Info] Response from server did not match expected schema",
          "color: red;"
        );
        console.log(
          "Issues from client parse of %c%s:",
          "font-weight: bold;color: blue;",
          endpoint
        );
        console.table(err.issues.map((issue) => ({ ...issue, path: issue.path.join(".") })));
        console.error("Malformed data from server:", responseData);
        console.groupEnd();
      }
      throw err;
    }
  }

  private async getFetch(authenticated: boolean) {
    if (!authenticated) {
      return fetch;
    }

    const { ensureSession, authenticatedFetch } = this.sessionStore;
    await ensureSession?.();
    return authenticatedFetch;
  }

  private appendSearchParams(url: string, params: QueryParams | null) {
    const parsedUrl = new URL(url, this.baseUrl);
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        const values = Array.isArray(value) ? value : [value];
        values.filter(exists).forEach((val) => parsedUrl.searchParams.append(key, `${val}`));
      }
    }
    return parsedUrl.toString();
  }
}
