import { Fragment, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { TextFieldInstance } from '@spaceduck/api';

import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { CommonEditProps, CommonProps } from '../InfoCategories';
import Empty from './Empty';
import styles from './Text.module.scss';

function parseText(input: string, className?: string) {
  const lines = input.split('\n');

  return (
    <div className={className}>
      {lines.map((line, idx) =>
        idx === 0 ? (
          <Fragment key={idx}>{line}</Fragment>
        ) : (
          <Fragment key={idx}>
            <br />
            {line}
          </Fragment>
        )
      )}
    </div>
  );
}

export default function TextField(commonProps: CommonProps) {
  const { canEdit, instances, updateField } = commonProps;
  const [shouldShowEditView, setShouldShowEditView] = useState(false);
  const textInstance = (instances as TextFieldInstance[])[0];
  const [localValue, setLocalValue] = useState(textInstance?.value ?? '');

  const handleClick = () => {
    if (canEdit) {
      setShouldShowEditView(true);
    }
  };

  return (
    <>
      {shouldShowEditView && canEdit ? (
        <EditView
          localValue={localValue}
          setLocalValue={setLocalValue}
          setShouldShowEditView={setShouldShowEditView}
          textInstance={textInstance}
          updateField={updateField}
        />
      ) : (
        <div className={clsx(styles.wrapper, styles.hoverBg)}>
          {textInstance ? (
            <div className={clsx(styles.overflowHidden)}>
              <div onClick={handleClick}>
                {parseText(localValue, styles.text)}
              </div>
            </div>
          ) : (
            <Empty onClick={handleClick} />
          )}
        </div>
      )}
    </>
  );
}

const EditView = ({
  localValue,
  setLocalValue,
  setShouldShowEditView,
  textInstance,
  updateField,
}: CommonEditProps & {
  localValue: string;
  setLocalValue: React.Dispatch<React.SetStateAction<string>>;
  textInstance?: TextFieldInstance;
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const _localValue = useRef(textInstance?.value ?? '');

  const setValue = (value: string) => {
    setLocalValue(value);
    _localValue.current = value;
  };

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: async () => {
      await updateField?.(
        _localValue.current
          ? ([
              { ...textInstance, value: _localValue.current },
            ] as TextFieldInstance[])
          : []
      );
      setShouldShowEditView(false);
    },
  });

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0';
      const { scrollHeight } = textareaRef.current;
      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textareaRef, localValue]);

  const handleChange = async (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = ev.currentTarget.value;
    setValue(value);
  };

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Escape') {
      ev.stopPropagation();
      ev.preventDefault();
      setShouldShowEditView(false);
    }
  };

  return (
    <div ref={containerRef}>
      <textarea
        autoFocus
        className={styles.textarea}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Text"
        ref={textareaRef}
        value={localValue}
      />
    </div>
  );
};
