import { MediaGroupDetailDTO } from '@spaceduck/api';

import PositionContainer from './PositionContainer';
import { ExtractBlock } from '@/components/ExtractBlock';

import styles from './ExtractPlaceholder.module.scss';

type ExtractPlaceholderProp = {
  mediaGroup: MediaGroupDetailDTO;
};

export default function ExtractPlaceholder({
  mediaGroup,
}: ExtractPlaceholderProp) {
  if (mediaGroup.kind !== 'extract') return null;

  return (
    <PositionContainer>
      <ExtractBlock
        className={styles.wrapper}
        mediaGroup={mediaGroup}
        palette="surface2"
      />
    </PositionContainer>
  );
}
