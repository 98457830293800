import { useEffect, useRef } from 'react';
import { MediaGroupDTO } from '@spaceduck/api';

type DraggableCardProps = {
  anchorMediaGroupId?: string | null;
  children: React.ReactNode;
  className?: string;
  mediaGroup: MediaGroupDTO;
  onClick?: () => void;
  setAnchorMediaGroupId?: React.Dispatch<React.SetStateAction<string | null>>;
};

export default function DraggableCard({
  anchorMediaGroupId,
  children,
  className,
  mediaGroup,
  onClick,
  setAnchorMediaGroupId,
}: DraggableCardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const handleDragStart = (ev: React.DragEvent) => {
    ev.dataTransfer.setData('mediaGroup', JSON.stringify(mediaGroup));
  };

  useEffect(() => {
    if (anchorMediaGroupId === mediaGroup.id) {
      ref.current?.scrollIntoView({ behavior: 'instant', block: 'start' });
      setAnchorMediaGroupId?.(null);
    }
  }, [anchorMediaGroupId, setAnchorMediaGroupId]);

  return (
    <div
      className={className}
      draggable="true"
      onClick={onClick}
      onDragStart={handleDragStart}
      ref={ref}
    >
      {children}
    </div>
  );
}
