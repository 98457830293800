import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { isTextFieldInstance, TextFieldInstance } from '@spaceduck/api';

import { useCategoryCellSelectionKeyboard } from '@hooks/useCategoryCellSelection';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import type {
  AvailableTypes,
  StandardCellValue,
  StandardEditCellValue,
} from '@/types/Category';
import styles from './Text.module.scss';

function getInitialValue(value: AvailableTypes) {
  const instance = value.find(() => true);
  if (!instance) {
    return null;
  }

  if (isTextFieldInstance(instance)) {
    return instance;
  }
  return null;
}

export default function TextValue({
  canEdit,
  clearSelectedCell,
  handleDelete,
  handleUpdate,
  info,
  setSelectedCell,
  showPreview,
  value,
}: StandardCellValue) {
  const initialValue = getInitialValue(value);
  const [shouldShowEditView, setShouldShowEditView] = useState(false);
  const [localValue, setLocalValue] = useState<TextFieldInstance | null>(
    initialValue
  );
  const displayedText = localValue?.value.split('\n')[0] ?? '';

  const { setEnabled } = useCategoryCellSelectionKeyboard({
    setSelectedCell,
    enableOnLoad: false,
    onEnter: () => setShouldShowEditView(true),
    onEscape: () => clearSelectedCell(),
  });

  useEffect(() => {
    setEnabled(showPreview);
  }, [showPreview]);

  return (
    <>
      {canEdit && shouldShowEditView && (
        <EditTextValue
          clearSelectedCell={clearSelectedCell}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
          initialValue={initialValue}
          info={info}
          localValue={localValue}
          setLocalValue={setLocalValue}
          setShouldShowEditView={setShouldShowEditView}
        />
      )}
      {showPreview ? (
        <div
          className={clsx(styles.text, styles.preview)}
          onClick={canEdit ? () => setShouldShowEditView(true) : undefined}
        >
          {displayedText}
        </div>
      ) : (
        <div className={clsx(styles.text, shouldShowEditView && styles.hidden)}>
          <span className={styles.textInner}>{displayedText}</span>
        </div>
      )}
    </>
  );
}

const EditTextValue = ({
  clearSelectedCell,
  handleDelete,
  handleUpdate,
  info,
  initialValue,
  localValue,
  setLocalValue,
  setShouldShowEditView,
}: StandardEditCellValue<TextFieldInstance | null>) => {
  const localValueRef = useRef(localValue);
  const setLocalValueRef = (value: TextFieldInstance | null) => {
    localValueRef.current = value;
    setLocalValue(value);
  };

  const persist = async () => {
    // Stop update if value is same as initial value
    if (isEqual(initialValue, localValueRef.current)) {
      return;
    }

    const value = localValueRef.current
      ? { ...localValueRef.current, value: localValueRef.current.value.trim() }
      : null;

    // Optimistic update
    info.table.options.meta?.updateData?.(info.row.index, info.column.id, [
      localValueRef.current ?? null,
    ]);

    if (!value) {
      handleDelete?.({
        propertyId: info.column.id,
        rowIndex: info.row.index,
      });
      return;
    }

    await handleUpdate?.({
      propertyId: info.column.id,
      rowIndex: info.row.index,
      value: [value],
    });
  };

  const handleChange = (ev: React.ChangeEvent) => {
    const value = (ev.target as HTMLInputElement).value;

    if (!value) {
      setLocalValueRef(null);
      return;
    }

    setLocalValueRef({
      ...localValueRef.current,
      value,
    });
  };

  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Escape') {
      persist();
      setShouldShowEditView(false);
    }
  };

  const handleFocus = (ev: React.FocusEvent) => {
    const el = ev.target as HTMLTextAreaElement;
    el.setSelectionRange(el.value.length, el.value.length);
  };

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: () => {
      persist();
      setShouldShowEditView(false);
      clearSelectedCell();
    },
  });

  return (
    <div className={styles.textEdit} ref={containerRef}>
      <textarea
        autoComplete="off"
        autoFocus
        className={styles.textContent}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        value={localValueRef.current?.value ?? ''}
        style={{ resize: 'none' }}
      />
    </div>
  );
};
