import { useState } from 'react';
import { usePoll } from './usePoll';
import { type MediaGroupDTO, mediaGroupPoll } from '@spaceduck/api';

export const useCheckMediaGroupUpdated = (mediaGroups: MediaGroupDTO[]) => {
  const [updatedMediaGroupMap, setUpdatedMediaGroupMap] = useState<
    Record<string, MediaGroupDTO>
  >({});

  const needsUpdate = (mg: MediaGroupDTO) => {
    return mg.thumbnail.isPlaceholder || mg.isGeneratingContent;
  };

  const mediaGroupsNeedingUpdate = new Set<string>();
  mediaGroups.filter(needsUpdate).forEach((mg) => {
    mediaGroupsNeedingUpdate.add(mg.id);
  });

  Object.keys(updatedMediaGroupMap).forEach((id) => {
    mediaGroupsNeedingUpdate.delete(id);
  });

  usePoll(1000, mediaGroupsNeedingUpdate.size > 0, async () => {
    const { updatedMap } = await mediaGroupPoll(
      Array.from(mediaGroupsNeedingUpdate)
    );
    setUpdatedMediaGroupMap((prevMap) => {
      return {
        ...prevMap,
        ...Object.fromEntries(
          Object.entries(updatedMap).filter(([_, mg]) => !needsUpdate(mg))
        ),
      };
    });
  });

  return updatedMediaGroupMap;
};
