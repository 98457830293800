import PlanButton from '@components/PlanButton';
import '@layouts/sidebar/Menu.scss';
import MenuCreateSection from './MenuCreateSection';
import MenuUserSection from './MenuUserSection';
import MenuMainNavSection from './MenuMainNavSection';
import MenuProjectsSection from './MenuProjectsSection';
import styles from './SpaceMenu.module.scss';
import { Icon16 } from '@spaceduck/icons';
import { useNavigate } from 'react-router-dom';
import { urlFor } from '@/urls';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import Button, { ButtonLink } from '@/components/ui/Button';

const { Delete, Support } = Icon16;

export default function ProjectMenu() {
  const navigate = useNavigate();
  const workspaceId = useWorkspaceId();
  return (
    <div className={styles.spaceMenu}>
      <div className={styles.menu}>
        <MenuUserSection />
        <MenuMainNavSection />
        <MenuProjectsSection />
      </div>
      <div className={styles.planStatus}>
        <PlanButton />
      </div>
      <div className={styles.contentManagement}>
        <MenuCreateSection />

        <div className={styles.leftNavActions}>
          {workspaceId && (
            <Button
              variant="ghost"
              onClick={() =>
                navigate(urlFor('workspaceTrashItems', { workspaceId }))
              }
            >
              <Delete />
            </Button>
          )}
          <ButtonLink
            variant="ghost"
            to="https://spaceduck.com/learn"
            target="_blank"
          >
            <Support />
          </ButtonLink>
        </div>
      </div>
    </div>
  );
}
