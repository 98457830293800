import type { BillingPlan } from '@spaceduck/api';
import Badge, { type BadgeProps } from '@ui/Badge';
import { PLAN_LABELS } from '@/const';

type PlanBadgeProps = {
  plan?: BillingPlan;
} & Pick<BadgeProps, 'className' | 'size'>;

export default function PlanBadge(props: PlanBadgeProps) {
  const { plan, size = 'sm', ...badgeProps } = props;

  if (!plan) return null;
  const preset = PLAN_LABELS[plan];

  return (
    <Badge preset={preset} size={size} {...badgeProps}>
      {PLAN_LABELS[plan]}
    </Badge>
  );
}
