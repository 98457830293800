import {
  forwardRef,
  Fragment,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import upperFirst from 'lodash/upperFirst';
import clsx from 'clsx';
import { MediaGroupDetailDTO } from '@spaceduck/api';
import { KeysMatching } from '@spaceduck/utils';

import { useMediaGroupDetail, usePatchMediaGroup } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import createToast from '@utils/createToast';
import styles from './SingleFieldEdit.module.scss';

type SingleFieldEditProps = {
  borderHeight?: number;
  displayAs?: React.ElementType;
  displayStyle?: string;
  displayWrapperStyle?: string;
  enterToSubmit?: boolean;
  fallback?: string;
  fieldLabel?: string;
  fieldName: KeysMatching<MediaGroupDetailDTO, string>;
  onKeyDown?: (ev?: React.KeyboardEvent<HTMLTextAreaElement>) => boolean;
  mediaGroupId: string;
  required?: boolean;
  switchFocus?: () => void;
  textareaStyle?: string;
};

const SingleFieldEdit = forwardRef(
  (
    {
      borderHeight = 0,
      displayAs,
      displayStyle,
      displayWrapperStyle,
      enterToSubmit = false,
      fallback = '',
      fieldLabel,
      fieldName,
      onKeyDown,
      mediaGroupId,
      required,
      switchFocus,
      textareaStyle,
    }: SingleFieldEditProps,
    ref: Ref<HTMLTextAreaElement>
  ) => {
    const id = mediaGroupId;
    const { data, isFetching, isLoading, isPending, isStale } =
      useMediaGroupDetail(id);
    const userCanEdit = isCapable('edit', data?.userCapabilities).capable;
    const label = fieldLabel ?? fieldName;

    const [localField, setLocalField] = useState(
      data?.mediaGroup[fieldName] ?? ''
    );
    const [isEditingLocalField, setIsEditingLocalField] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => textareaRef.current!);

    useEffect(() => {
      if (!fallback) {
        setLocalField(data?.mediaGroup[fieldName] ?? '');
        setIsEditingLocalField(!data?.mediaGroup[fieldName]);
      }
    }, [data?.mediaGroup[fieldName], fallback]);

    const { mutateAsync: patchMediaGroup, isPending: pendingLabelUpdate } =
      usePatchMediaGroup();

    const handleSubmit = async (value: string) => {
      if (value === data?.mediaGroup[fieldName]) return;

      if (required && !value.trim()) {
        createToast({
          titleText: `${upperFirst(label)} cannot be empty.`,
          bodyText: `Please provide a ${label}.`,
          iconVariant: 'danger',
        });

        setLocalField(data?.mediaGroup[fieldName]);

        return;
      }

      const patchData = {
        [fieldName]: value,
      };

      const res = await patchMediaGroup({
        mediaGroupId,
        patch: patchData,
      });

      if (res.kind === 'success') {
        createToast({
          titleText: 'Saved',
          bodyText: `${upperFirst(label)} was saved.`,
          iconVariant: 'success',
        });
      } else {
        createToast({
          titleText: 'Update failed',
          bodyText: `${upperFirst(label)} could not be updated.`,
          iconVariant: 'danger',
        });
      }
    };

    const handleEnterKeyPress = (
      ev: React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
      if (!pendingLabelUpdate) {
        ev.preventDefault();
        if (ev.currentTarget.value.trim() === '' && required) {
          return false;
        }
        handleSubmit(ev.currentTarget.value);
        setIsEditingLocalField(false);
        switchFocus?.();
      }
    };

    const handleKeyDown = async (
      ev: React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
      if (
        enterToSubmit &&
        !ev.shiftKey &&
        ev.key === 'Enter' &&
        !pendingLabelUpdate
      ) {
        handleEnterKeyPress(ev);
      } else if ((ev.metaKey || ev.ctrlKey) && ev.key === 'Enter') {
        handleEnterKeyPress(ev);
      } else if (ev.key === 'Escape') {
        ev.preventDefault();
        ev.stopPropagation();
        setIsEditingLocalField(false);
      } else if (onKeyDown) {
        const submit = onKeyDown(ev);
        if (submit) {
          handleSubmit(ev.currentTarget.value);
          setIsEditingLocalField(false);
        }
      }
    };

    const handleBlur = async (ev: React.FocusEvent<HTMLTextAreaElement>) => {
      await handleSubmit(ev.currentTarget.value.trim());
      setIsEditingLocalField(false);
    };

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = '0';
        const { scrollHeight } = textareaRef.current;
        textareaRef.current.style.height = `${scrollHeight + borderHeight}px`;
      }
    }, [textareaRef, localField, isEditingLocalField]);

    useEffect(() => {
      setLocalField(data?.mediaGroup[fieldName] ?? '');
    }, [data?.mediaGroup[fieldName]]);

    if (isLoading || !data?.mediaGroup) return null;

    const { mediaGroup } = data;

    return (
      <div>
        {isEditingLocalField && userCanEdit ? (
          <textarea
            autoComplete="off"
            autoFocus={true}
            className={textareaStyle}
            onBlur={handleBlur}
            onFocus={(ev) =>
              ev.currentTarget.setSelectionRange(
                ev.currentTarget.value.length,
                ev.currentTarget.value.length
              )
            }
            onChange={(ev) => setLocalField(ev.currentTarget.value)}
            onKeyDown={handleKeyDown}
            placeholder={fallback}
            ref={textareaRef}
            rows={1}
            required={required}
            value={localField}
          ></textarea>
        ) : (
          <div
            onClick={() => setIsEditingLocalField(true)}
            className={displayWrapperStyle}
          >
            <OptimisticLabel
              as={displayAs}
              className={displayStyle}
              fallback={fallback}
              isWaiting={isFetching || isPending || isStale}
              currentValue={mediaGroup[fieldName]}
              futureValue={localField}
            />
          </div>
        )}
      </div>
    );
  }
);

const OptimisticLabel = ({
  as,
  className,
  currentValue,
  fallback,
  futureValue,
  isWaiting,
}: {
  as?: React.ElementType;
  className?: string;
  currentValue: string;
  fallback?: string;
  futureValue?: string;
  isWaiting: boolean;
}) => {
  const displayedText = isWaiting ? futureValue : currentValue;
  const Component = as ?? 'p';

  return (
    <Component className={clsx(styles.mimicTextarea, className)}>
      {displayedText
        ? displayedText.split('\n').map((line, idx) => (
            <Fragment key={idx}>
              {idx > 0 && <br />}
              {line}
            </Fragment>
          ))
        : fallback}
    </Component>
  );
};

export default SingleFieldEdit;
