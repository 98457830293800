import { MediaGroupDetailDTO } from '@spaceduck/api';

import { useMediaGroupDetail } from '@api/mediaGroup';
import OverviewListCard from '@components/OverviewListCard';
import CardWrapper from './CardWrapper';
import DraggableCard from './DraggableCard';
import NotesCard from './NotesCard';
import PdfPreview from '../media/PdfPreview';
import styles from './BrowseRepository.module.scss';
import { ExtractBlock } from '@/components/ExtractBlock';

type ExtendedPreviewCardProps = {
  mediaGroup: MediaGroupDetailDTO;
  onClose?: () => void;
  onDelete?: () => void;
  onSwapClick?: () => void;
};

export default function ExtendedPreviewCard({
  mediaGroup,
  onClose,
  onDelete,
  onSwapClick,
}: ExtendedPreviewCardProps) {
  if (mediaGroup.contentType === 'pdf') {
    return (
      <CardWrapper
        className={styles.padded}
        mediaGroup={mediaGroup}
        onClose={onClose}
        onDelete={onDelete}
        onSwapClick={onSwapClick}
      >
        <PdfCard mediaGroupId={mediaGroup.id} />
      </CardWrapper>
    );
  }

  if (mediaGroup.kind === 'document') {
    return (
      <CardWrapper
        mediaGroup={mediaGroup}
        onClose={onClose}
        onDelete={onDelete}
        onSwapClick={onSwapClick}
      >
        <NotesCard mediaGroup={mediaGroup} />
      </CardWrapper>
    );
  }

  if (mediaGroup.kind === 'extract') {
    return (
      <CardWrapper
        mediaGroup={mediaGroup}
        onClose={onClose}
        onDelete={onDelete}
        onSwapClick={onSwapClick}
      >
        <ExtractBlock
          mediaGroup={mediaGroup}
          palette="surface3"
          className={styles.extractCard}
        />
      </CardWrapper>
    );
  }

  return (
    <DraggableCard mediaGroup={mediaGroup}>
      <CardWrapper
        mediaGroup={mediaGroup}
        onClose={onClose}
        onDelete={onDelete}
        onSwapClick={onSwapClick}
      >
        <div className={styles.padded}>
          <OverviewListCard
            asQuickView
            disableLinks
            mediaGroup={mediaGroup}
            projectName={mediaGroup.project?.label ?? ''}
            showMinimalView={true}
          />
        </div>
      </CardWrapper>
    </DraggableCard>
  );
}

const PdfCard = ({ mediaGroupId }: { mediaGroupId: string }) => {
  const { data } = useMediaGroupDetail(mediaGroupId);
  if (data?.mediaGroup.media[0]?.mediaType !== 'application/pdf') return null;

  const { assetName, assetUrl } = data.mediaGroup.media[0];

  return <PdfPreview name={assetName} url={assetUrl} />;
};
