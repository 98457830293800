import { useState } from 'react';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import { clsx } from 'clsx';

import Logo from '@/assets/img/Logo';
import AuthSelector from '@components/login/AuthSelector';
import Button from '@ui/Button';
import { EMAIL_REGEX } from '@utils/regex';
import { openMailTo } from '@utils/openMailTo';
import styles from './Form.module.scss';
import { LoginPurpose } from './schema';

export type EmailFormData = {
  email: string;
};

type EmailFormProps = {
  onSubmit: SubmitHandler<EmailFormData>;
  isLoading: boolean;
  error: string | null;
  nextUrl?: string;
  userHint?: string | null;
  purpose?: LoginPurpose | null;
};

const Title = ({ purpose }: { purpose?: LoginPurpose | null }) => {
  if (purpose === 'invite') {
    return <>Log in or Sign up to accept invitation</>;
  }
  return <>Log in or Sign up to Spaceduck</>;
};

const EmailForm = ({
  error,
  isLoading,
  onSubmit,
  nextUrl,
  userHint,
  purpose,
}: EmailFormProps) => {
  const defaultEmail = userHint?.match(EMAIL_REGEX) ? userHint : null;
  const [showEmailLogin, setShowEmailLogin] = useState(defaultEmail !== null);
  const {
    register,
    handleSubmit,
    formState: { errors: emailErrors },
  } = useForm<EmailFormData>();

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <h1 className="title2">
        <Title purpose={purpose} />
      </h1>
      <div className={styles.form}>
        <AuthSelector
          nextUrl={nextUrl}
          userHint={userHint}
          disableAppleLogin
          disableMSLogin
        />
        {showEmailLogin ? (
          <>
            <div className={styles.separator}></div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Invalid Email',
                  },
                })}
                type="text"
                defaultValue={defaultEmail ?? ''}
                placeholder="Enter your email address..."
                className={clsx(emailErrors.email && 'hasError')}
              />
              {emailErrors.email && (
                <p className="errorMessage">{emailErrors.email.message}</p>
              )}
              {error && <p className="errorMessage">{error}</p>}
              <Button
                type="submit"
                disabled={isLoading}
                variant="secondary"
                size="lg"
              >
                Continue with Email
              </Button>
            </form>
          </>
        ) : (
          <Button
            className={styles.btnEmailLogin}
            onClick={() => setShowEmailLogin(true)}
            variant="secondary"
            size="lg"
          >
            Continue with Email
          </Button>
        )}
      </div>
      <p className={clsx(styles.terms, 'body6')}>
        By continuing, you agree to our{' '}
        <a href="https://www.spaceduck.com/legal/terms" target="_blank">
          Terms of Use
        </a>{' '}
        and{' '}
        <a href="https://www.spaceduck.com/legal/privacy" target="_blank">
          Privacy Policy
        </a>
        .
      </p>
      <p className={clsx(styles.getHelp, 'body5')}>
        Need help?{' '}
        <Button
          variant="link"
          className={styles.button}
          onClick={() => openMailTo('[SUPPORT]', '')}
        >
          Get in touch
        </Button>
      </p>
    </div>
  );
};

export default EmailForm;
