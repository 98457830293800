import { NavLink } from 'react-router-dom';
import { clsx } from 'clsx';

import styles from './MenuLink.module.scss';

type ButtonOption = {
  asButton: true;
  onClick?: () => void;
};

type LinkOption = {
  asButton?: false;
  url: string;
};

type MenuLinkProps = {
  className?: string;
  children: React.ReactNode;
  count?: number;
  icon?: React.ReactNode;
} & (LinkOption | ButtonOption);

export default function MenuLink(props: MenuLinkProps) {
  const { asButton, children, className, count, icon } = props;
  const contentProps = { children, count, icon };

  if (asButton) {
    return (
      <button
        className={clsx(styles.button, className)}
        onClick={() => props.onClick?.()}
      >
        <Content {...contentProps}>{children}</Content>
      </button>
    );
  }

  return (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? clsx(styles.link, styles.active, className)
          : clsx(styles.link, className)
      }
      to={props.url}
    >
      <Content {...contentProps}>{children}</Content>
    </NavLink>
  );
}

const Content = ({
  children,
  count,
  icon,
}: Omit<MenuLinkProps, 'asButton' | 'className' | 'onClick' | 'url'>) => {
  return (
    <>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.content}>{children}</span>
      {count !== undefined && <span className={styles.count}>{count}</span>}
    </>
  );
};
