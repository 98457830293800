import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Editor, BubbleMenu, JSONContent } from '@tiptap/react';
import { upperFirst } from 'lodash';
import { Icon16 } from '@spaceduck/icons';
import { useHotkeys } from 'react-hotkeys-hook';
import clsx from 'clsx';

import { useMakeComment } from '@api/comment';
import { CommentTextBox } from '@components/detailsModal/comments/CommentTextbox';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Menu from '@ui/Menu';
import { type Transformation, handleTransformation } from './transformations';
import EditLinkForm from './nodes/link/EditLinkForm';
import styles from './SelectionMenu.module.scss';

type Options = {
  color?: string;
};

type View = 'menu' | 'link' | 'comment';

const {
  Bold,
  BulletedList,
  CodeBlock,
  CodeInline,
  Color,
  Comment,
  TextT,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Highlight,
  Italics,
  Link,
  NumberedList,
  Quote,
  Strikethrough,
  TextColor,
  TextHighlight,
  TodoList,
  Underline,
} = Icon16;

export default function SelectionMenu({
  editor,
  disabled = false,
}: {
  editor: Editor | null;
  disabled?: boolean;
}) {
  const [view, setView] = useState<View>('menu');
  const { mediaGroupId } = useParams();
  const detailsModalContent = document.querySelector(
    '#tiptapSelectionMenuRoot'
  );

  useEffect(() => {
    // Triggers tippy pos recalc
    window.dispatchEvent(new Event('resize'));
    detailsModalContent?.setAttribute('data-view', view);
  }, [view]);

  if (!(editor && detailsModalContent) || !editor.isEditable) return null;

  return (
    <BubbleMenu
      className={clsx(styles.menu, styles[view])}
      editor={editor}
      shouldShow={({ state, from, to }) => {
        if (disabled) return false;

        // For detecting drag handles
        if ('node' in state.selection) return false;
        if (from === 0) return false;
        return from !== to;
      }}
      tippyOptions={{
        maxWidth: 'none',
        onClickOutside: () => setView('menu'),
        onHide: () => setView('menu'),
        appendTo: detailsModalContent,
      }}
    >
      {view === 'link' && (
        <EditLinkForm
          initialValue={editor.getAttributes('link').href}
          initialOpenInNewTab={editor.getAttributes('link').target === '_blank'}
          hideForm={() => setView('menu')}
          onCreateLink={(url, openInNewTab) => {
            editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .setLink({ href: url, target: openInNewTab ? '_blank' : '_self' })
              .run();
            setView('menu');
          }}
          onDelete={() => {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();
            setView('menu');
          }}
        />
      )}
      {view === 'comment' && mediaGroupId && (
        <CommentForm
          mediaGroupId={mediaGroupId}
          editor={editor}
          setView={setView}
          view={view}
        />
      )}
      {view === 'menu' && (
        <Menu
          menuItems={[
            {
              icon: <TextT />,
              title: 'Format',
              tooltip: 'Format',
              menuItems: [
                {
                  label: 'Text',
                  icon: <TextT />,
                  onClick: () => handleTransformation(editor, 'text'),
                },
                {
                  label: 'Heading 1',
                  icon: <Heading1 />,
                  onClick: () => handleTransformation(editor, 'heading1'),
                },
                {
                  label: 'Heading 2',
                  icon: <Heading2 />,
                  onClick: () => handleTransformation(editor, 'heading2'),
                },
                {
                  label: 'Heading 3',
                  icon: <Heading3 />,
                  onClick: () => handleTransformation(editor, 'heading3'),
                },
                {
                  label: 'Heading 4',
                  icon: <Heading4 />,
                  onClick: () => handleTransformation(editor, 'heading4'),
                },
                {
                  label: 'Heading 5',
                  icon: <Heading5 />,
                  onClick: () => handleTransformation(editor, 'heading5'),
                },
                {
                  label: 'Heading 6',
                  icon: <Heading6 />,
                  onClick: () => handleTransformation(editor, 'heading6'),
                },
                {
                  label: 'Inline code',
                  icon: <CodeInline />,
                  onClick: () => handleTransformation(editor, 'inlineCode'),
                },
                {
                  label: 'Code block',
                  icon: <CodeBlock />,
                  onClick: () => handleTransformation(editor, 'codeBlock'),
                },
                {
                  label: 'Quote',
                  icon: <Quote />,
                  onClick: () => handleTransformation(editor, 'quote'),
                },
                {
                  label: 'Bold',
                  icon: <Bold />,
                  onClick: () => handleTransformation(editor, 'bold'),
                },
                {
                  label: 'Strikethrough',
                  icon: <Strikethrough />,
                  onClick: () => handleTransformation(editor, 'strikethrough'),
                },
                {
                  label: 'Underline',
                  icon: <Underline />,
                  onClick: () => handleTransformation(editor, 'underline'),
                },
                {
                  label: 'Italics',
                  icon: <Italics />,
                  onClick: () => handleTransformation(editor, 'italic'),
                },
              ],
            },
            {
              icon: <BulletedList />,
              tooltip: 'Bulleted list',
              onClick: () => handleTransformation(editor, 'bulletedList'),
            },
            {
              icon: <NumberedList />,
              tooltip: 'Numbered list',
              onClick: () => handleTransformation(editor, 'numberedList'),
            },
            {
              icon: <TodoList />,
              tooltip: 'Todo list',
              onClick: () => handleTransformation(editor, 'todoList'),
            },
            {
              icon: <Link />,
              tooltip: 'Link',
              onClick: () => {
                setView('link');
              },
            },
            {
              isSeparator: true,
            },
            {
              icon: <Color />,
              tooltip: 'Color',
              title: 'Color',
              menuItems: Object.getOwnPropertyNames(colors).map((color) =>
                getColorOption(
                  editor,
                  color,
                  colors[color]!,
                  handleTransformation
                )
              ),
            },
            {
              icon: <Highlight />,
              tooltip: 'Highlight',
              menuItems: Object.getOwnPropertyNames(colors).map((color) =>
                getHighlightOption(
                  editor,
                  color,
                  colors[color]!,
                  handleTransformation
                )
              ),
            },
            {
              isSeparator: true,
            },
            {
              icon: <CodeInline />,
              tooltip: 'Code inline',
              onClick: () => handleTransformation(editor, 'inlineCode'),
            },
            {
              icon: <CodeBlock />,
              tooltip: 'Code block',
              onClick: () => handleTransformation(editor, 'codeBlock'),
            },
            {
              icon: <Quote />,
              tooltip: 'Quote',
              onClick: () => handleTransformation(editor, 'quote'),
            },
            {
              isSeparator: true,
            },
            {
              icon: <Comment />,
              tooltip: 'Comment',
              onClick: () => setView('comment'),
            },
          ]}
        />
      )}
    </BubbleMenu>
  );
}

const colors: Record<string, string> = {
  default: 'none',
  red: '#FB7185',
  orange: '#FDBA74',
  yellow: '#FDDF74',
  green: '#6EE599',
  teal: '#57DDCC',
  blue: '#80B7FB',
  purple: '#A29AFA',
  gray: '#B5B7CA',
};

const getColorOption = (
  editor: Editor,
  color: string,
  hex: string,
  cb: (
    editor: Editor,
    transformation: Transformation,
    options?: Options
  ) => void
) => ({
  label: upperFirst(color),
  icon: <TextColor color={hex === 'none' ? '#F0F1F4' : hex} />,
  onClick: () => cb(editor, 'color', { color: hex }),
});

const getHighlightOption = (
  editor: Editor,
  color: string,
  hex: string,
  cb: (
    editor: Editor,
    transformation: Transformation,
    options?: Options
  ) => void
) => ({
  label: upperFirst(color),
  icon: <TextHighlight color={hex === 'none' ? '#F0F1F4' : hex} />,
  onClick: () => cb(editor, 'highlight', { color: hex + '66' }),
});

const CommentForm = ({
  editor,
  mediaGroupId,
  setView,
  view,
}: {
  editor: Editor;
  mediaGroupId: string;
  setView: React.Dispatch<React.SetStateAction<View>>;
  view: View;
}) => {
  const { mutateAsync } = useMakeComment(mediaGroupId);

  const handleSubmit = async (content: string, document?: JSONContent) => {
    const { id } = await mutateAsync({
      content,
      document,
    });

    editor
      .chain()
      .focus()
      .setComment()
      .updateAttributes('comment', { 'data-comment-id': id })
      .run();

    setView('menu');
  };

  const { setIsInlineCommenting } = useDetailsModalStore(
    ({ setIsInlineCommenting }) => ({
      setIsInlineCommenting,
    })
  );

  useEffect(() => {
    setIsInlineCommenting(true);

    return () => {
      setIsInlineCommenting(false);
    };
  }, []);

  useHotkeys(
    'Escape',
    (ev) => {
      setView('menu');
      ev.preventDefault();
      ev.stopPropagation();
    },
    {
      enabled: view === 'comment',
    },
    [view]
  );

  return (
    <div className={styles.commentTextBox}>
      <CommentTextBox
        className={styles.createCommentTextBox}
        emojiPickerAsTippy={true}
        isLoading={false}
        onSubmit={handleSubmit}
        onCancel={() => setView('menu')}
      />
    </div>
  );
};
