import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useWorkspaceId from '@hooks/useWorkspaceId';
import Spinner from '@ui/Spinner';
import { urlFor } from '@/urls';

export default function SpacesRedirectPage() {
  const workspaceId = useWorkspaceId();
  const navigate = useNavigate();

  useEffect(() => {
    if (workspaceId === null) {
      navigate(urlFor('userSettingsCreateWorkspace'), { replace: true });
      return;
    }
    if (workspaceId) {
      navigate(urlFor('workspaceSpaces', { workspaceId }), {
        replace: true,
      });
      return;
    }
  }, [workspaceId]);

  return <Spinner />;
}
