import { z } from "zod";
import { ApiClient } from "../client";
import { apiUrl } from "../util";
import { successfulResponseSchema } from "../schema";

type ProjectWebLinkPatch = {
  label: string;
  url: string;
};

type PatchProjectWebLinkParams = {
  projectWebLinkId: string;
  patch: ProjectWebLinkPatch;
};

const patchProjectWebLinkResponseSchema = successfulResponseSchema.extend({
  webLink: z.object({
    id: z.string(),
    project: z.object({ id: z.string(), label: z.string() }),
    label: z.string(),
    url: z.string()
  })
});

export const patchProjectWebLink = async ({ projectWebLinkId, patch }: PatchProjectWebLinkParams) =>
  ApiClient.call({
    method: "PATCH",
    endpoint: apiUrl`/w/auth/project-web-link/${projectWebLinkId}/`,
    responseSchema: patchProjectWebLinkResponseSchema,
    body: { patch }
  });

type DeleteProjectWebLinkParams = {
  projectWebLinkId: string;
};

const deleteProjectWebLinkResponseSchema = successfulResponseSchema.extend({
  projectId: z.string()
});

export const deleteProjectWebLink = async ({ projectWebLinkId }: DeleteProjectWebLinkParams) =>
  ApiClient.call({
    method: "DELETE",
    endpoint: apiUrl`/w/auth/project-web-link/${projectWebLinkId}/`,
    responseSchema: deleteProjectWebLinkResponseSchema
  });
