import { Fragment, Suspense } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';

import { ErrorBoundary } from '@components/ErrorBoundary';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import { ModalManagerProvider } from '@context/ModalManagerContext';
import { useUserInfo } from '@hooks/useAuth';
import useGooglePageTracking from '@hooks/useGooglePageTracking';
import { OuterSpace } from '@layouts/Stars';

import Onboarding from '@components/Onboarding';
import StarsLayout from '@layouts/Stars';
import AuthLayout from '@layouts/Auth';
import WorkspaceLayout from '@layouts/Workspace';
import SpaceLayout from '@layouts/Space';
import SpacesLayout from '@layouts/Spaces';
import UserSettingsLayout from '@layouts/UserSettings';
import WorkspaceSettingsLayout from '@layouts/WorkspaceSettings';
import SupportLayout from '@layouts/Support';

import LoginPage from '@pages/Login';
import WelcomePage from '@pages/Welcome';
import SpacesPage from '@pages/projects/Spaces';
import CategoriesPage from '@pages/projects/Categories';
import ProgressPage from '@pages/projects/Progress';
import LibraryPage from '@pages/projects/Library';
import CollectionsPage from '@pages/projects/Collections';
import CategoryPage from '@pages/projects/Category';
import CategoriesRedirectPage from '@pages/CategoriesRedirect';
import TrashPage from '@pages/projects/Trash';
import MediaGroupPage from '@pages/MediaGroup';
import CollectionsRedirectPage from '@pages/CollectionsRedirect';
import SpacesRedirectPage from '@pages/SpacesRedirect';
import ProfileManagerPage from '@pages/settings/workspace/ProfileManager';
import WorkspacesManagerPage from '@pages/settings/workspace/WorkspacesManager';
import CreateWorkspacePage from '@pages/settings/workspace/CreateWorkspacePage';
import PeopleManagerPage from '@pages/settings/workspace/PeopleManager';
import SpacesManagerPage from '@pages/settings/workspace/SpacesManager';
import TagManagerPage from '@pages/settings/workspace/TagManager';
import MediaGroupCategoryManagerPage from '@/pages/settings/workspace/CategoryManager';
import WorkspaceManagerPage from '@pages/settings/workspace/WorkspaceManager';
import BillingAndPlanPage from '@pages/settings/workspace/BillingAndPlan';
import IntegrationsPage from '@/pages/settings/workspace/Integrations';
import UserProfilePage from '@pages/auth/Profile';
import NotFoundPage from '@pages/404';
import ServerErrorPage from '@pages/500';
import ErrorPage from '@pages/Error';
import AuthorizeIntegrationPage from '@pages/AuthorizeIntegration';
import HelpAndSupportPage from '@pages/HelpAndSupport';
import SupportedFileTypesPage from '@pages/SupportedFileTypes';

import FigmaEntryPoint from '@figma/FigmaEntryPoint';
import BrowserLoginRedirect from '@browser/BrowserLoginRedirect';

import MobileMediaGroupPage from '@mobile/MediaGroup';

const GooglePageTracking = () => {
  useGooglePageTracking();
  return null;
};

export default function Router() {
  const user = useUserInfo();

  if (user && user.isNew) {
    return (
      <Suspense
        fallback={
          <OuterSpace>
            <LoadingPlaceholder />
          </OuterSpace>
        }
      >
        <Onboarding />
      </Suspense>
    );
  }

  return (
    <Suspense
      fallback={
        <OuterSpace>
          <LoadingPlaceholder />
        </OuterSpace>
      }
    >
      <BrowserRouter>
        <ErrorBoundary>
          <GooglePageTracking />
          <ModalManagerProvider>
            <NavigatableRoutes />
            <ModalRoutes />
          </ModalManagerProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </Suspense>
  );
}

const mediaGroupRoutes = [
  <Route index element={<MediaGroupPage />} />,
  <Route path="comments" element={<MediaGroupPage activeTab="comment" />} />,
  <Route
    path="browse-repository"
    element={<MediaGroupPage activeTab="browseRepository" />}
  />,
  <Route
    path="references"
    element={<MediaGroupPage activeTab="references" />}
  />,
  <Route
    path="table-of-content"
    element={<MediaGroupPage activeTab="tableOfContent" />}
  />,
  <Route path="activity" element={<MediaGroupPage activeTab="activity" />} />,
  <Route path="comments" element={<MediaGroupPage activeTab="comment" />} />,
];

const ModalRoutes = () => {
  const location = useLocation();
  const backgroundLocation: string | null = location.state?.backgroundLocation;

  if (!backgroundLocation) {
    return null;
  }

  return (
    <Routes>
      <Route path="/media-groups">
        <Route path=":mediaGroupId">
          {mediaGroupRoutes.map((route, idx) => (
            <Fragment key={idx}>{route}</Fragment>
          ))}
        </Route>
      </Route>
    </Routes>
  );
};

const NavigatableRoutes = () => {
  const location = useLocation();
  const backgroundLocation: string | null =
    location.state?.backgroundLocation ?? null;

  return (
    <Routes location={backgroundLocation ?? location}>
      <>
        <Route path="/integrations">
          <Route path="figma" element={<FigmaEntryPoint />} />
          <Route path="browser/login" element={<BrowserLoginRedirect />} />
        </Route>
        <Route element={<SupportLayout />}>
          <Route path="/help-and-support" element={<HelpAndSupportPage />} />
          <Route
            path="/supported-file-types"
            element={<SupportedFileTypesPage />}
          />
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="/auth/profile" element={<UserProfilePage />} />
          <Route
            path="/auth/integrate"
            element={<AuthorizeIntegrationPage />}
          />
          <Route path="/media-groups">
            <Route path=":mediaGroupId">
              {mediaGroupRoutes.map((route, idx) => (
                <Fragment key={idx}>{route}</Fragment>
              ))}
            </Route>
          </Route>
        </Route>
        <Route index element={<Navigate to="/collections" replace />} />
        <Route path="/workspaces">
          <Route
            index
            element={<Navigate to={'/settings/workspaces'} replace />}
          />
          <Route path=":workspaceId" element={<WorkspaceLayout />}>
            <Route index element={<Navigate to="./collections" replace />} />
            <Route path="settings" element={<WorkspaceSettingsLayout />}>
              <Route
                index
                element={<Navigate to="./general" replace={true} />}
              />
              <Route path="general" element={<WorkspaceManagerPage />} />
              <Route path="tags" element={<TagManagerPage />} />
              <Route
                path="categories"
                element={<MediaGroupCategoryManagerPage />}
              />
              <Route path="people" element={<PeopleManagerPage />} />
              <Route path="spaces" element={<SpacesManagerPage />} />
              <Route path="billing-and-plan" element={<BillingAndPlanPage />} />
              <Route path="integrations" element={<IntegrationsPage />} />
            </Route>
            <Route element={<SpacesLayout />}>
              <Route path="categories" element={<CategoriesPage />} />
              <Route path="collections" element={<CollectionsPage />} />
              <Route path="trash">
                <Route index element={<Navigate to="./content" replace />} />
                <Route path="content" element={<TrashPage show="items" />} />
                <Route path="spaces" element={<TrashPage show="projects" />} />
              </Route>
              <Route path="spaces">
                <Route index element={<SpacesPage />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route element={<SpacesLayout />}>
          <Route path="/collections" element={<CollectionsRedirectPage />} />
          <Route path="/category">
            <Route index element={<CategoriesRedirectPage />} />
            <Route path=":categoryId" element={<CategoryPage />} />
          </Route>
          <Route path="/spaces">
            <Route index element={<SpacesRedirectPage />} />
            <Route path=":projectId" element={<SpaceLayout />}>
              <Route index element={<Navigate to="./library" replace />} />
              <Route path="library" element={<LibraryPage />} />
              <Route path="progress">
                <Route index element={<Navigate to="./list" replace />} />
                <Route path="list" element={<ProgressPage active="list" />} />
                <Route
                  path="kanban"
                  element={<ProgressPage active="kanban" />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="/settings">
          <Route index element={<Navigate to="/settings/profile" />} />
          <Route path="profile">
            <Route element={<UserSettingsLayout />}>
              <Route index element={<ProfileManagerPage />} />
            </Route>
          </Route>
          <Route path="workspaces">
            <Route element={<UserSettingsLayout />}>
              <Route index element={<WorkspacesManagerPage />} />
            </Route>
            <Route
              element={<StarsLayout includeBackLink={true} withAuth={true} />}
            >
              <Route path="new" element={<CreateWorkspacePage />} />
            </Route>
          </Route>
        </Route>
        <Route element={<StarsLayout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/500" element={<ServerErrorPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route
          path="/mobile/media-groups/:mediaGroupId"
          element={<MobileMediaGroupPage />}
        />
      </>
    </Routes>
  );
};
