import { z } from "zod";
import {
  BooleanFieldInstance,
  CategoryFieldKindSettings,
  createCapabilitySchema,
  DateFieldInstance,
  FieldInstance,
  LinkFieldInstance,
  mediaGroupCategoryCollectionCapabilitySchema,
  mediaGroupCategoryDetailSchema,
  mediaGroupCategoryFieldSchema,
  mediaGroupCategorySchema,
  NumberFieldInstance,
  RelationshipFieldInstance,
  TagFieldInstance,
  TextFieldInstance,
  validateDecimal
} from "./schemas";
import { successfulResponseSchema } from "../schema";
import { apiUrl } from "../util";
import { ApiClient } from "../client";

export const isTagFieldInstance = (value: FieldInstance): value is TagFieldInstance => {
  if (value === null) return false;
  return Object.keys(value).includes("tag");
};

export const isNumberFieldInstance = (value: FieldInstance): value is NumberFieldInstance => {
  if (value === null) return false;
  return "value" in value && typeof value.value === "string" && validateDecimal(value.value);
};

export const isTextFieldInstance = (value: FieldInstance): value is TextFieldInstance => {
  if (value === null) return false;
  return "value" in value && typeof value.value === "string";
};

export const isBooleanFieldInstance = (value: FieldInstance): value is BooleanFieldInstance => {
  if (value === null) return false;
  return "value" in value && typeof value.value === "boolean";
};

export const isLinkFieldInstance = (value: FieldInstance): value is LinkFieldInstance => {
  if (value === null) return false;
  return "url" in value;
};

export const isRelationshipFieldInstance = (
  value: FieldInstance
): value is RelationshipFieldInstance => {
  if (value === null) return false;
  return "mediaGroupId" in value;
};

export const isDateFieldInstance = (value: FieldInstance): value is DateFieldInstance => {
  if (value === null) return false;
  return "date" in value;
};

export type ListMediaGroupCategoriesParams = {
  workspaceId: string;
  cursor?: string;
  query?: string;
};

const listMediaGroupCategoriesSchema = successfulResponseSchema.extend({
  categories: mediaGroupCategorySchema.array(),
  nextCursor: z.string().nullable(),
  count: z.number(),
  total: z.number(),
  capabilities: createCapabilitySchema(mediaGroupCategoryCollectionCapabilitySchema).array()
});

export const getMediaGroupCategory = async ({ categoryId }: { categoryId: string }) =>
  ApiClient.call({
    method: "GET",
    endpoint: apiUrl`/w/explore/media-group-category/${categoryId}/`,
    responseSchema: successfulResponseSchema.extend({ category: mediaGroupCategoryDetailSchema })
  });

export const listMediaGroupCategories = async ({
  workspaceId,
  cursor,
  query
}: ListMediaGroupCategoriesParams) =>
  ApiClient.call({
    method: "GET",
    endpoint: apiUrl`/w/explore/media-group-category/`,
    params: { workspaceId, cursor, query: query || undefined },
    responseSchema: listMediaGroupCategoriesSchema
  });

export type CreateMediaGroupCategoryParams = {
  workspaceId: string;
  label: string;
};

export type CreateMediaGroupCategoryFieldParams = {
  categoryId: string;
  label: string;
} & CategoryFieldKindSettings;

export type PatchMediaGroupCategoryFieldParams = Partial<
  {
    label: string;
    width: number | null;
  } & Pick<CategoryFieldKindSettings, "settings">
>;

const createMediaGroupCategorySchema = successfulResponseSchema.extend({
  category: mediaGroupCategoryDetailSchema,
  created: z.boolean()
});

export const createMediaGroupCategory = async (body: CreateMediaGroupCategoryParams) =>
  ApiClient.call({
    method: "POST",
    endpoint: apiUrl`/w/explore/media-group-category/`,
    body,
    responseSchema: createMediaGroupCategorySchema
  });

const patchMediaGroupCategorySchema = successfulResponseSchema.extend({
  category: mediaGroupCategoryDetailSchema
});

type ReorderMediaGroupCategoryFieldParams = {
  fields: string[];
};

type PatchMediaGroupCategoryParams = {
  id: string;
  patch: {
    labelAlias?: string;
    labelWidth?: number;
    label?: string;
  };
};

export const patchMediaGroupCategory = async ({ id, ...body }: PatchMediaGroupCategoryParams) =>
  ApiClient.call({
    method: "PATCH",
    endpoint: apiUrl`/w/explore/media-group-category/${id}/`,
    body,
    responseSchema: patchMediaGroupCategorySchema
  });

export const reorderMediaGroupCategoryFields = async (
  id: string,
  body: ReorderMediaGroupCategoryFieldParams
) =>
  ApiClient.call({
    method: "POST",
    endpoint: apiUrl`/w/explore/media-group-category/${id}/fields/reorder/`,
    body,
    responseSchema: successfulResponseSchema
  });

const deleteMediaGroupCategorySchema = successfulResponseSchema.extend({
  category: z.object({
    label: z.string(),
    workspaceId: z.string()
  })
});

export const deleteMediaGroupCategory = async (mediaGroupCategoryId: string) =>
  ApiClient.call({
    method: "DELETE",
    endpoint: apiUrl`/w/explore/media-group-category/${mediaGroupCategoryId}/`,
    responseSchema: deleteMediaGroupCategorySchema
  });

const createMediaGroupCategoryFieldSchema = successfulResponseSchema.extend({
  field: mediaGroupCategoryFieldSchema
});

const patchMediaGroupCategoryFieldSchema = successfulResponseSchema.extend({
  field: mediaGroupCategoryFieldSchema
});

const deleteMediaGroupCategoryFieldSchema = successfulResponseSchema.extend({
  field: z.object({
    label: z.string(),
    categoryId: z.string()
  })
});

export const createMediaGroupCategoryField = async (body: CreateMediaGroupCategoryFieldParams) =>
  ApiClient.call({
    method: "POST",
    endpoint: apiUrl`/w/explore/media-group-category/field/`,
    body,
    responseSchema: createMediaGroupCategoryFieldSchema
  });

export const deleteMediaGroupCategoryField = async (mediaGroupCategoryFieldId: string) =>
  ApiClient.call({
    method: "DELETE",
    endpoint: apiUrl`/w/explore/media-group-category/field/${mediaGroupCategoryFieldId}/`,
    responseSchema: deleteMediaGroupCategoryFieldSchema
  });

export const patchMediaGroupCategoryField = async ({
  mediaGroupCategoryFieldId,
  patch
}: {
  mediaGroupCategoryFieldId: string;
  patch: PatchMediaGroupCategoryFieldParams;
}) =>
  ApiClient.call({
    method: "PATCH",
    endpoint: apiUrl`/w/explore/media-group-category/field/${mediaGroupCategoryFieldId}/`,
    body: { patch },
    responseSchema: patchMediaGroupCategoryFieldSchema
  });

export const setMediaGroupCategoryFieldInstances = async (body: {
  fieldId: string;
  mediaGroupId: string;
  instances: FieldInstance[];
}) =>
  ApiClient.call({
    method: "POST",
    endpoint: apiUrl`/w/explore/media-group-category/field-instance/`,
    body,
    responseSchema: successfulResponseSchema
  });
