/**
 * Wrapper module for react-pdf. You must import from this file instead of
 * `react-pdf` directly in order to have the css and service worker configured
 * correctly.
 */
import { Document, Page, pdfjs } from 'react-pdf';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

const pdfWorkerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
);
pdfjs.GlobalWorkerOptions.workerSrc = pdfWorkerSrc.toString();

export const PdfDocument = Document;
export const PdfPage = Page;
