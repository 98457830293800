import clsx from 'clsx';
import Color from '@tiptap/extension-color';
import Focus from '@tiptap/extension-focus';
import Highlight from '@tiptap/extension-highlight';
import ListKeymap from '@tiptap/extension-list-keymap';
import Placeholder from '@tiptap/extension-placeholder';
import TableRow from '@tiptap/extension-table-row';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';

import BulletList from './nodes/BulletList';
import CodeBlock from './nodes/CodeBlock';
import Comment from './nodes/Comment';
import ContentBlock from './nodes/ContentBlock';
import ContentBlockMenu from './nodes/ContentBlockMenu';
import HorizontalRule from './nodes/HorizontalRule';
import HoverMenu from './nodes/HoverMenu';
import ImageExt from './nodes/Image';
import Link from './nodes/Link';
import ListItem from './nodes/ListItem';
import MediaCard from './nodes/MediaCard';
import Mention from './nodes/Mention';
import MentionMenu from './nodes/MentionMenu';
import OutgoingLink from './nodes/OutgoingLink';
import SlashMenu from './nodes/SlashMenu';
import TableCell from './nodes/TableCell';
import Table from './nodes/Table';
import TableHeader from './nodes/TableHeader';
import editorStyles from './Editor.module.scss';

export const extensions = [
  StarterKit.configure({
    bulletList: false,
    codeBlock: false,
    dropcursor: {
      color: '#1266ED',
    },
    horizontalRule: false,
    listItem: false,
  }),
  BulletList,
  CodeBlock,
  Color,
  Comment,
  ContentBlock,
  ContentBlockMenu,
  Focus.configure({
    className: 'hasFocus',
  }),
  Highlight.configure({
    multicolor: true,
  }),
  HorizontalRule,
  HoverMenu(),
  ImageExt.configure({
    inline: false,
  }),
  Link.configure({
    //TODO: Clean up paste - URL doesn't work when nested as a child element
    openOnClick: false,
    protocols: ['http', 'https'],
    validate: (href) => /^https?:\/\//.test(href),
  }),
  ListItem,
  ListKeymap,
  MediaCard,
  Mention,
  MentionMenu,
  OutgoingLink,
  Placeholder.configure({
    emptyNodeClass: editorStyles.empty,
    placeholder: ({ node }) => {
      switch (node.type.name) {
        case 'heading':
          switch (node.attrs.level) {
            case 1:
              return 'Heading one';
            case 2:
              return 'Heading two';
            case 3:
              return 'Heading three';
            case 4:
              return 'Heading four';
            case 5:
              return 'Heading five';
            case 6:
              return 'Heading six';
          }
          break;
        case 'codeBlock':
          return 'Code block';
      }
      return `Type '/' for commands, '@' to mention someone, '+' to insert content`;
    },
  }),
  SlashMenu(),
  Table.configure({
    resizable: true,
  }),
  TableCell,
  TableHeader,
  TableRow,
  TaskItem.configure({
    nested: true,
  }),
  TaskList,
  TextStyle,
  Underline,
];

export const webViewExtensions = [
  StarterKit.configure({
    bulletList: false,
    codeBlock: false,
    dropcursor: {
      color: '#1266ED',
    },
    horizontalRule: false,
    listItem: false,
  }),
  BulletList,
  CodeBlock,
  Color,
  Comment,
  ContentBlock,
  Focus.configure({
    className: 'hasFocus',
  }),
  Highlight.configure({
    multicolor: true,
  }),
  HorizontalRule,
  ImageExt.configure({
    inline: false,
  }),
  Link.configure({
    //TODO: Clean up paste - URL doesn't work when nested as a child element
    openOnClick: false,
    protocols: ['http', 'https'],
    validate: (href) => /^https?:\/\//.test(href),
  }),
  ListItem,
  ListKeymap,
  MediaCard,
  Mention,
  MentionMenu,
  OutgoingLink,
  Placeholder.configure({
    emptyNodeClass: clsx(editorStyles.empty, editorStyles.webViewEmpty),
    placeholder: ({ node }) => {
      switch (node.type.name) {
        case 'heading':
          switch (node.attrs.level) {
            case 1:
              return 'Heading one';
            case 2:
              return 'Heading two';
            case 3:
              return 'Heading three';
            case 4:
              return 'Heading four';
            case 5:
              return 'Heading five';
            case 6:
              return 'Heading six';
          }
          break;
        case 'codeBlock':
          return 'Code block';
      }
      return `Text`;
    },
  }),
  Table.configure({
    resizable: true,
  }),
  TableCell,
  TableHeader,
  TableRow,
  TaskItem.configure({
    nested: true,
  }),
  TaskList,
  TextStyle,
  Underline,
];
