import { z } from "zod";
import {
  Role,
  assetCapabilitySchema,
  createCapabilitySchema,
  mediaGroupCapability,
  projectsCapabilitySchema,
  projectDTO,
  roleSchema,
  workspaceMemberCapabilitySchema,
  userSchema,
  ProjectStatus,
  billingPlanSchema,
  workspaceCapabilitySchema
} from "./schemas";
import { apiUrl, exists } from "../util";
import { ApiClient } from "../client";
import { successfulResponseSchema } from "../schema";

export const baseWorkspaceSchema = z.object({
  id: z.string(),
  label: z.string(),
  role: roleSchema,
  isFavorite: z.boolean(),
  createdAt: z.string(),
  avatarUrl: z.string().nullable()
});

export const workspaceMember = z.object({
  id: z.string(),
  name: z.string(),
  avatarUrl: z.string().nullable(),
  role: roleSchema
});

export type WorkspaceMemberSimple = z.infer<typeof workspaceMember>;

export const workspaceSchema = baseWorkspaceSchema.extend({
  membersPreview: workspaceMember.array()
});

export type Workspace = z.infer<typeof workspaceSchema>;

export const workspaceDetailSchema = baseWorkspaceSchema.extend({
  members: workspaceMember.array(),
  membershipId: z.string(),
  plan: billingPlanSchema,
  knockToken: z.string(),
  capabilities: createCapabilitySchema(
    z.enum([
      ...workspaceCapabilitySchema.options,
      ...assetCapabilitySchema.options,
      ...mediaGroupCapability.options
    ])
  ).array()
});

export type WorkspaceDetail = z.infer<typeof workspaceDetailSchema>;

export const listWorkspaces = async () =>
  ApiClient.call({
    method: "GET",
    endpoint: apiUrl`/w/auth/workspace/`,
    responseSchema: successfulResponseSchema.extend({
      workspaces: workspaceSchema.array()
    })
  });

export const getWorkspace = async (workspaceId: string) =>
  ApiClient.call({
    method: "GET",
    endpoint: apiUrl`/w/auth/workspace/${workspaceId}/`,
    responseSchema: successfulResponseSchema.extend({
      workspace: workspaceDetailSchema
    })
  });

export const createWorkspace = (label: string) =>
  ApiClient.call({
    method: "POST",
    endpoint: apiUrl`/w/auth/workspace/`,
    body: { label },
    responseSchema: successfulResponseSchema
  });

export const patchWorkspace = ({
  workspaceId,
  patch
}: {
  workspaceId: string;
  patch: { label?: string; isFavorite?: boolean; avatarAssetId?: string | null };
}) =>
  ApiClient.call({
    method: "PATCH",
    endpoint: apiUrl`/w/auth/workspace/${workspaceId}/`,
    body: { workspace: patch },
    responseSchema: successfulResponseSchema.extend({
      workspace: workspaceDetailSchema
    })
  });

export const listWorkspaceProjectsResponseSchema = successfulResponseSchema.extend({
  projects: projectDTO.array(),
  capabilities: createCapabilitySchema(projectsCapabilitySchema).array()
});

export type ListWorkspaceProjectsResponse = z.infer<typeof listWorkspaceProjectsResponseSchema>;
export type ListProjectsParams = {
  workspaceId: string;
  isStarred?: boolean;
  sort?: "open";
  query?: string;
  status?: ProjectStatus[];
  isDeleted?: boolean;
};

export const listProjects = async ({ workspaceId, ...params }: ListProjectsParams) =>
  ApiClient.call({
    endpoint: apiUrl`/w/auth/workspace/${workspaceId}/project/`,
    method: "GET",
    responseSchema: listWorkspaceProjectsResponseSchema,
    params
  });

const workspaceMemberSchema = z.object({
  id: z.string(),
  name: z.string(),
  avatarUrl: z.string().nullable(),
  role: roleSchema,
  assignedRole: roleSchema,
  projectCount: z.number(),
  capabilities: createCapabilitySchema(workspaceMemberCapabilitySchema).array()
});

export type WorkspaceMember = z.infer<typeof workspaceMemberSchema>;

const workspaceMemberListingSchema = successfulResponseSchema.extend({
  members: workspaceMemberSchema.array(),
  nextCursor: z.string().nullable(),
  count: z.number(),
  total: z.number()
});

export const patchWorkspaceMemberSchema = z.object({
  status: z.enum(["success", "pending", "failed"]),
  reason: z.enum(["subscription_does_not_exist", "invoice_pending"]).nullable(),
  invoice: z.string().nullable()
});

export const workspaceMemberDetails = async (workspaceId: string, userId: string) =>
  ApiClient.call({
    method: "GET",
    endpoint: apiUrl`/w/auth/workspace/${workspaceId}/member/${userId}/`,
    responseSchema: successfulResponseSchema.extend({
      member: workspaceMemberSchema
    })
  });

export const listWorkspaceMembers = async (
  workspaceId: string,
  params?: { query?: string; cursor?: string; assignedRoles?: Role[] }
) =>
  ApiClient.call({
    method: "GET",
    endpoint: apiUrl`/w/auth/workspace/${workspaceId}/member/`,
    params,
    responseSchema: workspaceMemberListingSchema
  });

export type PatchWorkspaceMemberParams = {
  workspaceId: string;
  userId: string;
  patch: { role: Role };
};

export const patchWorkspaceMember = async ({
  workspaceId,
  userId,
  patch
}: PatchWorkspaceMemberParams) =>
  ApiClient.call({
    method: "PATCH",
    endpoint: apiUrl`/w/auth/workspace/${workspaceId}/member/${userId}/`,
    body: { member: patch },
    responseSchema: successfulResponseSchema.merge(patchWorkspaceMemberSchema)
  });

export const deleteWorkspaceMember = async ({
  workspaceId,
  userId
}: {
  workspaceId: string;
  userId: string;
}) =>
  ApiClient.call({
    method: "DELETE",
    endpoint: apiUrl`/w/auth/workspace/${workspaceId}/member/${userId}/`,
    responseSchema: successfulResponseSchema
  });

export const deleteWorkspace = async (workspaceId: string) =>
  ApiClient.call({
    method: "DELETE",
    endpoint: apiUrl`/w/auth/workspace/${workspaceId}/`,
    responseSchema: successfulResponseSchema
  });

export const workspaceInviteCapability = z.enum(["resend", "delete"]);

export const workspaceInviteSchema = z.object({
  id: z.string(),
  email: z.string(),
  role: roleSchema,
  createdBy: userSchema,
  capabilities: workspaceInviteCapability
    .nullable()
    .catch(null)
    .array()
    .transform((items) => items.filter(exists))
});

export const workspaceInviteListingSchema = successfulResponseSchema.extend({
  invites: workspaceInviteSchema.array(),
  nextCursor: z.string().nullable(),
  count: z.number(),
  total: z.number()
});

export const listWorkspaceInvites = async (
  workspaceId: string,
  params?: { query?: string; cursor?: string }
) =>
  ApiClient.call({
    method: "GET",
    endpoint: apiUrl`/w/auth/workspace/${workspaceId}/invite/`,
    params,
    responseSchema: workspaceInviteListingSchema
  });

export const createWorkspaceInvite = async ({
  workspaceId,
  emails,
  role,
  projectIds
}: {
  workspaceId: string;
  emails: string[];
  role: Role;
  projectIds: string[];
}) =>
  ApiClient.call({
    method: "POST",
    endpoint: apiUrl`/w/auth/workspace/${workspaceId}/invite/`,
    body: { emails, role, projectIds },
    responseSchema: successfulResponseSchema.extend({
      seatsUpdated: patchWorkspaceMemberSchema
    })
  });
