import { useRef, useState } from 'react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import Tippy from '@tippyjs/react';
import {
  isTagFieldInstance,
  TagFieldInstance,
  TagOption,
} from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';

import { usePatchMediaGroupCategoryField } from '@api/mediaGroupCategory';
import {
  getColor,
  getLabel,
  TagManager,
} from '@components/category/cellContent/Tag';
import tagStyles from '@components/category/cellContent/Tag.module.scss';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { useDeleteConfirmModal } from '@ui/ConfirmModal';
import ScrollArea from '@ui/ScrollArea';
import createToast from '@utils/createToast';
import { css } from '@/lib/css';
import Empty from './Empty';
import { CommonProps, getCategoryFieldById } from '../InfoCategories';
import styles from './Tag.module.scss';

const { Close } = Icon16;

export default function Tag(commonProps: CommonProps) {
  const { kind } = commonProps;

  if (kind === 'multi-select') {
    return <MultiSelect {...commonProps} />;
  } else if (kind === 'select') {
    return <Select {...commonProps} />;
  }

  return <Empty />;
}

const TagMenu = (
  props: CommonProps & {
    setShouldShowEditView: React.Dispatch<React.SetStateAction<boolean>>;
  }
) => {
  const { setShouldShowEditView, ...commonProps } = props;
  const { category, fieldId, instances, kind, refetchData, updateField } =
    commonProps;
  const initialValue = instances.filter(isTagFieldInstance);
  const [localValue, setLocalValue] =
    useState<TagFieldInstance[]>(initialValue);
  const _localValue = useRef(initialValue);

  const { mutateAsync: patchMediaGroupCategoryField } =
    usePatchMediaGroupCategoryField({ categoryId: category.id });

  const setLocalValueRef = (value: TagFieldInstance[]) => {
    _localValue.current = value;
    setLocalValue(value);
  };

  const persist = async () => {
    if (isEqual(_localValue.current, initialValue)) return;
    await updateField?.(_localValue.current);
  };

  const field = getCategoryFieldById(fieldId, category);
  const settings = field?.settings;

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: async () => {
      await persist();
      setShouldShowEditView(false);
    },
  });

  const handleDeleteOption = async (option: TagOption) => {
    if (!option.id) return;

    const updatedOptions = options.filter(
      (existingOption) => existingOption.id !== option.id
    );

    await patchMediaGroupCategoryField({
      mediaGroupCategoryFieldId: fieldId,
      patch: {
        settings: {
          options: updatedOptions,
        },
      },
    });

    handleRemoveEntry({ tag: option.id });
  };

  const { open: openDeleteTagModal } = useDeleteConfirmModal({
    title: `Delete item`,
    subtitle:
      'This action cannot be undone. Your item will be permanently deleted.',
    confirmText: 'Yes, delete item',
    onConfirm: async ({ option }: { option: TagOption }) =>
      await handleDeleteOption(option),
  });

  if (!(settings && 'options' in settings)) {
    console.error('Cannot find options for Tags');
    return null;
  }

  const options = settings.options;

  const handleAddEntry = async (entry: TagFieldInstance) => {
    if (!entry.tag) return;

    if (kind === 'multi-select') {
      setLocalValueRef([
        ..._localValue.current,
        {
          tag: entry.tag,
        },
      ]);
    } else {
      setLocalValueRef([
        {
          tag: entry.tag,
        },
      ]);
    }

    await persist();
    refetchData();
  };

  const handleRemoveEntry = async (option: TagFieldInstance) => {
    setLocalValueRef([
      ..._localValue.current.filter((entry) => entry.tag !== option.tag),
    ]);

    await persist();
    refetchData();
  };

  const handleAddOption = async (option: TagOption) => {
    const updatedOptions = [...options, option];

    const res = await patchMediaGroupCategoryField({
      mediaGroupCategoryFieldId: fieldId,
      patch: {
        settings: {
          options: updatedOptions,
        },
      },
    });

    if (
      !(
        res.kind === 'success' &&
        res?.field?.settings &&
        'options' in res.field.settings
      )
    ) {
      createToast({
        titleText: 'Tag creation failed',
        bodyText: 'Tag could not be created.',
        iconVariant: 'danger',
      });

      return;
    }

    const { options: newOptions } = res.field.settings;
    const newOption = newOptions.find(
      (newOption) => newOption.label === option.label
    );

    await refetchData();

    if (!newOption?.id) {
      createToast({
        titleText: 'Tag creation failed',
        bodyText: 'Tag could not be added.',
        iconVariant: 'danger',
      });

      return;
    }

    handleAddEntry({ tag: newOption.id });
  };

  const handleEditOption = async (option: TagOption) => {
    if (!option.id) return;

    const updatedOptions = options.map((existingOption) => {
      if (existingOption.id === option.id) {
        return { ...existingOption, ...option };
      }

      return existingOption;
    });

    await patchMediaGroupCategoryField({
      mediaGroupCategoryFieldId: fieldId,
      patch: {
        settings: {
          options: updatedOptions,
        },
      },
    });

    refetchData();
  };

  return (
    <div className={clsx(tagStyles.tagEdit, styles.tagEdit)} ref={containerRef}>
      <Tippy
        content={
          <TagManager
            addEntry={handleAddEntry}
            addTagOption={handleAddOption}
            deleteTagOption={async (option) => openDeleteTagModal({ option })}
            editTagOption={handleEditOption}
            localValue={localValue}
            options={options}
            removeEntry={handleRemoveEntry}
            setShouldShowEditView={setShouldShowEditView}
          />
        }
        className={tagStyles.tagManagerPopup}
        interactive={true}
        offset={[0, 4]}
        placement="bottom-start"
        visible={true}
      >
        <div className={tagStyles.tags}>
          <ScrollArea
            style={css({
              maxHeight: '100%',
              width: '100%',
            })}
            orientation="vertical"
          >
            <div className={tagStyles.tagsInner}>
              {localValue.map(({ tag }, idx) => (
                <span
                  className={clsx(
                    tagStyles.tag,
                    tagStyles[getColor(tag, options)]
                  )}
                  key={idx}
                  onClick={() => handleRemoveEntry({ tag })}
                >
                  {getLabel(tag, options)}
                  <Close />
                </span>
              ))}
            </div>
          </ScrollArea>
        </div>
      </Tippy>
    </div>
  );
};

const FieldContainer = (
  props: CommonProps & { children?: React.ReactNode }
) => {
  const { children, ...commonProps } = props;
  const [shouldShowEditView, setShouldShowEditView] = useState(false);

  return (
    <div
      className={styles.container}
      onClick={() => setShouldShowEditView(true)}
    >
      <div className={styles.wrapper}>{children}</div>
      {shouldShowEditView && commonProps.canEdit && (
        <TagMenu
          {...commonProps}
          setShouldShowEditView={setShouldShowEditView}
        />
      )}
    </div>
  );
};

const TagItem = ({ canEdit, tag }: { canEdit: boolean; tag: TagOption }) => {
  const { color, label } = tag;

  return (
    <span
      className={clsx(
        tagStyles.tag,
        tagStyles[color],
        canEdit && styles.cursorPointer
      )}
    >
      <span className={styles.truncated}>{label}</span>
    </span>
  );
};

const Select = (commonProps: CommonProps) => {
  const { canEdit, category, fieldId, instances } = commonProps;
  const selectInstance = (instances as TagFieldInstance[])[0];

  const field = getCategoryFieldById(fieldId, category);
  if (!field?.settings) return <Empty />;

  if (!('options' in field.settings)) return <Empty />;
  const { options } = field.settings;

  const tag = options.find(
    (option) => selectInstance && option.id === selectInstance?.tag
  );

  return (
    <FieldContainer {...commonProps}>
      {tag ? <TagItem canEdit={canEdit} tag={tag} /> : <Empty />}
    </FieldContainer>
  );
};

const MultiSelect = (commonProps: CommonProps) => {
  const { canEdit, category, fieldId, instances } = commonProps;
  const multiSelectInstances = instances as TagFieldInstance[];

  const field = getCategoryFieldById(fieldId, category);
  if (!field?.settings) return <Empty />;

  if (!('options' in field.settings)) return <Empty />;
  const { options } = field.settings;

  const tags = multiSelectInstances
    .map((instance) => {
      const option = options.find((option) => option.id === instance.tag);
      if (!option) return null;

      return { ...option, instanceId: instance.instanceId };
    })
    .filter(exists);

  return (
    <FieldContainer {...commonProps}>
      {!!tags.length ? (
        <div className={styles.tags}>
          {tags.map((tag, idx) => (
            <TagItem canEdit={canEdit} key={idx} tag={tag} />
          ))}
        </div>
      ) : (
        <Empty />
      )}
    </FieldContainer>
  );
};
