import { z } from "zod";
import { projectDutySchema, projectMemberCapabilitySchema, roleSchema } from "./schemas";
import { successfulResponseSchema } from "../schema";
import { apiUrl } from "../util";
import { ApiClient } from "../client";

export const userKeys = {
  all: ["user"] as const,
  listProjects: (filters: { userId: string; workspaceId: string; query: string }) =>
    [...userKeys.all, filters] as const
};

const userProjectMembershipSchema = z.object({
  project: z.object({ id: z.string(), label: z.string() }),
  role: roleSchema,
  duty: projectDutySchema.nullable(),
  capabilities: projectMemberCapabilitySchema
    .nullable()
    .catch((ctx) => {
      console.log("Unknown project member capability", ctx.error);
      return null;
    })
    .array()
});

export type UserProjectMembership = z.infer<typeof userProjectMembershipSchema>;

const userProjectListingSchema = successfulResponseSchema.extend({
  memberships: userProjectMembershipSchema.array(),
  nextCursor: z.string().nullable(),
  count: z.number(),
  total: z.number()
});

const selfUserSchema = successfulResponseSchema.extend({
  user: z.object({
    id: z.string(),
    preferredName: z.string(),
    email: z.string(),
    avatarUrl: z.string().nullable(),
    isNew: z.boolean(),
    flags: z
      .string()
      .array()
      .transform((flags) => new Set(flags))
  })
});

export const listUserProjectMemberships = async (
  userId: string,
  params?: { workspaceId: string; query?: string; cursor?: string }
) =>
  ApiClient.call({
    method: "GET",
    endpoint: apiUrl`/w/auth/user/${userId}/project/`,
    params,
    responseSchema: userProjectListingSchema
  });

export const patchUserSelf = async (params: {
  avatarAsset?: string | null;
  preferredName?: string;
  isActive?: boolean;
  isNew?: false;
}) =>
  ApiClient.call({
    method: "PATCH",
    endpoint: apiUrl`/w/auth/user/self/`,
    body: { patch: params },
    responseSchema: selfUserSchema
  });
