/**
 * ===============================
 * Processing Requests Howto Guide
 * ===============================
 * These endpoints allow you to upload and process assets.
 *
 * 1. Make an upload request
 * Create an upload request with `createUploadRequest`.
 * You will receive back an `UploadRequest` object with an `id` and an
 * `endpoint`.
 *
 * 2. Use the upload request endpoint to upload the file with
 * `uploadFileToUploadRequest`
 *
 * 3. Convert the upload request into a processing request with
 * `uploadRequestIntoProcessingRequest`
 *
 * 4. Poll the processing request with `getProcessingRequestDetailMany` using
 * the `id` from the processing request to check the status of the processing
 * request.
 *
 * Note there are significant utility functions available in the useProcessAssets hook
 */
import { z } from "zod";
import { successfulResponseSchema } from "../schema";
import { apiUrl } from "../util";
import { ApiClient } from "../client";

const assetSchema = z.object({
  id: z.string(),
  url: z.string()
});

const processingRequestStateSchema = z.enum(["W", "P", "S", "F", "R"]);
export type ProcessingRequestState = z.infer<typeof processingRequestStateSchema>;

export const processingRequestSchema = z.object({
  id: z.string(),
  state: processingRequestStateSchema,
  message: z.string(),
  result: assetSchema.nullable()
});

export type ProcessingRequest = z.infer<typeof processingRequestSchema>;

export const processingRequestDetailManySchema = successfulResponseSchema.extend({
  processingRequests: processingRequestSchema.array()
});

export const getProcessingRequestDetailMany = (processingRequestIds: string[]) =>
  ApiClient.call({
    endpoint: apiUrl`/w/assets/processing-request/fetch-many/`,
    method: "POST",
    responseSchema: processingRequestDetailManySchema,
    body: { processingRequestIds }
  });

export const uploadRequestSchema = z.object({
  id: z.string(),
  endpoint: z.string(),
  fields: z.record(z.string(), z.string()),
  fileKey: z.string()
});

export type UploadRequest = z.infer<typeof uploadRequestSchema>;

export const createUploadRequestSchema = successfulResponseSchema.extend({
  uploadRequest: uploadRequestSchema
});

export const createUploadRequest = (body: {
  sizeBytes: number;
  mediaType: string;
  fileName?: string;
  workspaceId?: string;
}) =>
  ApiClient.call({
    endpoint: apiUrl`/w/assets/upload-request/`,
    method: "POST",
    responseSchema: createUploadRequestSchema,
    body
  });

export const uploadFileToUploadRequest = async (uploadRequest: UploadRequest, file: File) => {
  const uploadData = new FormData();
  for (const [key, value] of Object.entries(uploadRequest.fields)) {
    uploadData.set(key, value);
  }
  uploadData.set("Content-Type", file.type);
  uploadData.set(uploadRequest.fileKey, file);

  const response = await fetch(uploadRequest.endpoint, {
    method: "POST",
    body: uploadData
  });

  if (!response.ok) {
    throw new Error(`Upload failed: ${response.status}`);
  }
};

export const uploadRequestIntoProcessingRequestSchema = successfulResponseSchema.extend({
  processingRequest: processingRequestSchema
});

export const uploadRequestIntoProcessingRequest = (
  uploadRequestId: string,
  kind?: "media" | "avatar"
) =>
  ApiClient.call({
    endpoint: apiUrl`/w/assets/upload-request/${uploadRequestId}/into-processing-request/`,
    body: {
      kind
    },
    method: "POST",
    responseSchema: uploadRequestIntoProcessingRequestSchema
  });
