import { getDate, getMonth, getYear } from 'date-fns';

export const FULL_MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const SHORT_MONTH_NAMES = FULL_MONTH_NAMES.map((name) =>
  name.substring(0, 3)
);

export function getDisplayDate(date: Date) {
  return `${SHORT_MONTH_NAMES[getMonth(date)]} ${getDate(date)}, ${getYear(date)}`;
}
