// TODO(@dbowring): needed?
export const acceptedUserMediaTypes = {
  'image/avif': '.avif',
  'image/gif': '.gif',
  'image/jpeg': '.jpeg',
  'image/png': '.png',
  'image/webp': '.webp',
  'image/heic': '.heic',
  'image/heic-sequence': '.heic',
  'image/heif': '.heic',
  'image/heif-sequence': '.heic',
  'image/svg+xml': '.svg',
  'video/mp4': '.mp4',
  'video/webm': '.webm',
  'video/quicktime': '.mov',
};

const acceptedProfileImageTypes = {
  'image/avif': ['.avif'],
  'image/gif': ['.gif'],
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/webp': ['.webp'],
  'image/heic': ['.heic'],
  'image/heic-sequence': ['.heic'],
  'image/heif': ['.heic'],
  'image/heif-sequence': ['.heic'],
};
export const acceptedProfileImageMediaTypes = new Set(
  Object.keys(acceptedProfileImageTypes)
);
export const acceptedProfileImagePattern = [
  ...new Set(Object.values(acceptedProfileImageTypes).flat()),
].join(',');

export const acceptedAppLogoMediaTypes = {
  'image/avif': '.avif',
  'image/gif': '.gif',
  'image/jpeg': '.jpeg',
  'image/png': '.png',
  'image/webp': '.webp',
};

export const acceptedStaffMediaTypes = Object.assign(
  {},
  acceptedUserMediaTypes,
  {}
);
