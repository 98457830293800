/* eslint-disable max-classes-per-file */
import { z } from "zod";
import { ApiClient } from "../client";

const LoginRequestAcceptedPayloadSchema = z.object({
  kind: z.literal("success"),
  requestId: z.string()
});
export type LoginRequestAcceptedPayload = z.infer<typeof LoginRequestAcceptedPayloadSchema>;

const VerifyCodeAcceptedPayloadSchema = z.object({
  kind: z.literal("success")
});
export type VerifyCodeAcceptedPayload = z.infer<typeof VerifyCodeAcceptedPayloadSchema>;
export const VerifyCodeRejectedPayloadSchema = z.object({
  kind: z.literal("error"),
  errorKind: z.literal("code-rejected"),
  info: z.object({
    reason: z.string()
  })
});
export type VerifyCodeRejectedPayload = z.infer<typeof VerifyCodeRejectedPayloadSchema>;

export const createLoginRequest = async (payload: {
  email: string;
  next: string | null;
}): Promise<string> => {
  const response = await ApiClient.call({
    endpoint: "/_/auth/passwordless/create_login_request/",
    method: "POST",
    body: payload,
    responseSchema: LoginRequestAcceptedPayloadSchema,
    authenticated: false
  });
  return response.requestId;
};

export const verifyLoginCode = async (payload: {
  requestId: string;
  code: string;
}): Promise<boolean> => {
  await ApiClient.call({
    endpoint: "/_/auth/passwordless/verify_code/",
    method: "POST",
    body: payload,
    responseSchema: VerifyCodeAcceptedPayloadSchema,
    authenticated: false
  });
  return true;
};
