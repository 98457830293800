import { clsx } from 'clsx';

import type { AllOrNone } from '@spaceduck/utils';

import { Alert } from '@components/icons';
import Button from '@ui/Button';
import styles from './AlertBanner.module.scss';

export type Variant = 'danger' | 'info' | 'success' | 'warning';
type AlertBannerProps = {
  className?: string;
  children: React.ReactNode;
  title?: string;
  variant: Variant;
} & AllOrNone<{
  onCancel: () => void;
  onContinue: () => void;
}>;

export default function AlertBanner({
  className,
  children,
  onCancel,
  onContinue,
  title,
  variant,
}: AlertBannerProps) {
  return (
    <div className={clsx(styles.alertBanner, styles[variant], className)}>
      <Alert className={styles.icon} variant={variant} />
      <div className={clsx('body5', styles.text)}>
        {title && <h4 className="title4">{title}</h4>}
        {children}
      </div>
      {!!(onCancel && onContinue) && (
        <div className={styles.actions}>
          <Button
            className={styles.cancel}
            onClick={() => onCancel?.()}
            size="xs"
            type="button"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className={styles.continue}
            onClick={() => onContinue?.()}
            size="xs"
            type="button"
            variant="primary"
          >
            Continue
          </Button>
        </div>
      )}
    </div>
  );
}
