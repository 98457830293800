import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';

import stars from '@assets/img/stars.png';
import AuthWrapper from '@components/AuthWrapper';
import styles from './Stars.module.scss';
import { urlFor } from '@/urls';

const { Left } = Icon24;

export default function StarsLayout({
  includeBackLink,
  withAuth,
}: {
  includeBackLink?: boolean;
  withAuth?: boolean;
}) {
  if (withAuth) {
    return (
      <AuthWrapper>
        <OuterSpace includeBackLink={includeBackLink}>
          <Outlet />
        </OuterSpace>
      </AuthWrapper>
    );
  }

  return (
    <OuterSpace includeBackLink={includeBackLink}>
      <Outlet />
    </OuterSpace>
  );
}

export const OuterSpace = ({
  includeBackLink,
  children,
  className,
  light = true,
}: {
  includeBackLink?: boolean;
  children: React.ReactNode;
  className?: string;
  light?: boolean;
}) => {
  return (
    <div className={clsx(styles.world, className)}>
      {includeBackLink && (
        <div className={styles.backLink}>
          <Link to={urlFor('home')}>
            <Left />
            Back to Spaceduck
          </Link>
        </div>
      )}
      {light && (
        <div className={styles.lightWrapper}>
          <div className={styles.light}></div>
        </div>
      )}
      <div className={styles.starsWrapper}>
        <img src={stars} className={styles.stars} width={946} height={586} />
      </div>
      <main>{children}</main>
    </div>
  );
};
