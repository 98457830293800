import styles from './NoAccessCard.module.scss';

export default function NoAccessCard() {
  return (
    <div className={styles.container}>
      <div>
        <h1>No access</h1>
        <p>Content removed or access unathorized.</p>
      </div>
    </div>
  );
}
