import { z } from "zod";
import { tagCapabilitySchema, tagDTO } from "./schemas";
import { apiUrl } from "../util";
import { ApiClient } from "../client";
import { successfulResponseSchema } from "../schema";

export const tagKeys = {
  all: ["tags"] as const,
  list: (filters: { workspaceId: string | null; query: string }) =>
    [...tagKeys.all, "list", filters] as const
};

const tagSchema = tagDTO.extend({ usageCount: z.number() });

export type Tag = z.infer<typeof tagSchema>;

const tagListingSchema = successfulResponseSchema.extend({
  tags: tagSchema.array(),
  nextCursor: z.string().nullable(),
  count: z.number(),
  total: z.number(),
  capabilities: tagCapabilitySchema.array()
});

export const listTags = async (params: { workspaceId: string; query?: string; cursor?: string }) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/tag/`,
    params,
    method: "GET",
    responseSchema: tagListingSchema
  });

export type CreateTagParams = {
  workspaceId: string;
  label: string;
  description: string;
};

const createTagSchema = successfulResponseSchema.extend({
  tag: tagDTO,
  created: z.boolean()
});

export const createTag = async (body: CreateTagParams) =>
  ApiClient.call({
    method: "POST",
    endpoint: apiUrl`/w/explore/tag/`,
    body,
    responseSchema: createTagSchema
  });

export const patchTag = async (tagId: string, patch: { label?: string }) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/tag/${tagId}/`,
    method: "PATCH",
    body: { tag: patch },
    responseSchema: successfulResponseSchema
  });

const tagDeleteSchema = successfulResponseSchema.extend({
  label: z.string(),
  numMediaGroups: z.number()
});

export const deleteTag = async (id: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/tag/${id}/`,
    method: "DELETE",
    responseSchema: tagDeleteSchema
  });
