import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import type { MediaGroupDTO } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';

import { useMediaGroupDetail } from '@api/mediaGroup';
import MediaGroupStatusBadge from '@/components/MediaGroupStatusBadge';
import { MediaGroupCategory } from '@components/icons';
import PreviewCarousel from '@components/PreviewCarousel';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import dayjs from '@lib/dayjs';
import Button from '@ui/Button';
import UserAvatar from '@ui/UserAvatar';
import { urlFor } from '@/urls';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import styles from './OverviewListCard.module.scss';

const { Comment, Share, Version } = Icon16;

type OverviewListCardProps = {
  asQuickView?: boolean;
  disableLinks?: boolean;
  projectName?: string;
  mediaGroup: MediaGroupDTO;
  showMinimalView?: boolean;
};

export default function OverviewListCard({
  asQuickView,
  disableLinks,
  projectName,
  mediaGroup,
  showMinimalView,
}: OverviewListCardProps) {
  const { data: mediaGroupDetailData, isLoading: mediaGroupDetailIsLoading } =
    useMediaGroupDetail(mediaGroup.id);

  const { author, id } = mediaGroup;
  const { description, commentCount } = mediaGroupDetailData?.mediaGroup || {};
  const navigate = useNavigate();
  const backgroundLocation = `${location.pathname}${location.search}${location.hash}`;

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        {!showMinimalView && (
          <div className={styles.header}>
            <div className={clsx('attribution', 'truncated', styles.author)}>
              <UserAvatar
                name={author.name}
                imageUrl={author.avatarUrl}
                size="xs"
              />{' '}
              <span className={styles.highlighted}>{author.name}</span>
              <span>in</span>
              <span className={styles.highlighted} title={projectName}>
                {projectName}
              </span>
            </div>
            <div className={styles.date}>
              <span title={dayjs(mediaGroup.createdAt).format('DD/MM/YYYY')}>
                {dayjs(mediaGroup.createdAt).format('MMM D')}
              </span>
            </div>
          </div>
        )}
        {!!mediaGroupDetailData?.mediaGroup && (
          <div
            className={clsx(
              styles.media,
              styles.aboveClick,
              showMinimalView && styles.minimalView
            )}
          >
            <PreviewCarousel
              mediaGroup={mediaGroupDetailData.mediaGroup}
              asQuickView={asQuickView}
            />
            <div className={styles.status}>
              {mediaGroup.status && (
                <MediaGroupStatusBadge
                  background={4}
                  outline={3}
                  status={mediaGroup.status}
                />
              )}
            </div>
          </div>
        )}
        {!showMinimalView && (
          <>
            {mediaGroupDetailIsLoading && (
              <div className={styles.loading}>
                <LoadingPlaceholder message="Loading media preview" />
              </div>
            )}
            <div className={styles.info}>
              <div className={styles.mediaGroupCategory}>
                <div className={styles.icon}>
                  <MediaGroupCategory />
                </div>
                <div className={styles.mediaGroupCategoryLabel}>
                  {mediaGroup.category?.label ?? 'Uncategorized'}
                </div>
                <div className={styles.version}>
                  {/* TODO: Add versioning after demo */}
                  <span>
                    <Version />
                    {'v1'}
                  </span>
                </div>
              </div>
              <h1>
                {disableLinks ? (
                  mediaGroup.label
                ) : (
                  <Link
                    to={urlFor('mediaGroup', { mediaGroupId: id })}
                    state={{ backgroundLocation }}
                  >
                    {mediaGroup.label}
                  </Link>
                )}
              </h1>
              {description && (
                <div className={styles.description}>{description}</div>
              )}
            </div>
            <div className={styles.controls}>
              <div className={styles.shareControls}>
                <Button
                  className={clsx(styles.controlButton, styles.aboveClick)}
                  disabled={disableLinks}
                  onClick={() => {
                    if (disableLinks) return true;
                    navigate(
                      urlFor('mediaGroupComments', { mediaGroupId: id }),
                      {
                        state: { backgroundLocation },
                      }
                    );
                  }}
                  size="sm"
                  variant="ghost"
                >
                  <Comment />
                  <span>{commentCount}</span>
                </Button>
                <Button
                  className={clsx(styles.controlButton, styles.aboveClick)}
                  onClick={() =>
                    copyTextToClipboard(
                      new URL(
                        urlFor('mediaGroup', { mediaGroupId: mediaGroup.id }),
                        window.location.origin
                      ).toString(),
                      {
                        bodyText: 'Share link copied to clipboard!',
                      }
                    )
                  }
                  size="sm"
                  title="Share design link"
                  variant="ghost"
                >
                  <Share />
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
