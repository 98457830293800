import type { HTMLAttributes, ReactNode } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { clsx } from 'clsx';

import styles from './Tooltip.module.scss';
import Shortcut, { MetaKey } from './Shortcut';

type TooltipProps = {
  align?: 'center' | 'start' | 'end';
  children: ReactNode;
  content: ReactNode;
  defaultOpen?: boolean;
  delayDuration?: number;
  icons?: ReactNode[];
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  portalProps?: TooltipPrimitive.TooltipPortalProps;
  props?: HTMLAttributes<HTMLDivElement>;
  side?: 'left' | 'right' | 'bottom' | 'top';
  size?: 'large' | 'medium';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'bare';
  shortkeys?: string[];
};

const tooltipSize = {
  large: styles.tooltipLarge,
  medium: styles.tooltipMedium,
};

export const tooltipVariant = {
  primary: styles.tooltipPrimary,
  secondary: styles.tooltipSecondary,
  tertiary: styles.tooltipTertiary,
  bare: undefined,
};

export default function Tooltip(tooltipProps: TooltipProps) {
  const {
    children,
    defaultOpen,
    delayDuration,
    onOpenChange,
    open,
    portalProps,
  } = tooltipProps;

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        defaultOpen={defaultOpen}
        delayDuration={delayDuration}
        onOpenChange={onOpenChange}
        open={open}
      >
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        {portalProps ? (
          <TooltipPrimitive.Portal {...portalProps}>
            <div>
              <TooltipContent tooltipProps={tooltipProps} />
            </div>
          </TooltipPrimitive.Portal>
        ) : (
          <TooltipContent tooltipProps={tooltipProps} />
        )}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}

const TooltipContent = ({ tooltipProps }: { tooltipProps: TooltipProps }) => {
  const {
    align = 'center',
    children,
    content,
    defaultOpen,
    delayDuration = 0,
    icons = [],
    onOpenChange,
    open,
    side = 'bottom',
    size = 'medium',
    variant,
    portalProps,
    shortkeys,
    ...props
  } = tooltipProps;
  const TooltipSize = tooltipSize[size ?? 'medium'];
  const TooltipVariant = tooltipVariant[variant ?? 'primary'];

  return (
    <TooltipPrimitive.Content
      side={side}
      align={align}
      sideOffset={4}
      {...props}
      className={clsx(
        TooltipSize,
        TooltipVariant,
        icons.length && styles.hasIcon,
        shortkeys?.length && styles.hasShortkeys
      )}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.content}>{content}</div>
        {shortkeys?.map((key, idx) =>
          key === 'META' ? (
            <MetaKey
              className={styles.shortkey}
              shadow
              key={idx}
              isFixedWidth
            />
          ) : (
            <Shortcut className={styles.shortkey} shadow key={idx} isFixedWidth>
              {key}
            </Shortcut>
          )
        )}

        {icons?.length > 0 ? (
          <div className={styles.iconsWrapper}>
            {icons.map((icon: React.ReactNode, idx) => (
              <span key={idx} className={styles.control}>
                {icon}
              </span>
            ))}
          </div>
        ) : null}
      </div>
    </TooltipPrimitive.Content>
  );
};
