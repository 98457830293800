import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { createTag, deleteTag, listTags, patchTag } from '@spaceduck/api';
import { QueryDisabledError } from './errors';

export const tagKeys = {
  all: ['tags'] as const,
  list: (filters: { workspaceId: string | null; query: string }) =>
    [...tagKeys.all, 'list', filters] as const,
};

export const useTags = (workspaceId: string | null, query?: string) => {
  const enabled = !!workspaceId;
  const infiniteQuery = useInfiniteQuery({
    enabled,
    queryKey: tagKeys.list({ workspaceId, query: query ?? '' }),
    initialPageParam: undefined as string | undefined,
    queryFn: async ({ pageParam }) => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      const response = await listTags({
        workspaceId,
        query: query ?? '',
        cursor: pageParam,
      });
      const { kind, ...payload } = response;
      return payload;
    },
    getNextPageParam: (lastPage) => lastPage?.nextCursor ?? undefined,
    getPreviousPageParam: () => undefined,
  });
  return infiniteQuery;
};

export const useCreateTag = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: tagKeys.all });
    },
  });
  return mutation;
};

export const usePatchTag = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({
      tagId,
      patch,
    }: {
      tagId: string;
      patch: { label: string };
    }) => patchTag(tagId, patch),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: tagKeys.all });
    },
  });
  return mutation;
};

export const useDeleteTag = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: tagKeys.all });
    },
  });
  return mutation;
};
