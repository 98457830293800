import { useEffect, useState } from 'react';

import { testAuthentication } from './lib/api';
import { ExportableBytes, PluginMessage } from './lib/interfaces';
import { requestToken } from './lib/plugin';
import Login from './modules/auth/Auth';
import { useAuth } from './modules/auth/AuthContext';
import Upload from './modules/upload/Upload';

function App() {
  const { setToken } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [exportedData, setExportedData] = useState<ExportableBytes[]>([]);

  useEffect(() => {
    onmessage = async (
      event: MessageEvent<{ pluginMessage: PluginMessage }>
    ) => {
      const { type } = event.data.pluginMessage;
      switch (type) {
        case 'token':
          if (event.data.pluginMessage.token) {
            try {
              await testAuthentication(event.data.pluginMessage.token);
            } catch (e) {
              setIsAuthenticated(false);
              setToken('');
              return;
            }
            setIsAuthenticated(true);
            setToken(event.data.pluginMessage.token);
          } else {
            setIsAuthenticated(false);
            setToken('');
          }
          break;
        case 'exported':
          setExportedData(event.data.pluginMessage.exportableBytes);
          break;
        case 'clearExported':
          setExportedData([]);
          break;
      }
    };

    requestToken();
  }, []);

  if (!isAuthenticated) {
    return <Login />;
  } else {
    return <Upload exportedData={exportedData} />;
  }
}

export default App;
