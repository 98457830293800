import { useParams } from 'react-router-dom';

import DetailsModal from '@components/detailsModal/DetailsModal';
import { knownErrors } from '@/const';
import { DetailsModelTab } from '@/types/MediaGroup';

export default function MediaGroupPage({
  activeTab,
}: {
  activeTab?: DetailsModelTab;
}) {
  const { mediaGroupId } = useParams();

  if (!mediaGroupId) {
    throw new Error(knownErrors.mediaGroupError, {
      cause: 'Missing Media Group Id',
    });
  }
  return <MediaGroup id={mediaGroupId} activeTab={activeTab ?? 'info'} />;
}

const MediaGroup = ({
  id,
  activeTab,
}: {
  id: string;
  activeTab: DetailsModelTab;
}) => {
  return <DetailsModal mediaGroupId={id} activeTab={activeTab} />;
};
