import TwoColumnLayout from './TwoColumn';
import SettingsSidebarContent from '@layouts/sidebar/SettingsSidebarContent';
import BackButton from '@layouts/sidebar/account/BackButton';
import MainMenu from '@layouts/sidebar/account/Menu';

export default function SettingsLayout() {
  return (
    <TwoColumnLayout>
      <SettingsSidebarContent
        backButton={<BackButton />}
        includeMoreMenu={true}
        menu={<MainMenu />}
      />
    </TwoColumnLayout>
  );
}
