import { useEffect, useRef, useState } from 'react';
import type { EditorView } from 'prosemirror-view';
import { Editor } from '@tiptap/core';

import { isCapable } from '@api/util';
import { useWorkspace } from '@api/workspace';
import {
  useProcessAssets,
  type ProcessingResult,
} from '@hooks/useProcessAssets';
import useWorkspaceId from '@hooks/useWorkspaceId';
import createToast from '@utils/createToast';
import { v4 } from 'uuid';
import { useCreateMediaGroup } from '@/api/mediaGroup';
import { MediaGroupDetailDTO } from '@spaceduck/api';

export const useFileUpload = (
  editor: Editor | null,
  mediaGroup?: MediaGroupDetailDTO
) => {
  const [serverAssets, setServerAssets] = useState<ProcessingResult[]>([]);
  const [mediaGroupIds, setMediaGroupIds] = useState<Record<string, string>>(
    {}
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const workspaceId = useWorkspaceId();
  const { data: workspace } = useWorkspace(workspaceId);

  const canIncreaseUsedStorage = isCapable(
    'useStorage',
    workspace?.workspace?.capabilities
  );

  const maxTotalSize = canIncreaseUsedStorage?.capable
    ? (canIncreaseUsedStorage.limitLeft ?? undefined)
    : undefined;

  const checkImageFiles = (files: File[]) => {
    if (
      files.find((file) => {
        return !/^image\/(png|gif|jpeg)$/i.test(file.type);
      })
    ) {
      createToast({
        titleText: 'Upload failed',
        bodyText: 'File extension not supported',
        iconVariant: 'danger',
      });
      return false;
    }

    return true;
  };

  const { insert } = useProcessAssets({
    pollInterval: 1500,
    onSuccess: (item) => {
      setServerAssets((serverImages) => [...serverImages, item]);
    },
  });

  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();
  useEffect(() => {
    if (serverAssets.length && mediaGroup) {
      for (const serverImage of serverAssets) {
        if (mediaGroupIds[serverImage.key]) {
          continue;
        }

        if (!serverImage?.request.result?.id) {
          return;
        }

        createMediaGroup({
          assets: [serverImage.request.result.id],
          kind: 'gallery',
          projectId: mediaGroup?.project?.id,
          workspaceId: mediaGroup?.project?.id
            ? undefined
            : mediaGroup?.workspace.id,
        }).then((createdMediaGroup) => {
          setMediaGroupIds({
            ...mediaGroupIds,
            ...{ [serverImage.key]: createdMediaGroup.mediaGroupId },
          });
        });
      }
    }
  }, [serverAssets, mediaGroup]);

  const handleDrop = (
    view: EditorView,
    event: DragEvent,
    _slice: unknown,
    moved: boolean
  ) => {
    if (!moved && event?.dataTransfer?.files?.[0]) {
      const files = Array.from(event.dataTransfer.files);
      event.stopPropagation();
      event.preventDefault();

      const passedFileCheck = checkImageFiles(files);
      if (!passedFileCheck || !workspaceId) return true;

      const { schema } = view.state;
      const coordinates = view.posAtCoords({
        left: event.clientX,
        top: event.clientY,
      });

      const keys = files.map(() => v4());
      const nodes = [];
      for (const idx in files) {
        const key = keys[idx];
        if (!key) {
          continue;
        }

        const node = schema.nodes['content-block']?.create({
          loading: true,
          'data-ref': key,
        });
        if (node) {
          nodes.push(node);
        }
      }

      if (coordinates && nodes.length) {
        const transaction = view.state.tr.insert(coordinates.pos, nodes);
        view.dispatch(transaction);
      }
      insert({
        workspaceId,
        files,
        keys,
        maxTotalSize,
      });

      return true;
    }
    return false;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from((event.target as HTMLInputElement).files ?? []);
    if (!files.length) return;

    const passedFileCheck = checkImageFiles(files);
    if (!(passedFileCheck && workspaceId && editor)) return;
    const pos = editor.state.selection.anchor;

    const keys = files.map(() => v4());
    let chain = editor.chain().focus();
    for (const idx in files) {
      const key = keys[idx];
      if (!key) {
        continue;
      }

      chain = chain.insertContentAt(pos, {
        type: 'content-block',
        attrs: {
          loading: true,
          'data-ref': key,
        },
      });
    }

    chain.run();
    insert({
      workspaceId,
      files,
      maxTotalSize,
    });

    return true;
  };

  return {
    handleDrop,
    handleInputChange,
    inputRef,
    mediaGroupIds,
  };
};
