import type { ProjectDetailDTO } from '@spaceduck/api';

import ProjectMenuDetails from './ProjectMenuDetails';
import ProjectMenuInfo from './ProjectMenuInfo';
import ProjectMenuPeople from './ProjectMenuPeople';
import ProjectMenuLinks from './ProjectMenuLinks';

type ProjectMenuProps = {
  project: ProjectDetailDTO;
};

export default function ProjectMenu({ project }: ProjectMenuProps) {
  return (
    <>
      <ProjectMenuDetails project={project} />
      <ProjectMenuInfo project={project} />
      <ProjectMenuPeople project={project} />
      <ProjectMenuLinks project={project} />
    </>
  );
}
