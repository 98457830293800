import { MediaGroupDTO } from '@spaceduck/api';
import styles from './ExtractBlock.module.scss';
import { Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';

const { Highlight, QuoteBottom, QuoteTop } = Icon24;

type ExtractBlockPalette = 'surface3' | 'surface2';

export type ExtractBlockComponentProps = {
  isQuote: boolean;
  text: string;
  palette: ExtractBlockPalette;
  className?: string;
};

export const ExtractBlockComponent = ({
  isQuote,
  text,
  palette,
  className,
}: ExtractBlockComponentProps) => {
  return (
    <div className={clsx(styles.extractBlock, styles[palette], className)}>
      <div className={clsx(styles.icon, 'icon')}>
        {isQuote ? <QuoteTop /> : <Highlight />}
      </div>
      <div className={clsx(styles.text, 'text')}>{text}</div>
      {isQuote && (
        <div className={clsx(styles.icon, 'icon')}>
          <QuoteBottom />
        </div>
      )}
    </div>
  );
};

export type ExtractBlockProps = Omit<
  ExtractBlockComponentProps,
  'isQuote' | 'text'
> & { mediaGroup: MediaGroupDTO };

export const ExtractBlock = ({ mediaGroup, ...props }: ExtractBlockProps) => {
  const { contentType, text } = mediaGroup;
  return (
    <ExtractBlockComponent
      isQuote={contentType === 'quote'}
      text={text}
      {...props}
    />
  );
};
