import {
  patchMediaGroupCategoryField,
  TagFieldInstance,
  TagOption,
} from '@spaceduck/api';

import { useDeleteConfirmModal } from '@ui/ConfirmModal';
import createToast from '@utils/createToast';
import {
  AvailableTypes,
  Row,
  TableColumnMeta,
  TableColumnReducerAction,
  TableRowReducerAction,
  UpdateCellFn,
} from '@/types/Category';

export const useCategoryTags = ({
  categoryProperties,
  data,
  dispatchCategoryProperties,
  handleCellUpdate,
  patchFunction,
  setData,
}: {
  categoryProperties: TableColumnMeta[];
  data: Row[];
  dispatchCategoryProperties: React.Dispatch<TableColumnReducerAction>;
  handleCellUpdate: UpdateCellFn;
  patchFunction: typeof patchMediaGroupCategoryField;
  setData: React.Dispatch<TableRowReducerAction>;
}) => {
  const addTagOption = async ({
    propertyId,
    rowIndex,
    option,
    isMultipleSelect,
  }: {
    propertyId: string;
    rowIndex: number;
    option: TagOption;
    isMultipleSelect: boolean;
  }) => {
    const existingProperty = categoryProperties.find(
      (property) => property.id === propertyId
    )!;

    if (
      existingProperty.kind !== 'multi-select' &&
      existingProperty.kind !== 'select'
    ) {
      return;
    }
    const exists = !!existingProperty?.settings?.options.find(
      (tag) => tag.label === option.label
    );

    if (exists) {
      createToast({
        titleText: 'Tag exists',
        bodyText: 'Tags must be unique.',
        iconVariant: 'danger',
      });

      return;
    }

    const res = await patchFunction({
      mediaGroupCategoryFieldId: propertyId,
      patch: {
        settings: {
          options: existingProperty?.settings?.options
            ? [...existingProperty.settings.options, option]
            : [option],
        },
      },
    });

    if (
      !(
        res.kind === 'success' &&
        res?.field?.settings &&
        'options' in res.field.settings
      )
    ) {
      createToast({
        titleText: 'Tag creation failed',
        bodyText: 'Tag could not be created.',
        iconVariant: 'danger',
      });

      return;
    }

    const newOptionId = res.field.settings.options.find(
      (availableOptions) => availableOptions.label === option.label
    )?.id;

    if (!newOptionId) {
      createToast({
        titleText: 'Tag creation failed',
        bodyText: 'Tag could not be added.',
        iconVariant: 'danger',
      });

      return;
    }

    dispatchCategoryProperties({
      type: 'EDIT',
      payload: [
        {
          id: propertyId,
          property: {
            ...existingProperty,
            settings: {
              options: [
                ...existingProperty.settings.options,
                { ...option, id: newOptionId },
              ],
            },
          },
        },
      ],
    });

    const tagEntry = { tag: newOptionId };
    const cellData: AvailableTypes | undefined =
      data?.[rowIndex]?._data?.[propertyId];

    const value: TagFieldInstance[] =
      isMultipleSelect && cellData
        ? [...(cellData as TagFieldInstance[]), tagEntry]
        : [tagEntry];

    await handleCellUpdate({
      propertyId,
      rowIndex,
      value,
    });

    createToast({
      titleText: 'Tag added',
      bodyText: `${option.label} added to tags`,
      iconVariant: 'success',
    });
  };

  const deleteTagOption = async ({
    propertyId,
    option,
  }: {
    propertyId: string;
    option: TagOption;
  }) => {
    const existingProperty = categoryProperties.find(
      (property) => property.id === propertyId
    )!;

    if (
      existingProperty.kind !== 'multi-select' &&
      existingProperty.kind !== 'select'
    ) {
      return;
    }

    if (!existingProperty.settings) {
      createToast({
        titleText: 'No options available',
        bodyText: 'Could not find any options.',
        iconVariant: 'danger',
      });

      return;
    }

    const exists =
      !!option.id &&
      !!existingProperty.settings.options?.find((tag) => tag.id === option.id);

    if (!exists) {
      createToast({
        titleText: 'Tag not found',
        bodyText: 'Could not delete tag.',
        iconVariant: 'danger',
      });

      return;
    }

    const updatedOptions = existingProperty.settings.options.filter(
      (existingOption) => {
        return existingOption.id !== option.id;
      }
    );

    const res = await patchFunction({
      mediaGroupCategoryFieldId: existingProperty.id,
      patch: {
        settings: {
          options: updatedOptions,
        },
      },
    });

    if (res.kind !== 'success') {
      createToast({
        titleText: 'Tag deletion failed',
        bodyText: 'Tag could not be deleted.',
        iconVariant: 'danger',
      });

      return;
    }

    dispatchCategoryProperties({
      type: 'EDIT',
      payload: [
        {
          id: propertyId,
          property: {
            ...existingProperty,
            settings: {
              options: updatedOptions,
            },
          },
        },
      ],
    });

    createToast({
      titleText: 'Tag deleted',
      bodyText: `${option.label} has been updated`,
      iconVariant: 'success',
    });

    data.forEach((row, idx) => {
      const tags = row._data?.[propertyId];
      if (tags) {
        setData({
          type: 'UPDATE_CELL',
          payload: {
            propertyId,
            rowIndex: idx,
            value: (tags as TagFieldInstance[]).filter((entry) => {
              return entry.tag !== option.id;
            }),
          },
        });
      }
    });
  };

  const { open: openDeleteTagModal } = useDeleteConfirmModal({
    title: `Delete item`,
    subtitle:
      'This action cannot be undone. Your item will be permanently deleted.',
    confirmText: 'Yes, delete item',
    onConfirm: async ({
      propertyId,
      option,
    }: {
      propertyId: string;
      option: TagOption;
    }) => await deleteTagOption({ propertyId, option }),
  });

  const editTagOption = async ({
    option,
    propertyId,
  }: {
    option: TagOption;
    propertyId: string;
  }) => {
    if (!option.id) {
      console.error('No Tag ID provided');
      return;
    }

    const existingProperty = categoryProperties.find(
      (property) => property.id === propertyId
    )!;

    if (
      existingProperty.kind !== 'multi-select' &&
      existingProperty.kind !== 'select'
    ) {
      return;
    }
    if (!existingProperty.settings) {
      createToast({
        titleText: 'No options available',
        bodyText: 'Could not find any options.',
        iconVariant: 'danger',
      });

      return;
    }

    const exists = !!existingProperty.settings.options?.find(
      (tag) => tag.id === option.id
    );

    if (!exists) {
      createToast({
        titleText: 'Tag not found',
        bodyText: 'Could not update tag.',
        iconVariant: 'danger',
      });

      return;
    }

    const updatedOptions = existingProperty?.settings?.options.map(
      (existingOption) => {
        if (existingOption.id !== option.id) return existingOption;
        return { ...existingOption, ...option };
      }
    );

    const res = await patchFunction({
      mediaGroupCategoryFieldId: existingProperty.id,
      patch: {
        settings: {
          options: updatedOptions,
        },
      },
    });

    if (res.kind !== 'success') {
      createToast({
        titleText: 'Category update failed',
        bodyText: 'Select options could not be updated.',
        iconVariant: 'danger',
      });

      return;
    }

    dispatchCategoryProperties({
      type: 'EDIT',
      payload: [
        {
          id: propertyId,
          property: {
            ...existingProperty,
            settings: {
              options: updatedOptions,
            },
          },
        },
      ],
    });

    createToast({
      titleText: 'Tag updated',
      bodyText: `${option.label} has been updated`,
      iconVariant: 'success',
    });

    return;
  };

  return {
    addTagOption,
    editTagOption,
    openDeleteTagModal,
  };
};
