export const MediaClass = {
  IMAGE: 'image' as const,
  VIDEO: 'video' as const,
} as const;

export const MediaType = {
  // Image
  AVIF: 'image/avif' as const,
  GIF: 'image/gif' as const,
  JPEG: 'image/jpeg' as const,
  PNG: 'image/png' as const,
  WEBP: 'image/webp' as const,
  SVG: 'image/svg+xml' as const,
  BITMAP: 'image/bmp' as const,
  ICON: 'image/x-icon' as const,
  TIFF: 'image/tiff' as const,
  HEIC: 'image/heic' as const,
  HEIC_SEQUENCE: 'image/heic-sequence' as const,
  HEIF: 'image/heif' as const,
  HEIF_SEQUENCE: 'image/heif-sequence' as const,
  // Video
  MP4: 'video/mp4' as const,
  QUICKTIME: 'video/quicktime' as const,
  WEBM: 'video/webm' as const,
  MATROSKA: 'video/x-matroska' as const,
  // Document
  PDF: 'application/pdf' as const,
  // Archive
  ZIP: 'application/zip' as const,
  // Text
  URI_LIST: 'text/uri-list' as const,
};
