import clsx from 'clsx';

import styles from './Container.module.scss';

export default function Container({
  children,
  className,
  size = 'md',
}: {
  children: React.ReactNode;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}) {
  return (
    <div className={clsx(styles.container, styles[size], className)}>
      {children}
    </div>
  );
}
