import LoadingDuck from '@/assets/img/LoadingDuck';
import starsSm from '@/assets/img/stars-sm.png';
import { useSearch } from '@hooks/useSearch';
import Button from '@ui/Button';
import styles from './NoResults.module.scss';

const defaultTags = [
  'sign up flow',
  'pricing page',
  'button component',
  'password recovery',
  'settings',
  'empty state',
  'airbnb ios',
  'video player flow',
  '404 not found desktop',
  'dialog & modal',
  'product & landing page',
  'spotify web app',
  'dropdown for android',
];

type NoResultsProps = {
  tags?: string[];
};

export default function NoResults({ tags = defaultTags }: NoResultsProps) {
  const { updateSearchInput } = useSearch();
  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    const tagText = ev.currentTarget.textContent;
    tagText && updateSearchInput(tagText);
  };

  return (
    <div className={styles.container}>
      <div className={styles.starsWrapper}>
        <img
          alt=""
          height={451}
          className={styles.styles}
          src={starsSm}
          width={645}
        />
      </div>
      <div className={styles.content}>
        <LoadingDuck />
        <h1 className="title1">Nothing Found</h1>
        <h2 className="body5">Try to research this instead:</h2>
        <ul className={styles.tags}>
          {tags.map((tag, idx) => (
            <li key={idx}>
              <Button
                onClick={handleClick}
                variant="tertiary"
                className={styles.tag}
              >
                {tag}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
