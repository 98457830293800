import { ReactNode } from 'react';
import { type Optional } from '@spaceduck/utils';

import { useWorkspace } from '@api/workspace';
import { useModalManager } from '@context/ModalManagerContext';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button, { ButtonLink } from '@ui/Button';
import Dialog from '@ui/Dialog';
import { WorkspaceAvatar } from '@ui/UserAvatar';
import styles from './ManagementModal.module.scss';

type Link = { to: string; target?: string };

type ManagementModalProps = {
  header: string;
  title: string;
  message: string | ReactNode;
  continueText: string;
  cancelText: string;
  closeModal: () => void;
  onContinue: () => void;
  onCancel?: (() => void) | Link;
};

const ManagementModal = ({
  header,
  title,
  message,
  closeModal,
  continueText,
  cancelText,
  onCancel,
  onContinue,
}: ManagementModalProps) => {
  const workspaceId = useWorkspaceId();
  const { data } = useWorkspace(workspaceId);
  const { avatarUrl, label } = data?.workspace ?? {};

  return (
    <Dialog
      closeModal={closeModal}
      isOpen={true}
      className={styles.managementModal}
      maxWidth="32.5rem"
      modalHeading={
        <div className={styles.modalHeader}>
          <span className={styles.companyLogo}>
            <WorkspaceAvatar
              workspaceAvatar={avatarUrl}
              workspaceName={label}
            />
          </span>
          <span>{header}</span>
        </div>
      }
      padding="md"
    >
      <div>
        <div className={styles.modalContent}>
          <h1>{title}</h1>
          <p>{message}</p>
        </div>
        <div className={styles.modalFooter}>
          {onCancel === undefined || typeof onCancel === 'function' ? (
            <Button
              onClick={() => {
                closeModal();
                onCancel?.();
              }}
              size="sm"
              variant="secondary"
            >
              {cancelText}
            </Button>
          ) : (
            <ButtonLink size="sm" variant="secondary" {...onCancel}>
              {cancelText}
            </ButtonLink>
          )}
          <Button
            onClick={() => {
              closeModal();
              onContinue();
            }}
            size="sm"
          >
            {continueText}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export function useManagementModal(
  modalProps: Optional<ManagementModalProps, 'closeModal'>
) {
  const { openModal, closeModal: managerCloseModal } = useModalManager();

  const closeModal = () => {
    modalProps.closeModal?.();
    managerCloseModal();
  };

  return {
    open: () => {
      openModal({
        component: <ManagementModal closeModal={closeModal} {...modalProps} />,
        closeModal,
      });
    },
    close: managerCloseModal,
  };
}
