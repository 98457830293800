import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Mention, { MentionOptions } from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Placeholder from '@tiptap/extension-placeholder';
import { EditorOptions, useEditor } from '@tiptap/react';
import {
  SuggestionConfigurationProps,
  useSuggestionConfiguration,
} from './MentionPopover';
import commentStyles from './Comment.module.scss';

const mentionConfiguration: Partial<MentionOptions> = {
  renderHTML: ({ options, node }) => {
    return [
      'span',
      {
        // TODO: Link to profile once we have profiles
        class: commentStyles.mention,
      },
      `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
    ];
  },
};

export const tiptapExtensions = (includeMention: boolean = true) => {
  const extensions = [Document, Paragraph, Text, HardBreak];
  if (includeMention) {
    extensions.push(Mention.configure(mentionConfiguration));
  }
  return extensions;
};

type TipTapProps = {
  suggestionConfiguration: SuggestionConfigurationProps;
  options: Partial<EditorOptions>;
  placeholder?: string;
};

export const useReadOnlyTiptap = (config: Partial<EditorOptions> = {}) => {
  return useEditor({
    ...config,
    editable: false,
    extensions: tiptapExtensions(true),
  });
};

export const useTiptap = (props: TipTapProps) => {
  const mentionSuggestions = useSuggestionConfiguration(
    props.suggestionConfiguration
  );

  const extensions = [
    Mention.configure({
      ...mentionConfiguration,
      suggestion: mentionSuggestions,
    }),
    Placeholder.configure({
      placeholder: props.placeholder ?? 'Leave a comment or feedback...',
      showOnlyCurrent: false,
    }),
    ...tiptapExtensions(false),
  ];

  return useEditor({
    extensions,
    ...props.options,
  });
};
